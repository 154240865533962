import { isEmpty } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Divider, FormBuilder, FormData, FormHidden, Stack } from "@resamare/ui";

import { RefObject } from "react";
import { SubmitHandler } from "react-hook-form";
import {
    DepartureLocationOption,
    DepartureTimeOption,
    ExcursionOptionsValues,
} from "../../activity-types/excursion";
import { Session } from "../../../models";
import { ContactInformations } from "./ContactInformations";

type OptionsFormProps = {
    formRef?: RefObject<HTMLFormElement>;
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    options: FormData;
    selectedSession: Session;
    sessions: Session[];
};

export function OptionsForm({
    formRef,
    onSubmit,
    options,
    selectedSession,
    sessions,
}: OptionsFormProps) {
    const { t } = useTranslation();

    const hasUnbookableSession = !sessions.every((session) => session.dispo_vente);

    return (
        <FormBuilder data={options} formRef={formRef} onSubmit={onSubmit}>
            <Stack gap={3}>
                {hasUnbookableSession ? <ContactInformations /> : null}
                <DepartureTimeOption sessions={sessions} />
                <DepartureLocationOption session={selectedSession} />
                <FormHidden name="session_id" />
                <FormHidden name="participants" />
            </Stack>
            {!isEmpty(options.sections) ? (
                <Divider>{t("Personnalisez votre réservation")}</Divider>
            ) : null}
        </FormBuilder>
    );
}
