export const CSS_VARIABLE_PREFIX = "resamare";

export const BREAKPOINTS = {
    XS: 0,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
};

export const COLOR = {
    PRIMARY: "#2b3b61",
    TEXT: "#333333",

    BACKGROUND: "#FFF",

    GREY_500: "#747474",
};

export const FONT_FAMILY = {
    MAIN: "Inter, sans-serif",
};

export const SHAPE = {
    BORDER_RADIUS: 8,
};
