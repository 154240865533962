import { FormControl, FormHelperText, FormInput, FormInputProps, FormLabel } from "../_partials";
import { Stack } from "../../Layout";

export interface TextFieldProps extends FormInputProps {
    helperText?: string;
    isError?: boolean;
    label: string;
}

export function TextField({
    autocomplete,
    endElement,
    helperText = "",
    inputClassName,
    inputRef,
    isError = false,
    label,
    multiline,
    name,
    onChange,
    placeholder,
    type = "text",
    value,
}: TextFieldProps) {
    return (
        <FormControl isError={isError}>
            <Stack gap={0.5}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <FormInput
                    autocomplete={autocomplete}
                    endElement={endElement}
                    inputClassName={inputClassName}
                    inputRef={inputRef}
                    multiline={multiline}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                />
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </Stack>
        </FormControl>
    );
}
