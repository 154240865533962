import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { apiClient } from "../../../config/api-client";

type AddCodeDTO = {
    cartKey: string;
    code: string;
};

async function addCode(request: AddCodeDTO): Promise<null> {
    const { cartKey, code } = request;

    return apiClient.post(`/panier/${cartKey}/codePromo`, { code });
}

export function useAddCode() {
    const queryClient = useQueryClient();

    const mutation = useMutation<null, AxiosError<{ message: string } | null>, AddCodeDTO>({
        mutationFn: addCode,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
        meta: { skipLogHttpErrors: [404, 409, 422] },
    });

    return mutation;
}
