import {
    cssTransition,
    toast as reactToastify,
    ToastContentProps as ReactToastifyToastContentProps,
    ToastContent,
} from "react-toastify";
import { CheckIcon, ErrorIcon, Typography } from "../../General";

import "./Toast.scss";

export type ToastContentProps = Partial<ReactToastifyToastContentProps>;

type Toast = {
    success: (message: string) => React.ReactNode;
    error: (message: string) => React.ReactNode;
    custom: (content: ToastContent) => React.ReactNode;
};

export const toast: Toast = {
    success: (message: string) =>
        reactToastify.success(() => <Typography>{message}</Typography>, {
            icon: <CheckIcon />,
        }),

    error: (message: string) =>
        reactToastify.error(() => <Typography>{message}</Typography>, {
            icon: <ErrorIcon />,
        }),

    custom: (content) =>
        reactToastify(content, {
            autoClose: false,
            className: "toast--hide",
            closeButton: false,
            closeOnClick: false,
            draggable: false,
            position: "bottom-center",
            transition: cssTransition({
                enter: "no-animation",
                exit: "no-animation",
                collapseDuration: 0,
            }),
        }),
};
