import { dt } from "@resamare/functions";
import { FormRadio } from "../FormRadio/FormRadio";
import { FormNumberStepper } from "../FormNumberStepper/FormNumberStepper";
import { Field, FieldType } from "./types";
import { FormSelect } from "../FormSelect/FormSelect";
import { FormTextarea } from "../FormTextarea/FormTextarea";
import { FormText } from "../FormText/FormText";
import { FormCheckbox } from "../FormCheckbox/FormCheckbox";
import { FormNumber } from "../FormNumber/FormNumber";
import { FormDate } from "../FormDate/FormDate";
import { FormFile } from "../FormFile/FormFile";
import { FormSelectMultiple } from "../FormSelectMultiple/FormSelectMultiple";

type DynamicFieldProps = {
    data: Field;
};

export function DynamicField({ data }: DynamicFieldProps) {
    if (data.type === FieldType.CHECKBOX) {
        return <FormCheckbox {...data} />;
    }

    if (data.type === FieldType.DATE) {
        return (
            <FormDate
                {...data}
                defaultValue={data.defaultValue ? dt.fromISO(data.defaultValue) : undefined}
                helperText={data.description || undefined}
            />
        );
    }

    if (data.type === FieldType.FILE) {
        return <FormFile {...data} helperText={data.description || undefined} />;
    }

    if (data.type === FieldType.NUMBER) {
        return <FormNumber {...data} />;
    }

    if (data.type === FieldType.NUMBER_STEPPER) {
        const min = data.validation?.find((rule) =>
            Object.prototype.hasOwnProperty.call(rule, "min"),
        )?.min;
        const max = data.validation?.find((rule) =>
            Object.prototype.hasOwnProperty.call(rule, "max"),
        )?.max;

        return (
            <FormNumberStepper
                {...data}
                defaultValue={data.defaultValue ? parseInt(data.defaultValue, 10) : undefined}
                helperText={data.description || undefined}
                max={max}
                min={min}
            />
        );
    }

    if (data.type === FieldType.RADIO) {
        return <FormRadio {...data} />;
    }

    if (data.type === FieldType.SELECT) {
        return (
            <FormSelect
                {...data}
                defaultValue={data.defaultValue ? data.defaultValue : undefined}
            />
        );
    }

    if (data.type === FieldType.SELECT_MULTIPLE) {
        return <FormSelectMultiple {...data} defaultValue={[]} />;
    }

    if (data.type === FieldType.TEXT) {
        return (
            <FormText
                {...data}
                defaultValue={data.defaultValue ? data.defaultValue : undefined}
                helperText={data.description || undefined}
            />
        );
    }

    if (data.type === FieldType.TEXTAREA) {
        return (
            <FormTextarea
                {...data}
                defaultValue={data.defaultValue ? data.defaultValue : undefined}
            />
        );
    }

    throw new Error(`Type d'input non pris en charge`);
}
