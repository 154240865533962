import { useEffect } from "react";

export function DatePortal() {
    useEffect(() => {
        let calendarRoot = document.getElementById("resamare-calendar-root");

        if (!calendarRoot) {
            calendarRoot = document.createElement("div");
            calendarRoot.id = "resamare-calendar-root";
            document.body.appendChild(calendarRoot);
        }
    }, []);

    return null;
}
