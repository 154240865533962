import { FallbackProps as ReactErrorFallbackProps } from "react-error-boundary";

import { Stack } from "../../Layout";
import { Button, ButtonLink, ErrorIcon, Typography } from "../../General";

import Styles from "./ErrorFallback.module.scss";

export function ErrorFallback({ error, resetErrorBoundary }: ReactErrorFallbackProps) {
    return (
        <Stack alignItems="center" gap={3}>
            <Stack alignItems="center" gap={1}>
                <div className={Styles.icon}>
                    <ErrorIcon />
                </div>
                <Typography align="center" variant="title-sm">
                    Il semblerait qu'il y ait un problème !
                </Typography>
                <Typography align="center">{error.message}</Typography>
            </Stack>
            <Button onClick={resetErrorBoundary}>Réessayer</Button>
            <ButtonLink onClick={() => window.location.reload()}>Actualiser la page</ButtonLink>
        </Stack>
    );
}
