import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useDebounce } from "@resamare/react-hooks";

type AutoSubmitProps = {
    delay: number;
    onSubmit: () => void;
};

export function AutoSubmit({ delay, onSubmit }: AutoSubmitProps) {
    const watchedValues = useWatch();

    const debouncedValues = useDebounce(watchedValues, delay);

    useEffect(() => {
        if (onSubmit) {
            onSubmit();
        }
    }, [debouncedValues]);

    return null;
}
