import { Divider as MuiDivider } from "@mui/material";
import { Typography } from "../Typography/Typography";

import Styles from "./Divider.module.scss";

type DividerProps = {
    children?: string;
};

export function Divider({ children }: DividerProps) {
    return (
        <MuiDivider className={Styles.divider}>
            {children ? (
                <Typography component="span" fontWeight={400} variant="text-sm">
                    {children}
                </Typography>
            ) : null}
        </MuiDivider>
    );
}
