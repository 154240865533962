import "react-datepicker/dist/react-datepicker.css";
import { useElementSize } from "@resamare/react-hooks";
import DatePicker, { registerLocale } from "react-datepicker";

import fr from "date-fns/locale/fr";

import { useCallback } from "react";
import { FormHelperText } from "../_partials";
import { Day, Header, Input, WeekDay } from "./_partials";
import { DatePortal } from "./_partials/DatePortal";

import styles from "./DateField.module.scss";

export interface DateFieldProps {
    availableDates?: Date[];
    blockedDates?: Date[];
    excludeDates?: Date[];
    helperText?: string;
    inline?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    isError?: boolean;
    label: string;
    locale?: "fr" | "en";
    maxDate?: Date | null;
    minDate?: Date | null;
    name: string;
    onChange: (date: Date) => void;
    placeholder?: string;
    portalId?: string;
    value: Date | null;
}

registerLocale("fr", fr);

const CALENDAR_MAX_WIDTH = 500;

export function DateField({
    availableDates,
    blockedDates,
    excludeDates,
    helperText,
    inline = false,
    inputRef,
    isError,
    label,
    locale = "fr",
    maxDate,
    minDate,
    name,
    onChange,
    placeholder,
    portalId,
    value,
}: DateFieldProps) {
    const [calendarRef, { width }] = useElementSize();

    const monthsShown = inline ? Math.floor((width || 0) / CALENDAR_MAX_WIDTH) + 1 : 1;

    const handleTouchStart = useCallback((e: TouchEvent) => e.stopPropagation(), []);

    const handleCalendarOpen = () => {
        document.addEventListener("touchstart", handleTouchStart, true);
    };

    const handleCalendarClose = () => {
        document.removeEventListener("touchstart", handleTouchStart, true);
    };

    return (
        <div ref={calendarRef}>
            <DatePicker
                calendarClassName={styles.calendar}
                customInput={
                    <Input inputName={name} inputRef={inputRef} isError={isError} label={label} />
                }
                dateFormat="EEEE d MMM"
                excludeDates={excludeDates}
                // eslint-disable-next-line react/no-unstable-nested-components
                formatWeekDay={(nameOfDay) => <WeekDay>{nameOfDay.substring(0, 2)}</WeekDay>}
                highlightDates={[
                    { available: availableDates || [] },
                    { blocked: blockedDates || [] },
                ]}
                includeDates={availableDates}
                inline={inline}
                locale={locale}
                maxDate={maxDate}
                minDate={minDate}
                monthsShown={monthsShown}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={handleCalendarOpen}
                onChange={onChange}
                placeholderText={placeholder}
                portalId={portalId || "resamare-calendar-root"}
                renderCustomHeader={(params) => (
                    <Header {...params} locale={locale} monthsShown={monthsShown} />
                )}
                renderDayContents={Day}
                selected={value}
                withPortal={!inline}
            />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            {!portalId ? <DatePortal /> : null}
        </div>
    );
}
