import { string } from "yup";
import { FieldValidationRule } from "../../types";

export function buildRadioSchema(validation: FieldValidationRule[]) {
    let schema = string();

    validation.forEach((rule) => {
        if (rule.required) {
            schema = schema.required(rule.message);
        }
        if (rule.oneOf) {
            schema = schema.oneOf(rule.oneOf, rule.message);
        }
    });

    return schema;
}
