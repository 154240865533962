import { Pagination } from "swiper/modules";
import { Swiper, SwiperProps } from "swiper/react";

import "swiper/scss";
import "swiper/scss/pagination";

import styles from "./Slider.module.scss";

interface SliderProps extends Pick<SwiperProps, "autoHeight" | "loop"> {
    children: React.ReactNode;
}

export function Slider({ autoHeight, children, loop = true }: SliderProps) {
    return (
        <Swiper
            autoHeight={autoHeight}
            className={styles.slider}
            loop={loop}
            modules={[Pagination]}
            pagination={{
                clickable: true,
                bulletClass: `swiper-pagination-bullet ${styles["slider-bullet"]}`,
                bulletActiveClass: `swiper-pagination-bullet-active ${styles["slider-bullet--is-active"]}`,
            }}
        >
            {children}
        </Swiper>
    );
}
