import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { ArrowLeftIcon, ArrowRightIcon, Typography, IconButton } from "../../../../General";

import styles from "./Header.module.scss";

interface HeaderProps
    extends Pick<
        ReactDatePickerCustomHeaderProps,
        | "customHeaderCount"
        | "decreaseMonth"
        | "increaseMonth"
        | "monthDate"
        | "nextMonthButtonDisabled"
        | "prevMonthButtonDisabled"
    > {
    locale: "fr" | "en";
    monthsShown: number;
}

export function Header({
    customHeaderCount,
    monthDate,
    decreaseMonth,
    increaseMonth,
    locale,
    monthsShown,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
}: HeaderProps) {
    const firstHeader = customHeaderCount === 0;
    const lastHeader = customHeaderCount === monthsShown - 1;

    return (
        <div className={styles["header"]}>
            <div style={{ visibility: firstHeader ? "visible" : "hidden" }}>
                <IconButton
                    aria-label="Previous month"
                    disabled={prevMonthButtonDisabled}
                    onClick={decreaseMonth}
                >
                    <ArrowLeftIcon />
                </IconButton>
            </div>

            <Typography variant="label">
                {monthDate.toLocaleDateString(locale, { month: "long", year: "numeric" })}
            </Typography>
            <div style={{ visibility: lastHeader ? "visible" : "hidden" }}>
                <IconButton
                    aria-label="Next month"
                    disabled={nextMonthButtonDisabled}
                    edge="start"
                    onClick={increaseMonth}
                >
                    <ArrowRightIcon />
                </IconButton>
            </div>
        </div>
    );
}
