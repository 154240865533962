export function formatDescription(text: string) {
    let formatted = text
        .replace(/<\/p>/g, "<br>")
        .replace(/<(?!a|br\s*\/?)[^>]+>/g, "")
        .replace(/(<br\s*\/?>\s*){2,}/gi, "<br>");

    if (formatted.endsWith("<br>")) {
        formatted = formatted.slice(0, -4);
    }

    return formatted;
}
