import { Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { Excursion } from "./Excursion";
import { Product } from "../../../../models";

type ExcursionsListProps = {
    products: Product[];
};

export function ExcursionsList({ products }: ExcursionsListProps) {
    const { t } = useTranslation();
    return (
        <Stack gap={3}>
            <Typography fontWeight={600}>{t("résultat", { count: products.length })}</Typography>

            {products.map((product) => (
                <Excursion key={product.id} excursion={product} />
            ))}
        </Stack>
    );
}
