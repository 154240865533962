import { useController } from "react-hook-form";
import { ItineraryPlannerField, ItineraryPlannerFieldProps } from "./ItineraryPlannerField";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";

export interface FormItineraryPlannerProps
    extends Pick<ItineraryPlannerFieldProps, "helperText" | "label" | "max" | "name" | "options"> {
    defaultValue?: [string, ...string[], string];
}

export function FormItineraryPlanner({
    defaultValue = ["", ""],
    helperText,
    label,
    max,
    name,
    options,
}: FormItineraryPlannerProps) {
    const { field } = useController({
        name,
        defaultValue,
    });

    return (
        <FieldWrapper name={field.name}>
            <ItineraryPlannerField
                helperText={helperText}
                label={label}
                max={max}
                name={field.name}
                onChange={field.onChange}
                options={options}
                value={field.value}
            />
        </FieldWrapper>
    );
}
