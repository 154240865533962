export function isElementFocusable(element: Element) {
    const focusableTagNames = ["INPUT", "SELECT", "TEXTAREA"];

    if (focusableTagNames.includes(element.tagName)) {
        return true;
    }
    return false;
}

export const isInViewport = function (element: Element, pixelsVisible = 0) {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    let { top, bottom } = element.getBoundingClientRect();

    return viewportHeight - top > pixelsVisible && bottom > pixelsVisible ? true : false;
};

export const isBuiltWithWix = () => {
    const metas = Array.from(document.getElementsByTagName("meta"));
    for (const meta of metas) {
        if (
            meta.getAttribute("name") === "generator" &&
            meta.getAttribute("content")?.toLowerCase().includes("wix")
        ) {
            return true;
        }
    }

    return false;
};
