import { CircularProgress as MuiCircularProgress, CircularProgressProps } from "@mui/material";
import { Stack } from "../../Layout";

declare module "@mui/material/CircularProgress" {
    interface CircularProgressPropsColorOverrides {
        primaryLink?: true;
    }
}

export type SpinnerProps = Pick<
    CircularProgressProps,
    "className" | "color" | "size" | "thickness"
>;

export function Spinner({
    className,
    color = "primary",
    size = 40,
    thickness = 3.6,
}: SpinnerProps) {
    return (
        <Stack alignItems="center">
            <MuiCircularProgress
                className={className}
                color={color === "primary" ? "primaryLink" : color}
                size={size}
                thickness={thickness}
            />
        </Stack>
    );
}
