import { useTranslation } from "@resamare/translation";
import { formatCurrency } from "@resamare/functions";
import { Stack, Typography } from "@resamare/ui";

type LineSubtotalProps = {
    amount: number;
};

export default function LineSubtotal({ amount }: LineSubtotalProps) {
    const { t } = useTranslation();

    return (
        <Stack key="subtotal" direction="row" justifyContent="space-between">
            <Typography fontWeight={600}>{t("Sous-total")} </Typography>
            <Typography fontWeight={600}>{formatCurrency(amount)}</Typography>
        </Stack>
    );
}
