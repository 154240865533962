import { Departure, Liaison } from "../../../../models";
import { LiaisonResumeCard } from "./LiaisonResumeCard/LiaisonResumeCard";

type LiaisonResumeItemProps = {
    liaison?: Liaison;
    selectedDeparture: string;
    type: React.ComponentProps<typeof LiaisonResumeCard>["type"];
};

export function LiaisonResumeItem({ liaison, selectedDeparture, type }: LiaisonResumeItemProps) {
    if (!liaison) return null;

    const departure = liaison.lieux_depart.find(
        (d) => d.value.toString() === selectedDeparture,
    ) as Departure;
    const destination = liaison.lieux_destination[0];

    return (
        <LiaisonResumeCard
            departure={departure}
            destination={destination}
            liaison={liaison}
            type={type}
        />
    );
}
