import { Input as MuiInput, Box as MuiBox } from "@mui/material";
import { FormInputProps } from "../_partials";

export interface HiddenFieldProps extends Pick<FormInputProps, "inputRef" | "name" | "value"> {}

export function HiddenField({ inputRef, name, value }: HiddenFieldProps) {
    return (
        <MuiBox sx={{ display: "none" }}>
            <MuiInput inputRef={inputRef} name={name} type="hidden" value={value} />
        </MuiBox>
    );
}
