import { useController } from "react-hook-form";

import { HiddenField } from "./HiddenField";
import { FormError } from "../_partials/FormError/FormError";

export type FormHiddenProps = {
    defaultValue?: string;
    name: string;
};

export function FormHidden({ defaultValue = "", name }: FormHiddenProps) {
    const { field } = useController({
        name,
        defaultValue,
    });

    return (
        <>
            <HiddenField inputRef={field.ref} name={field.name} value={field.value} />
            <FormError name={name} />
        </>
    );
}
