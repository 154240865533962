import { Cart } from "../../../../models";
import { TwoColumnsLayout } from "../../../../layouts";
import { OrderSummaryAside } from "./OrderSummaryAside";
import { OrderSummaryProducts } from "./OrderSummaryProducts";

type OrderSummaryProps = {
    cart: Cart;
    onContinueShopping: () => void;
    onGoNextStep: () => void;
};

export function OrderSummary({ cart, onContinueShopping, onGoNextStep }: OrderSummaryProps) {
    return (
        <TwoColumnsLayout>
            <OrderSummaryProducts cart={cart} />
            <OrderSummaryAside
                cart={cart}
                onContinueShopping={onContinueShopping}
                onGoNextStep={onGoNextStep}
            />
        </TwoColumnsLayout>
    );
}
