import { FormType } from "../../product-types";

type FormDisplayProps = {
    activityType: FormType;
    forms: Record<FormType, React.ReactNode>;
};

export function FormDisplay({ activityType, forms }: FormDisplayProps) {
    if (!Object.keys(forms).includes(activityType)) {
        throw new Error("Activity type not found");
    }
    return forms[activityType];
}
