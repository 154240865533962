import { dt } from "@resamare/functions";
import { ObjectSchema, object, date, number, string } from "yup";
import { ParticipantType } from "../../../participant-types";
import { ExcursionSearch } from "../../../search";

export function getExcursionSchema(
    participantTypes: ParticipantType[],
): ObjectSchema<ExcursionSearch> {
    const participantsShape = participantTypes.reduce(
        (acc, type) => ({
            ...acc,
            [type.id]: number()
                .min(
                    type.quantite_min,
                    `${type.label} doit être supérieur ou égal à ${type.quantite_min}`,
                )
                .max(
                    type.quantite_max,
                    `${type.label} doit être inférieur ou égal à ${type.quantite_max}`,
                ),
        }),
        {},
    );

    return object().shape({
        date_deb: date()
            .min(dt.sub(dt.now(), { days: 1 }), "La date sélectionnée est passée")
            .required("Veuillez choisir une date"),

        participants: object()
            .transform((value) => ({ ...value }))
            .shape(participantsShape)
            .test({
                test: (value: ExcursionSearch["participants"]) =>
                    Object.values(value).some((v) => v > 0),
                message: "Veuillez sélectionner des participants",
            }),

        type_produit: number().positive().integer(),
        produit_id: string()
            .matches(/^\d+(,\d+)*$/, "Produit id non valide")
            .test({
                message: "Produit id non valide",
                test: (value) => {
                    if (!value) return true;
                    return value.split(",").every((id) => Number.isInteger(Number(id)));
                },
            }),
    });
}
