import { createContext, useContext, useMemo } from "react";
import { Spinner } from "@resamare/ui";
import { Config } from "@resamare/models";
import { useGetClientConfig } from "../api/getClientConfig";
import { ClientConfig } from "../../../models";
import { useGetConfig } from "../../config";

interface ClientConfigContextType {
    config: ClientConfig & Config;
}

const ClientConfigContext = createContext<ClientConfigContextType | null>(null);

type ClientConfigProviderProps = {
    children: React.ReactNode;
    defaultConfig: Partial<ClientConfig>;
};

export function ClientConfigProvider({ children, defaultConfig }: ClientConfigProviderProps) {
    const configQuery = useGetConfig();
    const clientConfigQuery = useGetClientConfig();

    const config: ClientConfig & Config = useMemo(
        () => ({ ...clientConfigQuery.data, ...configQuery.data, ...defaultConfig }),
        [clientConfigQuery.data, configQuery.data, defaultConfig],
    );

    const contextValue = useMemo(() => ({ config }), [config]);

    if (clientConfigQuery.isPending) {
        return <Spinner />;
    }

    return (
        <ClientConfigContext.Provider value={contextValue}>{children}</ClientConfigContext.Provider>
    );
}

export function useClientConfig() {
    const context = useContext(ClientConfigContext);

    if (context === null) {
        throw new Error("useClientConfig must be used within a ClientConfigProvider");
    }

    return context;
}
