import { Typography } from "../../../../General";

import styles from "./Day.module.scss";

export function Day(day: number) {
    return (
        <div className={styles["day"]}>
            <Typography variant="text-sm">{day}</Typography>
        </div>
    );
}
