import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { usePreviousLocation } from "./usePreviousLocation";

export function useNavigateWithPrevious() {
    const navigateFunction = useNavigate();
    const previousLocation = usePreviousLocation();

    const navigate = (to: To, options?: NavigateOptions) => {
        navigateFunction(to, { state: { previousLocation }, ...options });
    };

    return navigate;
}
