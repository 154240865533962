import { useTranslation } from "@resamare/translation";
import { FormRadio } from "@resamare/ui";
import { useFormContext } from "react-hook-form";
import { TripType } from "../../../../search";

export function LiaisonFormTripType() {
    const { t } = useTranslation();

    const { getValues, setValue } = useFormContext();

    const handleChangeTripType = (currentTripType: string) => {
        if (currentTripType === TripType.ROUNDTRIP) {
            const [startDate] = getValues("dates");
            setValue("dates", [startDate]);
        }
        if (currentTripType === TripType.ONEWAY) {
            const [selectedDate] = getValues("dates");
            setValue("dates", [selectedDate, selectedDate]);
        }
    };

    return (
        <FormRadio
            label={t("Type d'itinéraire")}
            name="trip-type"
            onChange={(_v, currentValue) => handleChangeTripType(currentValue)}
            options={[
                { label: t("Aller-retour"), value: TripType.ROUNDTRIP },
                { label: t("Aller simple"), value: TripType.ONEWAY },
            ]}
            row
        />
    );
}
