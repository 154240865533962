import { useEffect, useState } from "react";
import { Dialog } from "@resamare/ui";
import { isEmpty } from "@resamare/functions";
import { useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { ExcursionSearch, useSearchContext } from "../../../../search";
import { ModalLayout } from "../../../../../layouts";
import { useGetSessions } from "../../../../sessions";
import { NoResultFound } from "../../../../../components";

export function NoResultFoundModal() {
    const queryClient = useQueryClient();
    const { setValue } = useFormContext<ExcursionSearch>();
    const { search, setSearch } = useSearchContext<ExcursionSearch>();
    const sessionsQuery = useGetSessions({ params: search });
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClose = () => {
        queryClient.removeQueries({ queryKey: ["sessions"] });
        setSearch(null);
        setIsOpen(false);
    };

    const handleOnClick = (date: Date) => {
        if (!search) throw new Error("NextDates: recherche manquante");
        setValue("date_deb", date);
        setSearch({ ...search, date_deb: date });
        setIsOpen(false);
    };

    useEffect(() => {
        if (sessionsQuery.data && isEmpty(sessionsQuery.data)) {
            setIsOpen(true);
        }
    }, [sessionsQuery.data]);

    if (!sessionsQuery.data) {
        return null;
    }

    return (
        <Dialog isOpen={isOpen} onClose={handleOnClose}>
            <ModalLayout>
                {search ? (
                    <NoResultFound onDateClick={handleOnClick} search={search} showIllustration />
                ) : null}
            </ModalLayout>
        </Dialog>
    );
}
