import {
    LoadingButton as MuiLoadingButton,
    LoadingButtonProps as MuiLoadingButtonProps,
} from "@mui/lab";

import Styles from "./Button.module.scss";
import { Spinner } from "../../Feedback/Spinner/Spinner";

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        primaryLink?: true;
    }
}

export interface ButtonProps
    extends Pick<
        MuiLoadingButtonProps,
        | "className"
        | "color"
        | "disabled"
        | "endIcon"
        | "fullWidth"
        | "onClick"
        | "size"
        | "startIcon"
        | "type"
        | "variant"
    > {
    buttonRef?: React.RefObject<HTMLButtonElement>;
    children: string | string[];
    isLoading?: boolean;
    variant?: "contained" | "outlined";
}

export function Button({
    buttonRef,
    className = "",
    children,
    color = "primary",
    disabled,
    endIcon,
    isLoading = false,
    startIcon,
    size = "medium",
    variant = "contained",
    ...rest
}: ButtonProps) {
    let loadingPosition: MuiLoadingButtonProps["loadingPosition"] = "center";
    if (endIcon) {
        loadingPosition = "end";
    } else if (startIcon) {
        loadingPosition = "start";
    }

    return (
        <MuiLoadingButton
            ref={buttonRef}
            className={`${Styles["Button"]} ${Styles[`Button--${size}`]} ${
                isLoading ? Styles[`Button--loading`] : ""
            } ${variant === "outlined" ? Styles[`Button--outlined`] : ""} ${
                disabled ? Styles[`Button--disabled`] : ""
            } ${className}`}
            color={color === "primary" && variant === "outlined" ? "primaryLink" : color}
            disabled={disabled}
            endIcon={endIcon}
            loading={isLoading}
            loadingIndicator={
                <Spinner
                    className={Styles["Button__spinner"]}
                    size={16}
                    thickness={variant === "contained" ? 5 : 3.6}
                />
            }
            loadingPosition={loadingPosition}
            startIcon={startIcon}
            variant={variant}
            {...rest}
        >
            <span>{children}</span>
        </MuiLoadingButton>
    );
}
