import { PropsWithChildren, ReactElement, useEffect } from "react";

import {
    CssBaseline,
    CssVarsThemeOptions,
    Experimental_CssVarsProvider as CssVarsProvider,
    ScopedCssBaseline,
    StyledEngineProvider,
} from "@mui/material";
import { deepMerge } from "@resamare/functions";

import { defaultCustomTheme } from "./config/theme";
import { generateTheme } from "./utils";

import styles from "./Baseline.module.scss";

export type ThemeOptions = CssVarsThemeOptions;

export type ThemeProviderProps = PropsWithChildren<{
    theme?: ThemeOptions;
    scoped?: boolean;
    scopedContainer?: boolean;
}>;

export function ThemeProvider({
    children,
    theme = {},
    scoped = false,
    scopedContainer = false,
}: ThemeProviderProps): ReactElement {
    const customTheme = generateTheme(deepMerge(defaultCustomTheme, theme));

    useEffect(() => {
        document.documentElement.style.setProperty(`--resamare-spacing`, customTheme.spacing(1));
    }, [customTheme]);

    return (
        <CssVarsProvider theme={customTheme}>
            <StyledEngineProvider injectFirst>
                {scoped ? (
                    <ScopedCssBaseline>
                        <div
                            className={`${styles["baseline"]} ${
                                scopedContainer ? styles["baseline-container"] : ""
                            }`}
                        >
                            {children}
                        </div>
                    </ScopedCssBaseline>
                ) : (
                    <CssBaseline>{children}</CssBaseline>
                )}
            </StyledEngineProvider>
        </CssVarsProvider>
    );
}
