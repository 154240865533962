import { PropsWithChildren } from "react";
import {
    ErrorBoundary as ReactErrorBoundary,
    ErrorBoundaryProps as ReactErrorBoundaryProps,
} from "react-error-boundary";

import { ErrorFallback } from "../ErrorFallback/ErrorFallback";

export type ErrorBoundaryProps = PropsWithChildren<
    Pick<ReactErrorBoundaryProps, "FallbackComponent" | "onError">
>;

export function ErrorBoundary({
    children,
    FallbackComponent = ErrorFallback,
    onError,
}: ErrorBoundaryProps) {
    return (
        <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
            {children}
        </ReactErrorBoundary>
    );
}
