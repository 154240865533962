import { Outlet } from "react-router-dom";

import { SearchProvider } from "@resamare/widgets-shared";
import { SearchModule } from "@/modules/search";

export function SearchLayout() {
    return (
        <div>
            <SearchProvider>
                <SearchModule />
                <Outlet />
            </SearchProvider>
        </div>
    );
}
