import { ButtonLink, Dialog, HelpIcon, Stack, Typography } from "@resamare/ui";
import { useState } from "react";
import { OverlayHeader } from "../../../../components";
import { ModalLayout } from "../../../../layouts";
import { Cart } from "../../../../models";

type CodeDetailsProps = Pick<Cart["code_promo"], "code" | "description" | "titre">;

export function CodeDetails({ code, titre, description }: CodeDetailsProps) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClose = () => setIsOpen(false);
    const handleOnOpen = () => setIsOpen(true);

    return (
        <>
            <ButtonLink onClick={handleOnOpen}>
                <HelpIcon color="primary" />
            </ButtonLink>
            <Dialog isOpen={isOpen} onClose={handleOnClose}>
                <OverlayHeader onClose={handleOnClose}>{titre}</OverlayHeader>
                <ModalLayout>
                    <Stack gap={3}>
                        <Typography>Code : {code}</Typography>
                        {description ? <Typography>{description}</Typography> : null}
                    </Stack>
                </ModalLayout>
            </Dialog>
        </>
    );
}
