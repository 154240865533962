import { useController } from "react-hook-form";
import { SelectSwapField } from "./SelectSwapField";
import { FormSelectProps } from "../FormSelect/FormSelect";
import { Stack } from "../../Layout";
import { FormError } from "../_partials/FormError/FormError";

export interface FormSelectSwapProps {
    first: Omit<FormSelectProps, "helperText" | "placeholder">;
    helperText?: string;
    label?: string;
    second: Omit<FormSelectProps, "helperText" | "placeholder">;
}

const isValuePresent = (value: string, options: FormSelectProps["options"]) =>
    options.some((option) => option.value === value);

export function FormSelectSwap({ first, helperText, label, second }: FormSelectSwapProps) {
    const firstController = useController({
        name: first.name,
        defaultValue: first.defaultValue || "",
    });

    const secondController = useController({
        name: second.name,
        defaultValue: second.defaultValue || "",
    });

    return (
        <Stack>
            <SelectSwapField
                first={{
                    inputRef: firstController.field.ref,
                    isDisabled: first.isDisabled,
                    isError: !!firstController.fieldState.error,
                    label: first.label,
                    name: firstController.field.name,
                    onChange: firstController.field.onChange,
                    options: first.options,
                    value: isValuePresent(firstController.field.value, first.options)
                        ? firstController.field.value
                        : "",
                }}
                helperText={helperText}
                label={label}
                second={{
                    inputRef: secondController.field.ref,
                    isDisabled: second.isDisabled,
                    isError: !!secondController.fieldState.error,
                    label: second.label,
                    name: secondController.field.name,
                    onChange: secondController.field.onChange,
                    options: second.options,
                    value: isValuePresent(secondController.field.value, second.options)
                        ? secondController.field.value
                        : "",
                }}
            />
            <FormError name={first.name} />
            <FormError name={second.name} />
        </Stack>
    );
}
