import React from "react";
import { Container } from "../Container/Container";

import styles from "./Section.module.scss";

type SectionProps = {
    children: React.ReactNode;
};

export function Section({ children }: SectionProps) {
    return (
        <Container>
            <div className={styles["section"]}>{children}</div>
        </Container>
    );
}
