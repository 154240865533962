import { useQueries, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { Session } from "../../../models";

type TQueries = UseQueryOptions<Session>[];

export function useGetMultipleSessions(ids: string[]) {
    const queryClient = useQueryClient();

    const queries = ids.map((id) => ({
        queryKey: ["sessions", id],
        queryFn: async () => {
            const cachedData = queryClient.getQueryData<Session>(["sessions", id]);
            if (!cachedData) {
                throw new Error(`No cached data for session ${id}`);
            }
            return cachedData;
        },
        gcTime: 0,
        refetchOnWindowFocus: false,
        initialData: () => queryClient.getQueryData<Session>(["sessions", id]),
    }));

    const results = useQueries<TQueries>({
        queries,
    });

    return {
        sessions: results
            .map((result) => result.data)
            .filter((item) => item !== undefined) as Session[],
    };
}
