import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../../../config/api-client";

type RemoveCardDTO = {
    cartKey: string;
    cardId: string;
};

async function removeCard(request: RemoveCardDTO): Promise<null> {
    const { cartKey, cardId } = request;

    return apiClient.delete(`/panier/${cartKey}/carteCadeau/${cardId}`);
}

export function useRemoveCard() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: removeCard,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
