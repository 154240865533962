import {
    Alert,
    ButtonLink,
    Card,
    Image,
    Stack,
    Typography,
    renderElementsWithDividers,
} from "@resamare/ui";
import { formatCurrency, isEmpty } from "@resamare/functions";

import { useTranslation } from "@resamare/translation";
import { CartLineHeader } from "./CartLineHeader";
import { CartLineInfos } from "./CartLineInfos";
import { CartLine as ICartLine } from "../../../../models";

import styles from "./CartLine.module.scss";
import { LinePromotion } from "../Line/LinePromotion/LinePromotion";

type CartLineProps = {
    collapse?: boolean;
    isDeleteLoading?: boolean;
    hideTotal?: boolean;
    line: ICartLine;
    message?: string;
    onDelete?: (line: ICartLine) => void;
};

export function CartLine({
    collapse = false,
    isDeleteLoading,
    hideTotal = false,
    line,
    message,
    onDelete,
}: CartLineProps) {
    const { t } = useTranslation();

    const elements = [];

    if (line.participants || !isEmpty(line.options)) {
        elements.push(<CartLineInfos key="infos" collapse={collapse} line={line} />);
    }

    if (line.promotion) {
        elements.push(<LinePromotion key="promotion" promotion={line.promotion} />);
    }

    if (!hideTotal) {
        elements.push(
            <Stack key="subtotal" direction="row" justifyContent="space-between">
                <Typography fontWeight={600}>{t("Sous-total")}</Typography>
                <Typography fontWeight={600}>{formatCurrency(line.total)}</Typography>
            </Stack>,
        );
    }

    return (
        <Card className={styles["cart-line"]}>
            <div className={styles["cart-line__image"]}>
                <Image src={line.image[0]} />
            </div>
            <Stack className={styles["cart-line__content"]} gap={2}>
                {renderElementsWithDividers([
                    <CartLineHeader key="header" line={line} />,
                    ...elements,
                ])}
                {onDelete ? (
                    <Stack alignItems="flex-end">
                        <ButtonLink
                            color="error"
                            isLoading={isDeleteLoading}
                            onClick={() => onDelete(line)}
                            variant="text-sm"
                        >
                            {t("Supprimer")}
                        </ButtonLink>
                    </Stack>
                ) : null}
            </Stack>
            {!line.dispo ? (
                <Alert severity="error" title="Produit non disponible">
                    {t(
                        "Ce produit n'est plus disponible à la vente. Veuillez le supprimer pour continuer.",
                    )}{" "}
                    {onDelete ? (
                        <ButtonLink
                            color="error"
                            isLoading={isDeleteLoading}
                            onClick={() => onDelete(line)}
                        >
                            {t("Supprimer")}
                        </ButtonLink>
                    ) : null}
                </Alert>
            ) : null}
            {line.dispo && message ? (
                <Alert severity="info" title="Bon à savoir">
                    {line.message_package}
                </Alert>
            ) : null}
        </Card>
    );
}
