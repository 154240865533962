import { Stack } from "../../Layout";
import { FormError } from "../_partials/FormError/FormError";

type FieldWrapperProps = {
    children: React.ReactNode;
    name: string;
};

export function FieldWrapper({ children, name }: FieldWrapperProps) {
    return (
        <Stack>
            {children}
            <FormError name={name} />
        </Stack>
    );
}
