import { dt } from "@resamare/functions";
import { ProductSection } from "@resamare/models";
import { ObjectSchema, object, date, number, array } from "yup";
import { ParticipantType } from "../../../participant-types";
import { NavibusSearch } from "../../../search";

export function getNavibusSchema(
    participantTypes: ParticipantType[],
    sections: ProductSection[],
): ObjectSchema<NavibusSearch> {
    const participantsShape = participantTypes.reduce(
        (acc, type) => ({
            ...acc,
            [type.id]: number()
                .min(
                    type.quantite_min,
                    `${type.label} doit être supérieur ou égal à ${type.quantite_min}`,
                )
                .max(
                    type.quantite_max,
                    `${type.label} doit être inférieur ou égal à ${type.quantite_max}`,
                ),
        }),
        {},
    );

    const firstDeparture = sections[0].lieu_depart;

    return object().shape({
        date_deb: date()
            .min(dt.sub(dt.now(), { days: 1 }), "La date sélectionnée est passée")
            .required("Veuillez choisir une date"),

        itinerary: array()
            .min(2)
            .required("Ce champ est requis")
            .test({
                name: "is-departure-and-destination-not-empty",
                message: "Veuillez choisir un point de départ et d'arrivée",
                test: (value) => {
                    if (value.length !== 2) return true;
                    if (value.every((v) => v)) return true;
                    return false;
                },
            })
            .test({
                name: "is-not-loop-without-stopover",
                message: `Vous ne pouvez pas effectuer de trajet ${firstDeparture.nom} - ${firstDeparture.nom} sans escale`,
                test: (value) => {
                    if (value.length !== 2) return true;
                    if (value.find((v) => v !== firstDeparture.id)) return true;
                    return false;
                },
            })
            .test({
                name: "is-stopover-not-empty",
                message: `Veuillez renseigner vos escales ou les supprimer`,
                test: (value) => {
                    if (value.every((v) => v)) return true;
                    return false;
                },
            }),
        // .test({
        //     name: "is-itinerary-with-stopover-possible",
        //     message: `Cet itinéraire n'est pas possible`,
        //     test: (value) => {
        //         if (value.length !== 3) return true;
        //         if (value[0] === firstDeparture.id && value[2] === firstDeparture.id)
        //             return true;
        //         if (value[1] === firstDeparture.id) return true;
        //         return false;
        //     },
        // }),

        participants: object()
            .transform((value) => ({ ...value }))
            .shape(participantsShape)
            .test({
                test: (value: NavibusSearch["participants"]) =>
                    Object.values(value).some((v) => v > 0),
                message: "Veuillez sélectionner des participants",
            }),

        produit_id: number().positive().integer().required("Le produit est requis"),
    });
}
