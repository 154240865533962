import {
    Checkbox as MuiCheckbox,
    MenuItemProps as MuiMenuItemProps,
    MenuItem as MuiMenuItem,
} from "@mui/material";
import { Typography } from "../../General";

export interface SelectItemProps
    extends Pick<MuiMenuItemProps, "disabled" | "onClick" | "selected"> {
    value: string;
    children: string;
}

export function SelectMultipleItem({
    disabled,
    children,
    onClick,
    selected,
    value,
}: SelectItemProps) {
    return (
        <MuiMenuItem disabled={disabled} onClick={onClick} selected={selected} value={value}>
            <MuiCheckbox checked={selected} disabled={disabled} />
            <Typography>{children}</Typography>
        </MuiMenuItem>
    );
}
