import { ButtonLink, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { StepIndicator } from "./StepIndicator";

import styles from "./Step.module.scss";

type StepProps = {
    isCompleted: boolean;
    isDisabled?: boolean;
    label: string;
    onClickEdit: () => void;
    step: number;
};

export function Step({ isCompleted, isDisabled = false, label, onClickEdit, step }: StepProps) {
    const { t } = useTranslation();
    return (
        <Stack className={styles["step-container"]} gap={2}>
            <Stack
                alignItems="center"
                className={isDisabled ? styles["step--is-disabled"] : ""}
                direction="row"
                gap={2}
            >
                <StepIndicator isCompleted={isCompleted} step={step} />
                <Stack>
                    <Typography variant="text-xs">
                        {t("Étape")} {step}
                    </Typography>
                    <Typography fontWeight={600}>{label}</Typography>
                </Stack>
            </Stack>
            {isCompleted ? <ButtonLink onClick={onClickEdit}>{t("Modifier")}</ButtonLink> : null}
        </Stack>
    );
}
