import { Stack } from "@resamare/ui";
import { CartLine, PackageLine, useDeleteCartLine } from "../../../cart";
import { Cart, CartLine as ICartLine } from "../../../../models";

import styles from "./OrderSummaryProducts.module.scss";

type OrderSummaryProductsProps = {
    cart: Cart;
};

export function OrderSummaryProducts({ cart }: OrderSummaryProductsProps) {
    const deleteMutation = useDeleteCartLine();

    const handleOnDelete = (line: ICartLine) => {
        deleteMutation.mutate({ cartKey: cart.panier_cle, lineId: line.id });
    };

    return (
        <Stack className={styles["order-summary-products"]} gap={3}>
            {cart.ligne_package.map((line) => (
                <PackageLine key={line.id} line={line} onDelete={handleOnDelete} />
            ))}
            {cart.ligne_panier.map((line) => (
                <CartLine
                    key={line.id}
                    isDeleteLoading={deleteMutation.isPending}
                    line={line}
                    message={line.message_package}
                    onDelete={handleOnDelete}
                />
            ))}
        </Stack>
    );
}
