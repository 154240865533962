import { apiClient } from "../../../config/api-client";

type CreateCartOptions = {
    partenaireId?: string;
    tokenReservation?: string;
};

export async function createCart({
    partenaireId,
    tokenReservation,
}: CreateCartOptions): Promise<string> {
    const { data } = await apiClient.post("/panier", {
        partenaire_id: partenaireId,
        reservation_token: tokenReservation,
    });
    return data.cle_panier;
}
