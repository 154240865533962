/* eslint-disable @typescript-eslint/naming-convention */

import { Cart, CartLine } from "../../../models";

export function getNumberOfSessions(cart: Cart): number {
    const { ligne_package, ligne_panier } = cart;

    let total = 0;

    ligne_package.forEach((pack) => {
        total += pack.liste_session.length;
    });

    total += ligne_panier.length;

    return total;
}

export function getSessionById(cart: Cart, sessionId: number): CartLine {
    let session = null;

    cart.ligne_package.forEach((line) => {
        session = line.liste_session.find(({ id }) => id === sessionId);
    });

    if (session) {
        return session;
    }

    session = cart.ligne_panier.find((ligne) => ligne.id === sessionId);

    return session as CartLine;
}
