import { mixed } from "yup";
import { FieldValidationRule } from "../../types";

export function buildFileSchema(validation: FieldValidationRule[]) {
    let schema = mixed<FileList>().nullable();

    validation.forEach((rule) => {
        if (rule.required) {
            schema = schema.required(rule.message);
        }
    });

    const mimesRule = validation.find((rule) => "mimes" in rule);
    const maxRule = validation.find((rule) => "max" in rule);

    if (mimesRule) {
        schema = schema.test({
            message: mimesRule.message,
            test: (value) => {
                if (!value || value.length === 0) return true;

                const mimes = mimesRule.mimes as string[];
                const files = Array.from(value);

                return files.every((file) => file instanceof File && mimes.includes(file.type));
            },
        });
    }

    if (maxRule) {
        schema = schema.test({
            message: maxRule.message,
            test: (value) => {
                if (!value || value.length === 0) return true;

                const files = Array.from(value);
                const maxSize = maxRule.max as number;

                return files.every((file) => file instanceof File && file.size <= maxSize);
            },
        });
    }

    return schema;
}
