import {
    Duration as LDuration,
    DateTime as LDateTime,
    Settings,
    Interval,
    DateTimeUnit as LDateTimeUnit,
} from "luxon";

export enum DateFormat {
    /** Exemple : "14/10/1983" / "10/14/1983" */
    DATE_SHORT = "DATE_SHORT",

    /** Exemple : "14 oct. 1983" / "Oct 14, 1983" */
    DATE_MED = "DATE_MED",

    /** Exemple : "30 août 2024" / "October 14, 1983" */
    DATE_FULL = "DATE_FULL",

    /** Exemple : "13:30" / "1:30 PM" */
    TIME_SIMPLE = "TIME_SIMPLE",

    /** Exemple : "Ven. 6 sept." */
    DAY_MONTH_SHORT = "DAY_MONTH_NARROW",

    /** Exemple : "Vendredi 6 sept." */
    DAY_MONTH_MED = "DAY_MONTH_SHORT",

    /** Exemple : "vendredi 6 septembre" */
    DAY_MONTH_LONG = "DAY_MONTH_LONG",

    /** Exemple : "14 oct. 1983 à 13:30" / "Oct 14, 1983, 1:30 PM" */
    DATETIME_MED = "DATETIME_MED",

    /** Exemple : "mer. 04" /  */
    DAY_SHORT = "DAY_SHORT",
}

/**
 * Objet représentant les presets de formatage de Luxon.
 * Chaque clé correspond à un nom de preset et chaque valeur est un objet de formatage Luxon.
 * Pour plus de détails sur les presets de formatage disponibles, consultez la documentation officielle de Luxon :
 * https://moment.github.io/luxon/#/formatting?id=presets
 */
const DateFormatValues: Record<DateFormat, Intl.DateTimeFormatOptions> = {
    [DateFormat.DATE_SHORT]: LDateTime.DATE_SHORT,
    [DateFormat.DATE_MED]: LDateTime.DATE_MED,
    [DateFormat.DATE_FULL]: LDateTime.DATE_FULL,
    [DateFormat.TIME_SIMPLE]: LDateTime.TIME_SIMPLE,
    [DateFormat.DATETIME_MED]: LDateTime.DATETIME_MED,

    [DateFormat.DAY_MONTH_SHORT]: { weekday: "short", day: "numeric", month: "short" },
    [DateFormat.DAY_MONTH_MED]: { weekday: "long", day: "numeric", month: "short" },
    [DateFormat.DAY_MONTH_LONG]: { weekday: "long", day: "numeric", month: "long" },
    [DateFormat.DAY_SHORT]: { weekday: "short", day: "2-digit" },
};

type Duration = {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
};

class DateTime {
    constructor() {
        Settings.defaultLocale = "fr";
        Settings.defaultZone = "local";
    }

    setLocale(locale: "en" | "fr") {
        Settings.defaultLocale = locale;
    }
    getLocale() {
        return Settings.defaultLocale;
    }

    setTimezone(timezone: string) {
        Settings.defaultZone = timezone;
    }
    getTimezone() {
        return Settings.defaultZone.name;
    }

    now() {
        return LDateTime.now().toJSDate();
    }

    fromISO(isoDate: string) {
        return LDateTime.fromISO(isoDate).toJSDate();
    }

    formatLocale(date: Date | [Date, Date], format: Intl.DateTimeFormatOptions | DateFormat) {
        const options = typeof format === "string" ? DateFormatValues[format] : format;

        if (Array.isArray(date)) {
            if (typeof Intl.DateTimeFormat.prototype.formatRange !== "function") {
                return `${LDateTime.fromJSDate(date[0]).toLocaleString(options)}`;
            }

            return Interval.fromDateTimes(date[0], date[1]).toLocaleString(options);
        }

        return LDateTime.fromJSDate(date).toLocaleString(options);
    }

    formatLocaleTime(date: Date) {
        const dateTime = LDateTime.fromJSDate(date);

        if (Settings.defaultLocale === "fr") {
            return dateTime.toFormat("HH'h'mm");
        }

        return dateTime.toLocaleString(DateFormatValues["TIME_SIMPLE"]);
    }

    removeIsoOffset(isoDate: string) {
        return isoDate.substring(0, 19);
    }

    toIsoDate(date: Date): string {
        const isoDate = LDateTime.fromJSDate(date).toISODate();
        if (!isoDate) throw new Error();
        return isoDate;
    }

    getDiff(date1: Date, date2: Date): Duration {
        const dt1 = LDateTime.fromJSDate(date1);
        const dt2 = LDateTime.fromJSDate(date2);

        return dt2.diff(dt1).rescale().toObject();
    }

    formatDuration(duration: Duration, format = "h'h'mm") {
        return LDuration.fromObject(duration).toFormat(format);
    }

    add(date: Date, duration: Duration | string) {
        const normalizedDuration =
            typeof duration === "string" ? LDuration.fromISO(duration) : duration;

        return LDateTime.fromJSDate(date).plus(normalizedDuration).toJSDate();
    }

    sub(date: Date, duration: Duration | string) {
        const normalizedDuration =
            typeof duration === "string" ? LDuration.fromISO(duration) : duration;

        return LDateTime.fromJSDate(date).minus(normalizedDuration).toJSDate();
    }

    isValid(date: Date | string) {
        if (typeof date === "string") {
            return LDateTime.fromISO(date).isValid;
        }

        return LDateTime.fromJSDate(date).isValid;
    }

    isSame(date: Date, dateToCompare: Date, unit?: LDateTimeUnit) {
        if (unit) {
            return LDateTime.fromJSDate(date).hasSame(LDateTime.fromJSDate(dateToCompare), unit);
        }

        return LDateTime.fromJSDate(date).equals(LDateTime.fromJSDate(dateToCompare));
    }

    isAfter(date: Date, dateToCompare: Date) {
        return LDateTime.fromJSDate(date) > LDateTime.fromJSDate(dateToCompare);
    }

    isSameOrAfter(date: Date, dateToCompare: Date) {
        return LDateTime.fromJSDate(date) >= LDateTime.fromJSDate(dateToCompare);
    }

    isBefore(date: Date, dateToCompare: Date) {
        return LDateTime.fromJSDate(date) < LDateTime.fromJSDate(dateToCompare);
    }

    isSameOrBefore(date: Date, dateToCompare: Date) {
        return LDateTime.fromJSDate(date) <= LDateTime.fromJSDate(dateToCompare);
    }

    startOf(date: Date, unit: LDateTimeUnit) {
        return LDateTime.fromJSDate(date).startOf(unit).toJSDate();
    }
}

export const dt = new DateTime();
