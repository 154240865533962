import { useController } from "react-hook-form";

import { TextField } from "./TextField";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";

export type FormTextProps = {
    defaultValue?: string;
    endElement?: React.ReactNode;
    helperText?: string;
    label: string;
    name: string;
    placeholder?: string;
    type?: "text" | "email";
};

export function FormText({
    defaultValue = "",
    endElement,
    helperText,
    label,
    name,
    placeholder,
    type,
}: FormTextProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue,
    });

    return (
        <FieldWrapper name={field.name}>
            <TextField
                endElement={endElement}
                helperText={helperText}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                name={field.name}
                onChange={field.onChange}
                placeholder={placeholder}
                type={type}
                value={field.value}
            />
        </FieldWrapper>
    );
}
