import { Button, Stack, Strikethrough, Typography } from "@resamare/ui";
import { formatCurrency, min } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";

import styles from "./ExcursionCard.module.scss";
import { Product } from "../../../../../models";
import { useClientConfig } from "../../../../client-details";
import { PromotionDetails } from "../../../../promo-code";
import { NotAvailableMessage } from "../NotAvailableMessage";

type ExcursionCardFooterProps = {
    excursion: Product;
    isLoading: boolean;
    onClick: () => void;
};

export function ExcursionCardFooter({ excursion, isLoading, onClick }: ExcursionCardFooterProps) {
    const { t } = useTranslation();
    const { config } = useClientConfig();

    const cheppestSession = min(
        excursion.sessions,
        (session) => session.prix_apartirde || session.prix_total,
    );

    const isBookable = excursion.sessions.find((session) => session.dispo_vente);

    return (
        <Stack className={styles["excursion-card__footer"]} gap={2}>
            {cheppestSession ? (
                <Stack alignItems="flex-start">
                    <Typography variant="text-sm">{t("À partir de")}</Typography>
                    <Stack alignItems="baseline" direction="row" gap={1}>
                        <Typography variant="title-sm">
                            {formatCurrency(
                                cheppestSession.prix_apartirde || cheppestSession.prix_total,
                            )}
                        </Typography>

                        {cheppestSession.prix_base > cheppestSession.prix_total ? (
                            <Strikethrough>
                                <Typography fontWeight={600} variant="text-sm">
                                    {formatCurrency(cheppestSession.prix_base)}
                                </Typography>
                            </Strikethrough>
                        ) : null}
                    </Stack>
                    {cheppestSession.promotion ? (
                        <PromotionDetails
                            description={cheppestSession.promotion.description}
                            nom={cheppestSession.promotion.nom}
                        />
                    ) : null}
                </Stack>
            ) : null}
            {isBookable ? (
                <Button isLoading={isLoading} onClick={onClick}>
                    {config.activer_panier ? t("Ajouter au panier") : t("Réserver")}
                </Button>
            ) : (
                <NotAvailableMessage />
            )}
        </Stack>
    );
}
