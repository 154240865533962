import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";

import { dt } from "@resamare/functions";
import { apiClient } from "../../../config";
import { Product } from "../../../models";

type GetSessionDTO = {
    date_deb: Date;
    participants: Record<string, number>;
    type_produit?: number;
    produit_id?: number | string;
};

async function getSessions(params: GetSessionDTO): Promise<Product[]> {
    if (!params) {
        throw new Error("Vous devez envoyer des paramètres pour récupérer des sessions");
    }

    const { data } = await apiClient.get("/recherche", {
        params: {
            ...params,
            date_deb: dt.toIsoDate(params.date_deb),
            participants: JSON.stringify(params.participants),
        },
    });
    return data.liste_produit;
}

type UseGetSessionsOptions = {
    params: GetSessionDTO | null;
};

export function useGetSessions({ params }: UseGetSessionsOptions) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["sessions", { ...params }],
        queryFn: async () => {
            const products = await getSessions(params as GetSessionDTO);
            products.forEach((product) => {
                product.sessions.forEach((session) => {
                    queryClient.setQueryData(["sessions", session.id], session);
                });
            });
            return products;
        },
        enabled: !!params,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        structuralSharing: false,
        staleTime: 0,
    });

    return query;
}
