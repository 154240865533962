import { useQueries } from "@tanstack/react-query";
import { apiClient } from "../../../config/api-client";
import { ClientConfig } from "../../../models";

async function getInfos(): Promise<ClientConfig> {
    const response = await apiClient.get("/infos");
    return response.data;
}

async function getInfosClient(): Promise<ClientConfig> {
    const response = await apiClient.get("/infos-client");
    return response.data;
}

export const useGetClientConfig = () => {
    const queries = [
        { queryKey: ["infos"], queryFn: getInfos, staleTime: Infinity },
        { queryKey: ["infos-client"], queryFn: getInfosClient, staleTime: Infinity },
    ];

    const results = useQueries({
        queries,
    });

    let clientConfig = {};

    results.forEach((query) => {
        clientConfig = { ...clientConfig, ...query.data };
    });

    return {
        isPending: !!results.find((query) => query.isPending),
        data: clientConfig as ClientConfig,
    };
};
