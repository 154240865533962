import { CreateCartLineDTO, useCreateCartLine as useCreateCartLineMutation } from "../../cart";
import { useCreateCartLineSuccess } from "./useCreateCartLineSuccess";

export function useCreateCartLineAndNavigate() {
    const { handleCreateCartLineSuccess, isLoading } = useCreateCartLineSuccess();
    const createCartLineMutation = useCreateCartLineMutation();

    const handleCreateCartLine = (params: CreateCartLineDTO) => {
        createCartLineMutation.mutate(params, {
            onSuccess: (lineId) =>
                handleCreateCartLineSuccess({
                    lineId,
                    sessionId: params.session_id,
                    participants: params.participants,
                }),
        });
    };

    return { handleCreateCartLine, isLoading: isLoading || createCartLineMutation.isPending };
}
