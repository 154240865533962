import { useFormSubmitter } from "@resamare/react-hooks";
import { useTranslation } from "@resamare/translation";
import { Button, FormBuilder, FormData, Stack } from "@resamare/ui";
import { FieldValues } from "react-hook-form";
import { ModalLayout } from "../../../layouts";
import { OverlayFooter } from "../../../components";

type AdditionnalOptionsContainerProps = {
    formData: FormData;
    isLoading: boolean;
    onSubmit: (data: FieldValues) => void;
};

export function AdditionnalOptionsContainer({
    formData,
    isLoading,
    onSubmit,
}: AdditionnalOptionsContainerProps) {
    const { t } = useTranslation();
    const { formRef, submitForm } = useFormSubmitter();

    return (
        <>
            <ModalLayout>
                <FormBuilder data={formData} formRef={formRef} onSubmit={onSubmit} />
            </ModalLayout>
            <OverlayFooter>
                <Stack direction="row" justifyContent="flex-end">
                    <Button isLoading={isLoading} onClick={submitForm}>
                        {t("Suivant")}
                    </Button>
                </Stack>
            </OverlayFooter>
        </>
    );
}
