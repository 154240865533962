import { Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { Cart } from "../../../../models";
import { DiscountCodes, DiscountField } from "../../../promo-code";

type DiscountCodeSectionProps = {
    cart: Cart;
};

export function DiscountCodeSection({ cart }: DiscountCodeSectionProps) {
    const { t } = useTranslation();

    return (
        <Stack gap={3}>
            <Typography variant="title-xs">{t("Code promo / Carte cadeau")}</Typography>
            <DiscountField cartKey={cart.panier_cle} />
            <DiscountCodes cart={cart} />
        </Stack>
    );
}
