import { useTranslation } from "@resamare/translation";
import { FormPhone, FormRadio, FormText, Stack, Typography } from "@resamare/ui";

export function UserDetailsSection() {
    const { t } = useTranslation();

    return (
        <Stack gap={3}>
            <Typography variant="title-xs">{t("Vos coordonnées")}</Typography>
            <Stack gap={2}>
                <FormText label={t("Prénom")} name="informations.prenom" />
                <FormText label={t("Nom")} name="informations.nom" />
                <FormText label={t("Adresse e-mail")} name="informations.email" type="email" />
                <FormPhone
                    helperText={t(
                        "Obligatoire pour vous avertir de changements de dernière minute.",
                    )}
                    label={t("Téléphone")}
                    name="informations.phone"
                    valueNames={{ dialCode: "indicatif", phoneNumber: "telephone" }}
                />

                <FormRadio
                    label={t("Langue")}
                    name="informations.langue"
                    options={[
                        { label: "Français", value: "français" },
                        { label: "English", value: "anglais" },
                    ]}
                    row
                />
            </Stack>
        </Stack>
    );
}
