/* eslint-disable @typescript-eslint/naming-convention */
import { dt, flat, unique } from "@resamare/functions";
import { SelectedLiaison } from "../types";
import { DepartureLocation, LocationType } from "../../../../models";
import { LiaisonSearch, TripType } from "../../../search";

export const getDepartureSelectOptions = (
    locations: DepartureLocation[] | DepartureLocation["destinations"],
) => {
    const options = locations.map(({ id, nom, ville }) =>
        ville
            ? { value: `${LocationType.CITY}-${ville.id}`, label: ville.nom }
            : { value: `${LocationType.PLACE}-${id}`, label: nom },
    );

    return unique(options, (v) => v.value);
};

export const getDestinationSelectOptions = (
    selectedDeparture: string,
    locations: DepartureLocation[],
) => {
    const [type, id] = selectedDeparture.split("-") as [LocationType, string];

    if (type === LocationType.PLACE) {
        const departure = locations.find((location) => location.id === id);
        if (!departure) return null;
        return getDepartureSelectOptions(departure.destinations);
    }

    if (type === LocationType.CITY) {
        const departures = locations.filter((location) => location.ville?.id === id);
        const destinations = flat(departures.map((departure) => departure.destinations));
        return getDepartureSelectOptions(destinations);
    }

    return null;
};

export const getOutboundParams = (search: LiaisonSearch | null) => {
    if (!search) return null;

    const { dates, participants, from, to, type_produit, "trip-type": tripType } = search;

    const [fromType, fromId] = from.split("-") as [LocationType, string];
    const [toType, toId] = to.split("-") as [LocationType, string];

    let params = {};
    if (fromType === LocationType.CITY) params = { ...params, ville_depart: [fromId] };
    if (fromType === LocationType.PLACE) params = { ...params, lieu_depart: [fromId] };
    if (toType === LocationType.CITY) params = { ...params, ville_destination: [toId] };
    if (toType === LocationType.PLACE) params = { ...params, lieu_destination: [toId] };

    return {
        ...params,
        date_deb: dates[0],
        date_retour: dates[1],
        participants,
        retour: tripType === TripType.ROUNDTRIP,
        type_produit,
    };
};

export const getReturnParams = (search: LiaisonSearch | null, session: SelectedLiaison | null) => {
    if (!search) return null;

    const { dates, participants, from, to, type_produit, "trip-type": tripType } = search;

    const [fromType, fromId] = from.split("-") as [LocationType, string];
    const [toType, toId] = to.split("-") as [LocationType, string];

    let params = {};
    if (toType === LocationType.CITY) params = { ...params, ville_depart: [toId] };
    if (toType === LocationType.PLACE) params = { ...params, lieu_depart: [toId] };
    if (fromType === LocationType.CITY) params = { ...params, ville_destination: [fromId] };
    if (fromType === LocationType.PLACE) params = { ...params, lieu_destination: [fromId] };

    if (session) {
        params = {
            ...params,
            session_aller_id: session.session_id,
            session_aller_prix: session.prix_apartirde,
            date_deb: dt.isAfter(session.date_fin, dates[1]) ? session.date_fin : dates[1],
        };
    }

    return {
        date_deb: dates[1],
        ...params,
        participants,
        retour: tripType === TripType.ROUNDTRIP,
        type_produit,
    };
};
