import { Select as MuiSelect } from "@mui/material";
import { FormControl, FormHelperText, FormInputProps, FormLabel } from "../_partials";
import { Stack } from "../../Layout";
import { SelectItem } from "./SelectItem";
import { ChevronBottomIcon, Typography } from "../../General";

import styles from "./SelectField.module.scss";

export type SelectOption = {
    label: string;
    value: string;
};

export interface SelectFieldProps
    extends Pick<FormInputProps, "inputRef" | "name" | "placeholder" | "value"> {
    helperText?: string;
    isDisabled?: boolean;
    isError?: boolean;
    label: string;
    options: SelectOption[];
    onChange: (value: string) => void;
}

export function SelectField({
    helperText = "",
    inputRef,
    isDisabled = false,
    isError = false,
    label,
    name,
    onChange,
    options,
    placeholder,
    value,
}: SelectFieldProps) {
    return (
        <FormControl isError={isError}>
            <Stack gap={0.5}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <MuiSelect
                    IconComponent={ChevronBottomIcon}
                    aria-labelledby={name}
                    className={isDisabled ? styles["select--is-disabled"] : ""}
                    disabled={isDisabled}
                    displayEmpty
                    id={name}
                    inputProps={{ className: styles["select"], "aria-labelledby": name }}
                    inputRef={inputRef}
                    name={name}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    renderValue={(v) => (
                        <Typography>
                            {options.find((option) => option.value === v)?.label || placeholder}
                        </Typography>
                    )}
                    value={value}
                    variant="outlined"
                >
                    {placeholder ? (
                        <SelectItem disabled value="">
                            {placeholder}
                        </SelectItem>
                    ) : null}
                    {options.map((option) => (
                        <SelectItem
                            key={option.value}
                            selected={option.value === value}
                            value={option.value}
                        >
                            {option.label}
                        </SelectItem>
                    ))}
                </MuiSelect>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </Stack>
        </FormControl>
    );
}
