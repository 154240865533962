import { mixed } from "yup";
import { SectionValidationRule } from "../../types";

export function buildSectionSchema(validation: SectionValidationRule[]) {
    const schema = mixed().test({
        test: (_, { parent, createError, path }) => {
            let error;

            validation.forEach((rule) => {
                const { fields } = rule;
                if (!fields || fields.length < 2) return;

                if (rule.max !== undefined) {
                    const total = fields
                        .map((field) => parent[field])
                        .filter((value) => value !== undefined)
                        .reduce((acc, currentValue) => acc + currentValue, 0);

                    if (total > rule.max) {
                        error = { message: rule.message, path, type: "section-max" };
                    }
                }

                if (rule.min !== undefined) {
                    const total = fields
                        .map((field) => parent[field])
                        .filter((value) => value !== undefined)
                        .reduce((acc, currentValue) => acc + currentValue, 0);

                    if (total < rule.min) {
                        error = { message: rule.message, path, type: "section-min" };
                    }
                }
            });

            if (!error) return true;
            return createError(error);
        },
    });

    return schema;
}
