import { CirclePlusIcon, Stack } from "@resamare/ui";
import { ReactElement } from "react";

import styles from "./PackageLine.module.scss";

export function renderPackageSessions(elements: ReactElement[]): ReactElement[] {
    return elements.reduce<ReactElement[]>((acc, element, index) => {
        acc.push(element);

        // Ajoute un Divider après chaque élément, sauf après le dernier
        if (index < elements.length - 1) {
            acc.push(
                <Stack
                    key={`${element.key}-divider`}
                    alignItems="center"
                    className={styles["package-line-separator"]}
                >
                    <div>
                        <CirclePlusIcon size="large" />
                    </div>
                </Stack>,
            );
        }

        return acc;
    }, []);
}
