import { createSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useCheckAdditionnalOptions } from "../../sessions";
import { Path } from "../../../routes";
import { useClientConfig } from "../../client-details";
import { useNavigateWithPrevious } from "../../../hooks";

export function useCreateCartLineSuccess() {
    const navigate = useNavigateWithPrevious();
    const { config } = useClientConfig();

    const { checkAdditionnalOptions, isLoading } = useCheckAdditionnalOptions();

    const navigateToCheckout = () => {
        const nextStep = config.activer_panier ? Path.CHECKOUT_SUMMARY : Path.CHECKOUT_FORM;
        navigate(nextStep);
    };

    const navigateToAdditionnalOptions = ({
        lineId,
        sessionId,
        participants,
    }: {
        lineId: number;
        sessionId: string;
        participants: Record<string, number>;
    }) => {
        const searchParams = createSearchParams({
            lineId: lineId.toString(),
            participants: JSON.stringify(participants),
            sessionId: sessionId.toString(),
        }).toString();
        navigate({ pathname: Path.ADDITIONNAL_OPTIONS, search: searchParams });
    };

    const handleCreateCartLineSuccess = async ({
        lineId,
        sessionId,
        participants,
    }: {
        lineId: unknown;
        sessionId: string;
        participants: Record<string, number>;
    }) => {
        if (typeof lineId !== "number") return navigateToCheckout();

        try {
            const params = { lineId, participants };
            const hasAdditionnalOptions = await checkAdditionnalOptions(sessionId, params);
            if (!hasAdditionnalOptions) return navigateToCheckout();
        } catch (error) {
            if (!(error instanceof AxiosError) || error?.response?.status !== 404) throw error;
            // Si on reçoit une 404, c'est que des lignes de panier ont été fusionnées.
            // On ne propose pas les options de type pourcentage dans ce cas là.
            navigateToCheckout();
        }

        return navigateToAdditionnalOptions({ lineId, sessionId, participants });
    };

    return { handleCreateCartLineSuccess, isLoading };
}
