import { DateFormat, dt } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { ClockIcon, EventIcon, LocationIcon, Stack, Typography } from "@resamare/ui";
import { CartLine } from "../../../../models";

import { LineTitle } from "../Line/LineTitle/LineTitle";

type CartLineHeaderProps = {
    line: CartLine;
};

export function CartLineHeader({ line }: CartLineHeaderProps) {
    const { t } = useTranslation();

    const startTime = dt.formatLocaleTime(dt.fromISO(line.heure_deb));
    const endTime = line.heure_fin ? dt.formatLocaleTime(dt.fromISO(line.heure_fin)) : null;

    return (
        <Stack gap={0.5}>
            {line.lieu_destination ? (
                <LineTitle>{`${line.lieu_depart} -> ${line.lieu_destination}`}</LineTitle>
            ) : (
                <>
                    <LineTitle>{line.nom}</LineTitle>

                    <Stack alignItems="center" direction="row" gap={0.5}>
                        <LocationIcon size="small" />
                        <Typography>
                            {t("Lieu de départ")} : {line.lieu_depart}
                        </Typography>
                    </Stack>
                </>
            )}
            <Stack alignItems="center" direction="row" gap={0.5}>
                <EventIcon size="small" />
                <Typography>
                    {dt.formatLocale(
                        [dt.fromISO(line.date_deb), dt.fromISO(line.date_fin)],
                        DateFormat.DATE_FULL,
                    )}
                </Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={0.5}>
                <ClockIcon size="small" />
                <Typography>
                    {startTime} {endTime ? `- ${endTime}` : null}
                </Typography>
            </Stack>
        </Stack>
    );
}
