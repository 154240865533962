import { yupResolver } from "@hookform/resolvers/yup";
import Yup, { ObjectSchema, ValidateOptions } from "yup";
import {
    FieldValues as FieldValuesRHF,
    UseFormProps as UseFormPropsRHF,
    useForm as useFormRHF,
    UseFormReturn as UseFormReturnRHF,
    Resolver,
} from "react-hook-form";
import { useCallback, useState } from "react";

export interface UseFormProps<FormValues extends FieldValuesRHF> {
    defaultValues?: UseFormPropsRHF<FormValues>["defaultValues"];
    options?: Omit<UseFormPropsRHF<FormValues>, "defaultValues" | "resolver">;
    schema?: ObjectSchema<FormValues, Yup.AnyObject>;
    schemaOptions?: ValidateOptions;
}

interface UseCustomFormReturn<FormValues extends FieldValuesRHF = FieldValuesRHF>
    extends UseFormReturnRHF<FormValues> {
    updateSchema: (newSchema: ObjectSchema<FormValues>) => void;
}

export function useForm<FormValues extends FieldValuesRHF>({
    defaultValues,
    options,
    schema,
    schemaOptions,
}: UseFormProps<FormValues>): UseCustomFormReturn<FormValues> {
    const [currentSchema, setCurrentSchema] = useState<ObjectSchema<FormValues> | undefined>(
        schema,
    );

    const resolver = currentSchema
        ? (yupResolver(currentSchema, schemaOptions) as unknown as Resolver<FormValues>)
        : undefined;

    const formMethods = useFormRHF<FormValues>({
        defaultValues,
        resolver,
        ...options,
    });

    const updateSchema = useCallback((newSchema: ObjectSchema<FormValues>) => {
        setCurrentSchema(newSchema);
    }, []);

    return {
        ...formMethods,
        updateSchema,
    };
}
