import { ElementType } from "react";
import { FormHelperText as MuiFormHelperText } from "@mui/material";

import { Typography } from "../../../General";

import Styles from "./FormHelperText.module.scss";

export interface FormHelperTextProps {
    children: string;
    component?: ElementType;
    role?: "alert";
}

export function FormHelperText({ children, component = "p", role }: FormHelperTextProps) {
    return (
        <MuiFormHelperText
            className={Styles.FormHelperText}
            component={component}
            error={false}
            role={role}
        >
            <Typography component="span" variant="helper-text">
                {children}
            </Typography>
        </MuiFormHelperText>
    );
}
