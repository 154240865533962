import { ReactNode } from "react";

import { FormControl as MuiFormControl } from "@mui/material";

export interface FormControlProps {
    children: ReactNode;
    component?: "div" | "fieldset";
    isError?: boolean;
}

export function FormControl({ children, component = "div", isError = false }: FormControlProps) {
    return (
        <MuiFormControl component={component} error={isError} fullWidth variant="standard">
            {children}
        </MuiFormControl>
    );
}

export default FormControl;
