export type DepartureLocation = {
    id: string;
    nom: string;
    destinations: Destination[];
    ville?: City;
};

export enum LocationType {
    CITY = "ville",
    PLACE = "lieu",
}

export type Destination = {
    id: string;
    nom: string;
    ville?: City;
};

type City = {
    id: string;
    nom: string;
};
