/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    FieldValues,
    FormProvider,
    SubmitErrorHandler,
    SubmitHandler,
    UseFormProps,
    useFormContext,
} from "react-hook-form";
import { ObjectSchema, ValidateOptions } from "yup";
import { isBrowser } from "react-device-detect";
import { RefObject } from "react";
import { useForm } from "./useForm";
import { AutoSubmit } from "./AutoSubmit";

export type FormProps<FormValues extends FieldValues> = {
    autoSubmitOnChange?: {
        enabled: boolean;
        delay: number;
    };
    children: React.ReactNode;
    formRef?: RefObject<HTMLFormElement>;
    onError?: SubmitErrorHandler<FormValues>;
    onSubmit?: SubmitHandler<FormValues>;
    options?: UseFormProps<FormValues>;
    schema?: ObjectSchema<FormValues>;
    schemaOptions?: ValidateOptions;
};

export function Form<FormValues extends FieldValues>({
    autoSubmitOnChange,
    children,
    formRef,
    onError,
    onSubmit,
    options,
    schema,
    schemaOptions,
}: FormProps<FormValues>) {
    const methods = useForm({
        defaultValues: options?.defaultValues,
        options,
        schema,
        schemaOptions,
    });
    const contextMethods = useFormContext<FormValues>();

    const props = { autoSubmitOnChange, formRef, onError, onSubmit };

    if (contextMethods) {
        if (options || schema) {
            // eslint-disable-next-line no-console
            console.warn(
                "Attention : Les props 'options' et 'schema' sont ignorées car un FormProvider est déjà présent plus haut dans l'arbre React.",
            );
        }

        return <FormComponent {...props}>{children}</FormComponent>;
    }

    return (
        <FormProvider {...methods}>
            <FormComponent {...props}>{children}</FormComponent>
        </FormProvider>
    );
}

function FormComponent<FormValues extends FieldValues>({
    autoSubmitOnChange,
    children,
    formRef,
    onError,
    onSubmit,
}: Pick<
    FormProps<FormValues>,
    "autoSubmitOnChange" | "children" | "formRef" | "onError" | "onSubmit"
>) {
    const { handleSubmit } = useFormContext<FormValues>();

    const handleOnSubmit = (formData: FormValues) => {
        const focusedElement = document.activeElement;
        if (
            !isBrowser &&
            focusedElement &&
            /^(INPUT|TEXTAREA|SELECT)$/.test(focusedElement.tagName)
        ) {
            (focusedElement as HTMLElement).blur();
        }

        if (onSubmit) {
            onSubmit(formData);
        }
    };

    return (
        <form ref={formRef} noValidate onSubmit={handleSubmit(handleOnSubmit, onError)} role="form">
            {autoSubmitOnChange?.enabled ? (
                <AutoSubmit
                    delay={autoSubmitOnChange.delay}
                    onSubmit={handleSubmit(handleOnSubmit, onError)}
                />
            ) : null}
            {children}
        </form>
    );
}
