import { getInputNames } from "@resamare/functions";
import { toast } from "@resamare/ui";
import { AxiosError } from "axios";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface ValidationError {
    [key: string]: {
        [type: string]: string;
    };
}

export async function handleServerError<T extends FieldValues>(
    error: AxiosError,
    setError: UseFormReturn<T>["setError"],
    formRef: React.MutableRefObject<HTMLFormElement | null>,
    errorFieldMap?: Record<string, string>,
) {
    // Gérer les erreurs de validation
    if (
        error.response &&
        error.response.status === 400 &&
        typeof error.response.data === "object"
    ) {
        const fieldOrder = getInputNames(formRef.current);
        const errors = error.response.data as ValidationError;

        const firstErrorField = fieldOrder.find((field) => errors[errorFieldMap?.[field] || field]);

        Object.keys(errors).forEach((apiField) => {
            const mappedField = (errorFieldMap?.[apiField] || apiField) as Path<T>;
            const errorType = Object.keys(errors[apiField])[0];
            const errorMessage = errors[apiField][errorType];

            setError(
                mappedField,
                { type: errorType, message: errorMessage },
                { shouldFocus: mappedField === firstErrorField },
            );
        });
        return;
    }

    if (error.response && error.response.data) {
        const { data } = error.response;
        const message = typeof data === "string" ? data : JSON.stringify(error.response.data);
        toast.error(message);
        return;
    }

    toast.error("Une erreur inattendue est apparue. Veuillez réessayer.");
}
