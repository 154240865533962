import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from "@mui/material";

import styles from "./Tabs.module.scss";

interface TabsListProps extends Pick<MuiTabsProps, "children"> {
    onChange?: (value: string) => void;
    value: string;
}

export function TabsList({ children, onChange, value }: TabsListProps) {
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <MuiTabs
            TabIndicatorProps={{
                className: styles.indicator,
            }}
            allowScrollButtonsMobile
            className={styles.tabs}
            onChange={handleChange}
            sx={{
                ".MuiTabs-scroller": {
                    paddingLeft: 2,
                },
            }}
            value={value}
            variant="scrollable"
        >
            {children}
        </MuiTabs>
    );
}
