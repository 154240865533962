import { Trans as I18nTrans } from "react-i18next";

interface TransProps {
    children?: string;
    components?: { readonly [tagName: string]: React.ReactElement };
    i18nKey?: string;
}

export function Trans({ children, components, i18nKey }: TransProps) {
    return (
        <I18nTrans components={components} i18nKey={i18nKey}>
            {children}
        </I18nTrans>
    );
}
