import { DialogProps as MuiDialogProps, Dialog as MuiDialog } from "@mui/material";

interface DialogProps
    extends Pick<MuiDialogProps, "children" | "container" | "fullWidth" | "onClose"> {
    isOpen: boolean;
}

export function Dialog({ children, container, fullWidth, isOpen, onClose }: DialogProps) {
    return (
        <MuiDialog
            container={container}
            fullWidth={fullWidth}
            onClose={onClose}
            open={isOpen}
            scroll="body"
        >
            {children}
        </MuiDialog>
    );
}
