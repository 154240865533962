import { dt } from "@resamare/functions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../../../config";

export type DatesAvailable = {
    date_dispo: string[];
    date_bloquee: string[];
};

type GetDatesAvailableDTO = {
    productId: string;
};

async function getDatesAvailable(params: GetDatesAvailableDTO): Promise<DatesAvailable> {
    const now = dt.now();

    const response = await apiClient.get(`/dateDispo`, {
        params: {
            date_debut: now,
            date_fin: dt.add(now, { years: 1 }),
            produit_id: params.productId,
        },
    });
    return response.data;
}

export function useGetDatesAvailable(params: GetDatesAvailableDTO) {
    const query = useQuery({
        queryKey: ["dates-available", params],
        queryFn: () => getDatesAvailable(params),
    });

    return query;
}

export function usePrefetchDatesAvailable(productIds?: number[]) {
    const queryClient = useQueryClient();

    productIds?.forEach((productId) => {
        const queryKey = ["dates-available", { productId: productId.toString() }];
        queryClient.prefetchQuery({
            queryKey,
            queryFn: () => getDatesAvailable({ productId: productId.toString() }),
        });
    });
}
