import { useWindowSize, useElementSize } from "@resamare/react-hooks";

import styles from "./AsideLayout.module.scss";
import { useClientConfig } from "../../features/client-details";

type AsideLayoutProps = {
    children: React.ReactNode;
    className?: string;
};

export function AsideLayout({ children, className = "" }: AsideLayoutProps) {
    const { config } = useClientConfig();
    const { height: windowHeight } = useWindowSize();
    const [asideContentRef, { height: asideContentHeight }] = useElementSize();

    const VERTICAL_PADDING = 64;
    const offsetTop = config.offsetTop ? parseInt(config.offsetTop, 10) : 0;
    const stickOnBottom =
        (asideContentHeight || 0) > (windowHeight || 0) - VERTICAL_PADDING - offsetTop;

    const top = config.offsetTop ? `calc(2em + ${config.offsetTop || 0}px)` : "2em";
    const style = stickOnBottom ? { bottom: "2em" } : { top };

    return (
        <div className={`${styles["aside"]} ${className}`}>
            {stickOnBottom ? <div style={{ flex: 1 }} /> : null}
            <div ref={asideContentRef} className={styles["aside__content"]} style={style}>
                {children}
            </div>
        </div>
    );
}
