import { Card as MuiCard, CardProps as MuiCardProps } from "@mui/material";

type CardProps = Pick<MuiCardProps, "className" | "elevation" | "variant"> & {
    children: React.ReactNode;
};

export function Card({ children, className, elevation = 2, variant = "elevation" }: CardProps) {
    return (
        <MuiCard
            className={className}
            elevation={variant === "elevation" ? elevation : undefined}
            variant={variant}
        >
            {children}
        </MuiCard>
    );
}

export default Card;
