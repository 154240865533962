import { useQuery } from "@tanstack/react-query";
import { dt } from "@resamare/functions";
import { apiClient } from "../../../config/api-client";
import { OptionDetails } from "../types";

type GetOptionsDTO = {
    date: Date;
};

async function getOptions(params: GetOptionsDTO): Promise<OptionDetails[]> {
    try {
        const response = await apiClient.get("options", { params });
        return response.data;
    } catch (error) {
        return [];
    }
}

export const useGetOptions = (params: GetOptionsDTO) => {
    const query = useQuery({
        queryKey: ["options", { date: dt.toIsoDate(params.date) }],
        queryFn: () => getOptions(params),
    });
    return query;
};
