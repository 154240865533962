/* eslint-disable @typescript-eslint/no-use-before-define */
import { Radio as MuiRadio, FormControlLabel as MuiFormControlLabel } from "@mui/material";
import { CircleIcon, SuccessIcon, Typography } from "../../General";
import { FormHelperText } from "../_partials";
import { Stack } from "../../Layout";
import { RadioOption } from "./types";

import styles from "./RadioItem.module.scss";

declare module "@mui/material/Radio" {
    interface RadioPropsColorOverrides {
        primaryLink?: true;
    }
}

interface RadioItemProps<OptionType extends RadioOption> {
    disabled?: boolean;
    description?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    label: string;
    renderOption?: (option: OptionType) => React.ReactNode;
    value: string;
}

export function RadioItem<OptionType extends RadioOption>({
    disabled = false,
    description,
    inputRef,
    label,
    renderOption,
    value,
}: RadioItemProps<OptionType>) {
    const option = { description, label, value } as OptionType;

    const icon = disabled ? (
        <CircleIcon className={styles["label--is-disabled__icon"]} />
    ) : undefined;

    return (
        <MuiFormControlLabel
            className={`${styles["label"]} ${disabled ? styles["label--is-disabled"] : ""}`}
            control={
                <MuiRadio
                    aria-labelledby={value}
                    checkedIcon={
                        renderOption ? (
                            <CustomOptionWrapper isActive>
                                {renderOption(option)}
                            </CustomOptionWrapper>
                        ) : undefined
                    }
                    classes={{ root: renderOption ? styles["custom-option__root"] : undefined }}
                    color="primaryLink"
                    disabled={disabled}
                    icon={
                        renderOption ? (
                            <CustomOptionWrapper>{renderOption(option)}</CustomOptionWrapper>
                        ) : (
                            icon
                        )
                    }
                    id={value}
                    inputRef={inputRef}
                />
            }
            disableTypography
            htmlFor={value}
            label={
                renderOption ? null : (
                    <Typography className={styles["label-text"]} component="span">
                        <Stack component="span">
                            {label}
                            {description ? (
                                <FormHelperText component="span">{description}</FormHelperText>
                            ) : null}
                        </Stack>
                    </Typography>
                )
            }
            value={value}
        />
    );
}

type CustomOptionWrapperProps = {
    children: React.ReactNode;
    isActive?: boolean;
};

function CustomOptionWrapper({ children, isActive = false }: CustomOptionWrapperProps) {
    return (
        <span
            className={`${styles["custom-option"]}  ${
                isActive ? styles["custom-option--is-active"] : ""
            }`}
        >
            {isActive ? <SuccessIcon size="small" /> : null}
            {children}
        </span>
    );
}
