import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { apiClient } from "../../../config/api-client";
import { Cart, CartLine } from "../../../models";

type AddOptionsDTO = {
    cartKey: Cart["panier_cle"];
    lineId: CartLine["id"];
    options: Record<string, unknown>;
};

async function addOptions(request: AddOptionsDTO): Promise<AxiosInstance> {
    const { cartKey, lineId, options } = request;
    return apiClient.post(`/panier/${cartKey}/ajouterOptionLignePanier/${lineId}`, options);
}

export function useAddOptions() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addOptions,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
