import { Card, Stack, renderElementsWithDividers } from "@resamare/ui";
import { CartLine as ICartLine, PackageLine as IPackageLine } from "../../../../models";
import { LineTitle } from "../Line/LineTitle/LineTitle";
import { CartLine } from "../CartLine/CartLine";

import styles from "./PackageLine.module.scss";
import { LineParticipants } from "../Line/LineParticipants/LineParticipants";
import { LinePromotion } from "../Line/LinePromotion/LinePromotion";
import LineSubtotal from "../Line/LineSubtotal";
import { renderPackageSessions } from "./renderPackageSessions";

type PackageLineProps = {
    isDeleteLoading?: boolean;
    line: IPackageLine;
    onDelete?: (line: ICartLine) => void;
};

export function PackageLine({ isDeleteLoading, line, onDelete }: PackageLineProps) {
    const elements = [];

    if (line.promotion) {
        elements.push(<LinePromotion key="promotion" promotion={line.promotion} />);
    }

    return (
        <Card className={styles["package-line"]} variant="outlined">
            <Stack gap={2}>
                <LineTitle>{line.nom}</LineTitle>
                <Stack gap={0.75}>
                    {renderPackageSessions(
                        line.liste_session.map((session) => (
                            <CartLine
                                key={session.id}
                                collapse
                                hideTotal
                                isDeleteLoading={isDeleteLoading}
                                line={session}
                                onDelete={onDelete}
                            />
                        )),
                    )}
                </Stack>
                {renderElementsWithDividers([
                    <LineParticipants key="participants" participants={line.participants} />,
                    ...elements,
                    <LineSubtotal key="subtotal" amount={line.total} />,
                ])}
            </Stack>
        </Card>
    );
}
