import { Stack, Typography } from "@resamare/ui";
import { DateFormat, dt } from "@resamare/functions";
import { useWatch } from "react-hook-form";
import { useTranslation } from "@resamare/translation";
import { ParticipantInfosCard } from "./ParticipantInfosCard";
import { getSessionById } from "../../cart";
import { DataField } from "../types";
import { Cart } from "../../../models";

type ParticipantsInfosFieldsProps = {
    cart: Cart;
    data: DataField[];
};

export function ParticipantsInfosFields({ cart, data }: ParticipantsInfosFieldsProps) {
    const { t } = useTranslation();
    const { participants } = cart;

    const isSame = useWatch({ name: "isSame" });

    const sessionIds = Object.keys(cart.participants);

    if (isSame) {
        return (
            <Stack gap={2}>
                {participants[sessionIds[0]].map(({ id, quantite, nom }) =>
                    Array.from(Array(parseInt(quantite, 10))).map((_, index) => (
                        <ParticipantInfosCard
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${nom}-${index}`}
                            data={data}
                            prefix={`informations-participants.${sessionIds[0]}.${id}.${index}`}
                            title={`${nom} ${t("n°", { number: index + 1 })}`}
                        />
                    )),
                )}
            </Stack>
        );
    }

    return (
        <Stack gap={4}>
            {sessionIds.map((sessionId) =>
                participants[sessionId].map(({ id, quantite, nom }) => (
                    <Stack key={id} gap={2}>
                        <Typography fontWeight={600}>
                            {getSessionById(cart, parseInt(sessionId, 10))?.nom} ·{" "}
                            {dt.formatLocale(
                                dt.fromISO(getSessionById(cart, parseInt(sessionId, 10))?.date_deb),
                                DateFormat.DATE_FULL,
                            )}
                        </Typography>
                        {Array.from(Array(parseInt(quantite, 10))).map((_, index) => (
                            <ParticipantInfosCard
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${nom}-${index}`}
                                data={data}
                                prefix={`informations-participants.${sessionId}.${id}.${index}`}
                                title={`${nom} ${t("n°", { number: index + 1 })}`}
                            />
                        ))}
                    </Stack>
                )),
            )}
        </Stack>
    );
}
