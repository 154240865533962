import { useTranslation } from "@resamare/translation";
import { isEmpty } from "@resamare/functions";
import { Collapse, Stack, renderElementsWithDividers } from "@resamare/ui";

import { CartLineOptions } from "./CartLineOptions";
import { CartLine } from "../../../../models";
import { LineParticipants } from "../Line/LineParticipants/LineParticipants";

type CartLineInfosProps = {
    collapse?: boolean;
    line: CartLine;
};

export function CartLineInfos({ collapse, line }: CartLineInfosProps) {
    const { t } = useTranslation();

    const elements = [];

    if (line.participants) {
        elements.push(<LineParticipants key="guests" participants={line.participants} />);
    }

    if (!isEmpty(line.options)) {
        elements.push(<CartLineOptions key="options" line={line} />);
    }

    return (
        <div>
            {collapse ? (
                <Collapse label={line.participants ? t("Afficher détails") : t("Afficher options")}>
                    <Stack gap={2}>{renderElementsWithDividers(elements)}</Stack>
                </Collapse>
            ) : (
                <Stack gap={2}>{renderElementsWithDividers(elements)}</Stack>
            )}
        </div>
    );
}
