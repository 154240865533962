import {
    Alert as MuiAlert,
    AlertTitle as MuiAlertTitle,
    AlertColor as MuiAlertColor,
} from "@mui/material";
import { ErrorIcon, InfoIcon, SuccessIcon, Typography, WarningIcon } from "../../General";

import Styles from "./Alert.module.scss";

interface AlertProps {
    children?: string | React.ReactNode;
    severity: MuiAlertColor;
    showIcon?: boolean;
    title?: string;
}
export function Alert({ children, severity, showIcon = true, title }: AlertProps) {
    const iconMapping = {
        error: <ErrorIcon />,
        info: <InfoIcon />,
        warning: <WarningIcon />,
        success: <SuccessIcon />,
    };

    return (
        <MuiAlert icon={showIcon ? iconMapping[severity] : false} severity={severity}>
            {title ? (
                <MuiAlertTitle>
                    <Typography fontWeight={600}>{title}</Typography>
                </MuiAlertTitle>
            ) : null}

            {children ? (
                <Typography className={Styles["Alert__content"]}>{children}</Typography>
            ) : null}
        </MuiAlert>
    );
}
