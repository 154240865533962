import { ensure } from "@resamare/functions";
import { useGetMultipleSessions, usePrefetchSessionOptionsForm } from "../../sessions";
import { OptionsContainer } from "../containers";
import { useCreateCartLineAndNavigate, useSearchParamsOptions } from "../hooks";

export function OptionsPage() {
    const { sessionIds, participants } = useSearchParamsOptions();
    const { sessions } = useGetMultipleSessions(sessionIds);
    usePrefetchSessionOptionsForm(sessionIds, { participants });

    const firstBookableSession = ensure(sessions.find((session) => session?.dispo_vente));
    const defaultValues = {
        session_id: firstBookableSession.id,
        hour: firstBookableSession.heure_depart.value,
        lieu_id: firstBookableSession.lieux_depart[0].value.toString(),
        participants,
    };

    const { handleCreateCartLine, isLoading } = useCreateCartLineAndNavigate();

    return (
        <OptionsContainer
            defaultValues={defaultValues}
            isLoading={isLoading}
            onSubmit={handleCreateCartLine}
            participants={participants}
            sessions={sessions}
        />
    );
}
