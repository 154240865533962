import { createContext, useContext, useMemo, useState } from "react";
import { ExcursionSearch, LiaisonSearch, NavibusSearch, PackageSearch } from "../types";

type SearchType = ExcursionSearch | LiaisonSearch | NavibusSearch | PackageSearch;

interface SearchContextType<T> {
    search: T | null;
    setSearch: React.Dispatch<React.SetStateAction<T | null>>;
}

const SearchContext = createContext<SearchContextType<SearchType> | null>(null);

type SearchProviderProps = {
    children: React.ReactNode;
};

export function SearchProvider({ children }: SearchProviderProps) {
    const [search, setSearch] = useState<SearchType | null>(null);

    const contextValue = useMemo(
        () => ({
            search,
            setSearch,
        }),
        [search],
    );

    return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
}

export function useSearchContext<T>() {
    const context = useContext(SearchContext) as SearchContextType<T> | null; // Assertion de type

    if (context === null) {
        throw new Error("useSearchContext must be used within a SearchProvider");
    }

    return context;
}
