import { RadioOption } from "../../FormRadio/types";
import { SelectOption } from "../../FormSelect/SelectField";

export interface FormData {
    title?: string;
    description?: string;
    sections: Section[];
}

export interface Section {
    title: string;
    short_description?: string;
    description?: string;
    id: string;
    fields: Field[];
    validation?: SectionValidationRule[];
}

interface ValidationRule {
    max?: number;
    min?: number;
    required?: boolean;
    message: string;
}

export interface SectionValidationRule extends ValidationRule {
    fields: string[];
}

export interface FieldValidationRule extends ValidationRule {
    oneOf?: string[];
    mimes?: string[];
}

export type Field =
    | CheckboxField
    | DateField
    | FileField
    | NumberField
    | NumberStepperField
    | RadioField
    | SelectField
    | SelectMultipleField
    | TextField
    | TextAreaField;

export enum FieldType {
    CHECKBOX = "checkbox",
    DATE = "date",
    FILE = "file",
    NUMBER = "numerique",
    NUMBER_STEPPER = "quantity",
    RADIO = "radio",
    SELECT = "select",
    SELECT_MULTIPLE = "select multiple",
    TEXT = "text",
    TEXTAREA = "textarea",
}

interface BaseField {
    type: FieldType;
    name: string;
    label: string;
    description?: string | null;
    defaultValue?: string | null;
    validation?: FieldValidationRule[];
}

export interface CheckboxField extends Omit<BaseField, "defaultValue"> {
    type: FieldType.CHECKBOX;
    defaultValue?: boolean;
}

export interface DateField extends BaseField {
    type: FieldType.DATE;
}

export interface FileField extends BaseField {
    type: FieldType.FILE;
    fileMimes: string[];
    fileMaxSize: number;
}

export interface NumberField extends BaseField {
    type: FieldType.NUMBER;
    defaultValue?: string;
}

export interface NumberStepperField extends BaseField {
    type: FieldType.NUMBER_STEPPER;
}

export interface RadioField extends BaseField {
    type: FieldType.RADIO;
    options: RadioOption[];
}

export interface SelectField extends BaseField {
    type: FieldType.SELECT;
    options: SelectOption[];
}

export interface SelectMultipleField extends BaseField {
    type: FieldType.SELECT_MULTIPLE;
    options: SelectOption[];
}

export interface TextField extends BaseField {
    type: FieldType.TEXT;
}

export interface TextAreaField extends BaseField {
    type: FieldType.TEXTAREA;
}
