import { formatCurrency } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Button, Stack, Typography } from "@resamare/ui";

import styles from "./NavibusCard.module.scss";

type NavibusCardFooterProps = {
    onClick: () => void;
    total?: number;
};

export function NavibusCardFooter({ onClick, total }: NavibusCardFooterProps) {
    const { t } = useTranslation();

    return (
        <Stack className={styles["navibus-card__footer"]} gap={1}>
            {total ? (
                <Stack>
                    <Typography variant="text-sm">{t("À partir de")}</Typography>
                    <Stack alignItems="baseline" direction="row" gap={1}>
                        <Typography variant="title-sm">{formatCurrency(total)}</Typography>
                    </Stack>
                </Stack>
            ) : null}
            <Button onClick={onClick}>{t("Sélectionner")}</Button>
        </Stack>
    );
}
