import { useRef } from "react";
import { Button as MuiButton } from "@mui/material";
import { isEmpty } from "@resamare/functions";
import { FormControl, FormHelperText, FormInputProps, FormLabel } from "../_partials";
import { Stack } from "../../Layout";
import { ButtonCustom, Typography } from "../../General";

import { MimeExtensionsMap } from "./mimes";
import { formatExtensions, formatSize, getExtensionsFromMimes } from "./utils";

import buttonStyles from "../../General/Button/Button.module.scss";
import styles from "./FileField.module.scss";

export interface FileFieldProps extends Pick<FormInputProps, "inputRef" | "name" | "placeholder"> {
    fileMaxSize: number;
    fileMimes: (keyof MimeExtensionsMap)[];
    helperText?: string;
    isError?: boolean;
    label: string;
    multiple?: boolean;
    onChange: (files: FileList | null) => void;
    value: FileList | null;
}

export function FileField({
    fileMaxSize,
    fileMimes,
    helperText = "",
    inputRef,
    isError = false,
    label,
    multiple = false,
    name,
    onChange,
    placeholder = "Aucun fichier",
    value,
}: FileFieldProps) {
    const ref = useRef<null | HTMLDivElement>(null);

    const isFilePresent = !isEmpty(value) && value !== null;

    const extensions = getExtensionsFromMimes(fileMimes);
    const fileInfos = `${formatExtensions(extensions)} (max: ${formatSize(fileMaxSize)})`;

    const handleOnClick = () => {
        const input = ref.current?.querySelector<HTMLInputElement>("input");
        if (input) {
            input.click();
        }
    };

    return (
        <FormControl isError={isError}>
            <Stack gap={0.5}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <FormHelperText>{fileInfos}</FormHelperText>
                <ButtonCustom
                    className={`${styles["input-container"]} ${isError ? styles["is-error"] : ""}`}
                    onClick={handleOnClick}
                >
                    <Stack alignItems="center" component="span" direction="row" gap={2}>
                        <MuiButton
                            className={`${buttonStyles["Button"]} ${buttonStyles["Button--medium"]}`}
                            component="span"
                            variant="contained"
                        >
                            Choisir un fichier
                        </MuiButton>
                        <Typography
                            className={styles["placeholder"]}
                            component="span"
                            variant="text-sm"
                        >
                            {!isFilePresent ? placeholder : null}
                            {isFilePresent && value.length === 1 ? value[0].name : null}
                            {isFilePresent && value.length > 1 ? `${value.length} fichiers` : null}
                        </Typography>
                    </Stack>
                </ButtonCustom>
                <div ref={ref}>
                    <input
                        ref={inputRef}
                        accept={fileMimes.join(", ")}
                        className={styles["input"]}
                        id={name}
                        multiple={multiple}
                        name={name}
                        onChange={(e) => {
                            onChange(e.target.files);
                        }}
                        type="file"
                    />
                </div>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </Stack>
        </FormControl>
    );
}
