import { ItineraryOption } from "./types";

export const getAvailableStopovers = (
    options: ItineraryOption[],
    value: [string, ...string[], string],
) => {
    const departureIndex = options.findIndex((option) => option.value === value[0]);
    const arrivalIndex = options.findIndex((option) => option.value === value[value.length - 1]);

    if (departureIndex === -1 || arrivalIndex === -1) {
        return [];
    }

    if (departureIndex === arrivalIndex) {
        return options
            .filter((option) => option.value !== options[departureIndex].value)
            .map((option) => option.value);
    }

    if (departureIndex < arrivalIndex) {
        return options
            .filter((_option, index) => index > departureIndex && index < arrivalIndex)
            .map((option) => option.value);
    }

    if (departureIndex > arrivalIndex) {
        return options
            .filter((_option, index) => index > departureIndex || index < arrivalIndex)
            .map((option) => option.value);
    }

    return options.map((option) => option.value);
};
