import { DynamicField, Field, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";

type ReservationInfosSectionProps = {
    fields: Field[];
};

export function ReservationInfosSection({ fields }: ReservationInfosSectionProps) {
    const { t } = useTranslation();

    return (
        <Stack gap={3}>
            <Typography variant="title-xs">{t("Informations supplémentaires")}</Typography>

            {fields.map((field) => (
                <DynamicField key={field.name} data={field} />
            ))}
        </Stack>
    );
}
