import { Spinner, Stack } from "@resamare/ui";

import { Section } from "../../components";
import { SellingDisabledMessage } from "./components/SellingDisabledMessage";
import { FormDisplay } from "./components/FormDisplay";
import { FormType, useGetProductTypes } from "../product-types";
import { useSearchContext } from "./providers/SearchProvider";
import { useClientConfig } from "../client-details";
import { useUrlActivityType } from "./hooks/useUrlActivityType";
import { Navigation } from "./components/Navigation";

import styles from "./SearchModule.module.scss";

type SearchModuleProps = {
    backgroundMode?: "color" | "image";
    forms: Record<FormType, React.ReactNode>;
    maxWidth?: number;
    mode?: "full" | "light";
    renderBefore?: () => React.ReactNode;
};

export function SearchModule({
    backgroundMode = "image",
    forms,
    maxWidth,
    mode = "full",
    renderBefore,
}: SearchModuleProps) {
    const { setSearch } = useSearchContext();
    const { config } = useClientConfig();
    const productTypesQuery = useGetProductTypes();
    const activityType = useUrlActivityType() as FormType;

    const handleOnChange = () => setSearch(null);

    const disabledMessage = (
        <Stack className={styles["search-content"]}>
            <Stack className={styles["search-container"]} gap={1}>
                <SellingDisabledMessage />
            </Stack>
        </Stack>
    );

    const searchContent = config.vente_en_ligne ? (
        <Stack className={styles["search-content"]}>
            {renderBefore ? (
                <Stack className={styles["search-container"]} gap={1}>
                    {renderBefore()}
                </Stack>
            ) : null}
            {productTypesQuery.isPending ? <Spinner /> : null}
            {productTypesQuery.data && (
                <>
                    <Navigation onChange={handleOnChange} productTypes={productTypesQuery.data} />
                    <div className={styles["search-container"]}>
                        <FormDisplay activityType={activityType} forms={forms} />
                    </div>
                </>
            )}
        </Stack>
    ) : (
        disabledMessage
    );

    if (mode === "light") return searchContent;

    return (
        <div
            className={styles[`search-bg-${backgroundMode}`]}
            style={{ backgroundImage: backgroundMode === "image" ? `url(${config.image})` : "" }}
        >
            <Section>
                <div style={{ maxWidth }}>{searchContent}</div>
            </Section>
        </div>
    );
}
