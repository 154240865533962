import { useState } from "react";
import { Collapse as MuiCollapse, CollapseProps as MuiCollapseProps } from "@mui/material";
import { Stack } from "../../Layout";
import { ButtonLink, ChevronBottomIcon, ChevronTopIcon } from "../../General";

import styles from "./Collapse.module.scss";

type CollapseProps = Pick<MuiCollapseProps, "collapsedSize"> & {
    children: React.ReactNode;
    displayButtonAfter?: boolean;
    displayOverlay?: boolean;
    gap?: number;
    label: string;
    variant?: React.ComponentProps<typeof ButtonLink>["variant"];
};

export function Collapse({
    children,
    collapsedSize,
    displayButtonAfter = false,
    displayOverlay = false,
    gap,
    label,
    variant,
}: CollapseProps) {
    const [isOpen, setIsOpen] = useState(false);
    const handleOnClick = () => setIsOpen(!isOpen);
    return (
        <Stack className={styles["collapse"]} gap={gap}>
            {!displayButtonAfter ? (
                <ButtonLink fullWidth onClick={handleOnClick} variant={variant}>
                    <Stack
                        alignItems="center"
                        component="span"
                        direction="row"
                        justifyContent="space-between"
                    >
                        {label}
                        {isOpen ? (
                            <ChevronTopIcon size="small" />
                        ) : (
                            <ChevronBottomIcon size="small" />
                        )}
                    </Stack>
                </ButtonLink>
            ) : null}
            <MuiCollapse collapsedSize={collapsedSize} in={isOpen} sx={{ position: "relative" }}>
                {children}
                {!isOpen && displayOverlay ? <div className={styles["collapse__overlay"]} /> : null}
            </MuiCollapse>
            {displayButtonAfter ? (
                <ButtonLink fullWidth onClick={handleOnClick} variant={variant}>
                    <Stack
                        alignItems="center"
                        component="span"
                        direction="row"
                        justifyContent="space-between"
                    >
                        {label}
                        {isOpen ? (
                            <ChevronTopIcon size="small" />
                        ) : (
                            <ChevronBottomIcon size="small" />
                        )}
                    </Stack>
                </ButtonLink>
            ) : null}
        </Stack>
    );
}
