/* eslint-disable @typescript-eslint/naming-convention */
import { Alert, Button, Stack } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { formatCurrency } from "@resamare/functions";
import { Cart } from "../../../../models";

type CheckoutFormButtonProps = {
    isLoading: boolean;
    cart: Cart;
};

export function CheckoutFormButton({ isLoading, cart }: CheckoutFormButtonProps) {
    const { t } = useTranslation();

    const { accompte_panier, montant_regle, resa_accompte, total_panier, type_paiement } = cart;

    if (type_paiement === "aucun") {
        return (
            <Stack gap={2}>
                <Alert severity="info">
                    {t(
                        "En cliquant sur « Réserver » votre réservation sera validée. Le paiement s'effectuera le jour de l'activité.",
                    )}
                </Alert>
                <Button isLoading={isLoading} type="submit">
                    {t("Réserver")}
                </Button>
            </Stack>
        );
    }

    if (montant_regle > 0 && resa_accompte) {
        return (
            <Stack gap={2}>
                <Alert severity="info">
                    {t(
                        "En cliquant sur « Confirmer » vous confirmez le repport de la réservation. Le reste du règlement se fera le jour de l'excursion.",
                    )}
                </Alert>
                <Button isLoading={isLoading} type="submit">
                    {t("Confirmer")} ({formatCurrency(parseFloat(cart.total_panier))})
                </Button>
            </Stack>
        );
    }

    if (montant_regle > 0) {
        return (
            <Stack gap={2}>
                <Alert severity="info">
                    {t(
                        "En cliquant sur « Confirmer » vous confirmez le repport de la réservation. Si il y a un reste à payer, vous allez être redirigé vers le paiement en ligne.",
                    )}
                </Alert>
                <Button isLoading={isLoading} type="submit">
                    {t("Confirmer")} ({formatCurrency(parseFloat(cart.total_panier))})
                </Button>
            </Stack>
        );
    }

    if (parseFloat(accompte_panier) > 0 && type_paiement === "acompte") {
        return (
            <Stack gap={2}>
                <Alert severity="info">
                    {t(
                        "Pour valider votre réservation, vous devez régler un acompte de {{amount}} aujourd'hui. Le montant restant sera à régler à une date ultérieure.",
                        { amount: formatCurrency(parseFloat(accompte_panier)) },
                    )}
                </Alert>
                <Button isLoading={isLoading} type="submit">
                    {t("Payer l'acompte")} ({formatCurrency(parseFloat(accompte_panier))})
                </Button>
            </Stack>
        );
    }

    return (
        <Button isLoading={isLoading} type="submit">
            {t("Paiement")} ({formatCurrency(parseFloat(total_panier))})
        </Button>
    );
}
