import { useTranslation } from "@resamare/translation";
import { ButtonLink, FormDate, FormDateRange, FormError, Stack } from "@resamare/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { dt } from "@resamare/functions";
import { useClientConfig } from "../../../../client-details";
import { TripType } from "../../../../search";

export function LiaisonFormDates() {
    const { t } = useTranslation();

    const { config } = useClientConfig();

    const { getValues, setValue } = useFormContext();

    const tripType = useWatch({
        name: "trip-type",
        defaultValue: getValues("trip-type") || TripType.ROUNDTRIP,
    });

    const handleSwitchToRoundtrip = () => {
        const selectedDate = getValues("dates[0]");
        setValue("trip-type", TripType.ROUNDTRIP);
        setValue("dates", [selectedDate, selectedDate]);
    };

    const maxDate = config.date_fin ? dt.fromISO(config.date_fin) : undefined;
    const minDate = config.date_debut
        ? dt.fromISO(dt.removeIsoOffset(config.date_debut))
        : undefined;

    return tripType === TripType.ROUNDTRIP ? (
        <FormDateRange
            label={t("Dates de voyage")}
            locale={config.langue}
            maxDate={maxDate}
            minDate={minDate}
            name="dates"
        />
    ) : (
        <div>
            <FormDate
                label={t("Départ le")}
                locale={config.langue}
                maxDate={maxDate}
                minDate={minDate}
                name="dates.0"
            />
            <FormError name="dates" />
            <Stack alignItems="flex-end">
                <ButtonLink onClick={handleSwitchToRoundtrip}>
                    + {t("Ajouter un retour")}
                </ButtonLink>
            </Stack>
        </div>
    );
}
