import { useTranslation } from "@resamare/translation";
import { Alert, EmailIcon, Link, PhoneIcon, Stack, Typography } from "@resamare/ui";
import { useClientConfig } from "../../../client-details";

export function NotAvailableMessage() {
    const { t } = useTranslation();

    const { config } = useClientConfig();

    return (
        <Alert severity="warning" showIcon={false} title={t("Vente en ligne désactivée")}>
            <Typography component="span">{t("Contactez-nous pour réserver.")}</Typography>
            <Stack alignItems="center" component="span" direction="row" gap={1}>
                <PhoneIcon size="small" />
                <Link href={`tel:${config.telephone}`}>{config.telephone}</Link>
            </Stack>
            <Stack alignItems="center" component="span" direction="row" gap={1}>
                <EmailIcon size="small" />
                <Link href={`mailto:${config.email}`}>{config.email}</Link>
            </Stack>
        </Alert>
    );
}
