import { IconButtonProps as MuiIconButtonProps, IconButton as MuiIconButton } from "@mui/material";

import styles from "./IconButton.module.scss";

declare module "@mui/material/IconButton" {
    interface IconButtonPropsColorOverrides {
        primaryLink?: true;
    }
}

export interface IconButtonProps
    extends Pick<
        MuiIconButtonProps,
        "aria-controls" | "aria-label" | "className" | "color" | "disabled" | "edge" | "onClick"
    > {
    children: React.ReactNode;
}

export function IconButton({
    "aria-controls": ariaControls,
    "aria-label": ariaLabel,
    className,
    color = "primary",
    children,
    edge = "end",
    ...rest
}: IconButtonProps) {
    return (
        <MuiIconButton
            aria-controls={ariaControls}
            aria-label={ariaLabel}
            className={`${styles["icon-button"]} ${className || ""}`}
            color={color === "primary" ? "primaryLink" : color}
            edge={edge}
            {...rest}
        >
            {children}
        </MuiIconButton>
    );
}
