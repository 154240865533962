import * as React from "react";

export function LoupIllustration() {
    return (
        <svg
            height={96}
            viewBox="0 0 512 512"
            width={96}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx={256}
                cy={256}
                r={256}
                style={{
                    fill: "#88c5cc",
                }}
            />
            <path
                d="M333.645 329.666h32v36h-32z"
                style={{
                    fill: "#e6e6e6",
                }}
                transform="rotate(-45.001 349.643 347.672)"
            />
            <path
                d="M387.84 351.9c-9.372-9.372-24.572-9.372-33.94 0-9.372 9.376-9.372 24.572 0 33.94l66.42 66.416a257.648 257.648 0 0 0 33.764-34.112L387.84 351.9z"
                style={{
                    fill: "#263740",
                }}
            />
            <circle
                cx={256}
                cy={256}
                r={112}
                style={{
                    fill: "#c2e2f2",
                }}
            />
            <circle
                cx={288}
                cy={216}
                r={28}
                style={{
                    fill: "#daeaf2",
                }}
            />
            <path
                d="M208.804 335.196c-43.748-43.74-43.744-114.652 0-158.392 17.788-17.788 40.076-28.34 63.196-31.66-33.732-4.84-69.252 5.712-95.196 31.66-43.744 43.74-43.748 114.652 0 158.392 25.944 25.948 61.464 36.5 95.196 31.66-23.12-3.32-45.408-13.872-63.196-31.66z"
                style={{
                    fill: "#b8d6e6",
                }}
            />
            <path
                d="M343.68 343.68c-48.348 48.348-127.016 48.348-175.364 0-48.344-48.348-48.348-127.012 0-175.364 48.352-48.348 127.016-48.348 175.364 0s48.348 127.016 0 175.364zM185.288 185.288c-38.992 38.992-38.992 102.428 0 141.416 38.988 38.988 102.424 38.988 141.416 0s38.992-102.432.004-141.416c-38.988-38.988-102.432-38.988-141.42 0z"
                style={{
                    fill: "#242424",
                }}
            />
            <path
                d="M399.152 329.272c3.112 3.108 3.112 8.204 0 11.312l-56.568 56.572c-3.108 3.108-8.208 3.108-11.312 0l-5.66-5.656c-3.108-3.108-3.108-8.204 0-11.316l56.572-56.568c3.108-3.108 8.204-3.112 11.312 0l5.656 5.656z"
                style={{
                    fill: "#ffd464",
                }}
            />
        </svg>
    );
}
