import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";

import { dt, flat } from "@resamare/functions";
import { apiClient } from "../../../config";
import { Liaison, Product } from "../../../models";

type GetLiaionsDTO = {
    date_deb: Date;
    date_retour?: Date;
    lieu_depart?: string[];
    lieu_destination?: string[];
    participants: Record<string, number>;
    retour?: boolean;
    session_aller_id?: string;
    session_aller_prix?: number;
    type_produit: number;
    ville_depart?: string[];
    ville_destination?: string[];
};

async function getLiaisons(params: GetLiaionsDTO): Promise<Liaison[]> {
    if (!params) {
        throw new Error("Vous devez envoyer des paramètres pour récupérer des liaisons");
    }

    let returnDate = params.date_retour;

    if (returnDate && !dt.isAfter(returnDate, params.date_deb)) {
        returnDate = params.date_deb;
    }

    const { data } = await apiClient.get<{ liste_produit: Product[] }>("/recherche", {
        params: {
            ...params,
            date_deb: dt.toIsoDate(params.date_deb),
            date_retour: returnDate ? dt.toIsoDate(returnDate) : null,
            participants: JSON.stringify(params.participants),
        },
    });

    const liaisons = flat(data.liste_produit.map((product) => product.sessions));

    return liaisons as unknown as Liaison[];
}

type UseGetLiaisonsOptions = {
    params: GetLiaionsDTO | null;
};

export function useGetLiaisons({ params }: UseGetLiaisonsOptions) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["liaisons", { ...params }],
        queryFn: async () => {
            const liaisons = await getLiaisons(params as GetLiaionsDTO);
            liaisons.forEach((liaison) => {
                queryClient.setQueryData(["liaisons", liaison.id], liaison);
            });
            return liaisons;
        },
        enabled: !!params,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        structuralSharing: false,
        staleTime: 1 * 60 * 1000,
    });

    return query;
}
