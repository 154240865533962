import { useController } from "react-hook-form";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { FileField, FileFieldProps } from "./FileField";

type FormDateProps = Pick<
    FileFieldProps,
    "fileMimes" | "fileMaxSize" | "helperText" | "label" | "multiple" | "name" | "placeholder"
>;

export function FormFile({
    fileMimes,
    fileMaxSize,
    helperText,
    label,
    multiple = true,
    name,
    placeholder,
}: FormDateProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue: null,
    });

    return (
        <FieldWrapper name={name}>
            <FileField
                fileMaxSize={fileMaxSize}
                fileMimes={fileMimes}
                helperText={helperText}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                multiple={multiple}
                name={name}
                onChange={field.onChange}
                placeholder={placeholder}
                value={field.value}
            />
        </FieldWrapper>
    );
}
