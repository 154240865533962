import { Alert, DateField, Spinner, Stack } from "@resamare/ui";

import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { dt, isEmpty } from "@resamare/functions";
import { PackageChoiceSessions } from "./PackageChoiceSessions";
import { ExcursionOptionsValues } from "../../../activity-types/excursion";
import { useClientConfig } from "../../../client-details";
import { useGetDatesAvailable } from "../../../dates-available";
import { Step } from "../../../../components";

type PackageChoiceProps = {
    isCompleted: boolean;
    isDisabled: boolean;
    maxDate?: Date;
    minDate?: Date;
    onDateChange?: (date: Date) => void;
    onEdit: () => void;
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    product: { id: number; nom: string };
    step: number | null;
};

export function PackageChoice({
    isCompleted,
    isDisabled,
    maxDate,
    minDate,
    onDateChange,
    onEdit,
    onSubmit,
    product,
    step,
}: PackageChoiceProps) {
    const { config } = useClientConfig();

    const datesAvailableQuery = useGetDatesAvailable({ productId: product.id.toString() });

    const availableDates = datesAvailableQuery.data?.date_dispo.map((date) => dt.fromISO(date));
    const blockedDates = datesAvailableQuery.data?.date_bloquee.map((date) => dt.fromISO(date));

    const [date, setDate] = useState<Date | null>(null);

    const handleOnDateChange = (newDate: Date) => {
        if (onDateChange) {
            onDateChange(newDate);
        }
        setDate(newDate);
    };

    if (datesAvailableQuery.isPending) {
        return <Spinner />;
    }

    return (
        <Stack gap={2}>
            {step ? (
                <Step
                    isCompleted={isCompleted}
                    isDisabled={isCompleted ? false : isDisabled}
                    label={product.nom}
                    onClickEdit={onEdit}
                    step={step}
                    stepLabel="Produit"
                />
            ) : null}
            {isDisabled || isCompleted ? null : (
                <Stack gap={3}>
                    {isEmpty(availableDates) ? (
                        <Alert severity="error" title="Aucune date disponible">
                            Actuellement, aucune date n'est disponible pour ce produit. Veuillez
                            nous contacter ou réessayer ultérieurement.
                        </Alert>
                    ) : (
                        <DateField
                            availableDates={availableDates}
                            blockedDates={blockedDates}
                            inline={step === null}
                            label="Date"
                            locale={config.langue}
                            maxDate={maxDate}
                            minDate={minDate}
                            name="date_deb"
                            onChange={handleOnDateChange}
                            placeholder="Choisissez une date"
                            value={date}
                        />
                    )}
                    {date ? (
                        <PackageChoiceSessions
                            date={date}
                            onDateChange={handleOnDateChange}
                            onSubmit={onSubmit}
                            productId={product.id}
                        />
                    ) : null}
                </Stack>
            )}
        </Stack>
    );
}
