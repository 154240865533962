import { useTranslation } from "@resamare/translation";
import { Button, Stack, Typography } from "@resamare/ui";
import { FishingIllustration } from "../../../../assets";

import styles from "./EmptyCartMessage.module.scss";

type EmptyCartMessageProps = {
    onClick?: () => void;
};

export function EmptyCartMessage({ onClick }: EmptyCartMessageProps) {
    const { t } = useTranslation();

    return (
        <Stack alignItems="center" gap={5}>
            <div className={styles["illustration"]}>
                <FishingIllustration />
            </div>
            <Stack alignItems="center" gap={2}>
                <Stack alignItems="center">
                    <Typography align="center" variant="title">
                        {t("Votre panier est vide")}
                    </Typography>
                    {onClick ? (
                        <Typography align="center">
                            {t(
                                "Cliquez ci-dessous pour le remplir en découvrant nos différents produits.",
                            )}
                        </Typography>
                    ) : null}
                </Stack>
                {onClick ? <Button onClick={onClick}>{t("Découvrir nos produits")}</Button> : null}
            </Stack>
        </Stack>
    );
}
