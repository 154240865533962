import { removeHtml } from "@resamare/functions";
import { ProductSection } from "@resamare/models";
import { useTranslation } from "@resamare/translation";
import { FormItineraryPlanner, Stack } from "@resamare/ui";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetProductType } from "../../../product-types";

type NavibusItineraryPlannerProps = {
    productSections: ProductSection[];
};

const getMaxItinerarySections = (itinerary: string[], sections: ProductSection[]) => {
    if (itinerary.some((step) => step === "")) return 3;

    if (itinerary.every((step) => step === sections[0].lieu_depart.id)) return 3;
    if (itinerary.every((step) => step === itinerary[0])) return 2;

    const firstSectionDeparture = sections[0].lieu_depart.id;
    const departureId = itinerary[0];
    const arrivalId = itinerary[itinerary.length - 1];

    if (departureId === firstSectionDeparture || arrivalId === firstSectionDeparture) return 3;

    if (parseInt(departureId, 10) > parseInt(arrivalId, 10)) return 2;

    return 3;
};

export function NavibusItineraryPlanner({ productSections }: NavibusItineraryPlannerProps) {
    const { t } = useTranslation();

    const { productTypeId } = useParams() as { productTypeId: string };
    const productTypeQuery = useGetProductType(productTypeId);

    const options = useMemo(
        () =>
            productSections.map(({ lieu_depart }) => ({
                label: lieu_depart.nom,
                value: lieu_depart.id,
            })),
        [productSections],
    );

    const itinerary = useWatch({ name: "itinerary", defaultValue: ["", ""] });

    const description = productTypeQuery.data?.description;
    const max = getMaxItinerarySections(itinerary, productSections);

    return (
        <Stack gap={0.5}>
            <FormItineraryPlanner
                helperText={description ? removeHtml(description) : undefined}
                label={t("Itinéraire")}
                max={max}
                name="itinerary"
                options={options}
            />
        </Stack>
    );
}
