import { FormGroup as MuiFormGroup } from "@mui/material";
import { FormControl, FormHelperText, FormLabel } from "../_partials";
import { Stack } from "../../Layout";
import { CheckboxItem } from "./CheckboxItem";

export type CheckboxOption = {
    label: string | React.ReactNode;
    name: string;
    attributes?: {
        disabled: boolean;
    };
};

type CheckboxUnique = {
    options: [CheckboxOption];
    onChange: (data: boolean) => void;
    value: boolean;
};

type ICheckboxGroup = {
    options: CheckboxOption[];
    onChange: (data: Record<string, boolean> | boolean) => void;
    value: Record<string, boolean>;
};

type CheckboxGroupProps = (CheckboxUnique | ICheckboxGroup) & {
    alignTop?: boolean;
    helperText?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    isError?: boolean;
    label?: string;
};

function CheckboxGroup({
    alignTop,
    helperText = "",
    inputRef,
    isError = false,
    label,
    onChange,
    options,
    value,
}: CheckboxGroupProps) {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof value === "boolean") {
            onChange(event.target.checked);
            return;
        }

        onChange({
            ...value,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <FormControl isError={isError}>
            <Stack gap={0.5}>
                {label ? <FormLabel htmlFor="">{label}</FormLabel> : null}

                <MuiFormGroup>
                    {options.map((option) => (
                        <CheckboxItem
                            key={option.name}
                            alignTop={alignTop}
                            checked={
                                typeof value === "boolean"
                                    ? value === true
                                    : value[option.name] === true
                            }
                            disabled={option.attributes?.disabled}
                            inputRef={inputRef}
                            label={option.label}
                            name={option.name}
                            onChange={handleOnChange}
                        />
                    ))}
                </MuiFormGroup>

                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </Stack>
        </FormControl>
    );
}

export default CheckboxGroup;
