import React from "react";

import { Stack } from "../../Layout";
import { CheckIcon, Typography } from "../../General";

import styles from "./StepIndicator.module.scss";

type StepIndicatorProps = {
    isCompleted?: boolean;
    isDisabled?: boolean;
    size?: "small" | "large" | "medium";
    step: number;
};

export function StepIndicator({
    isCompleted,
    isDisabled,
    size = "medium",
    step,
}: StepIndicatorProps) {
    return (
        <Stack
            className={`${styles["step-indicator"]} ${
                isCompleted ? styles["step-indicator--is-completed"] : ""
            } ${isDisabled ? styles["step-indicator--is-disabled"] : ""} ${
                size === "large" ? styles["step-indicator--is-large"] : ""
            } ${size === "small" ? styles["step-indicator--is-small"] : ""}`}
        >
            {isCompleted ? (
                <CheckIcon />
            ) : (
                <Typography variant={size === "large" ? "text" : "text-sm"}>{step}</Typography>
            )}
        </Stack>
    );
}
