import { Spinner, Stack } from "@resamare/ui";
import { useGetCart } from "../../cart";
import { Section } from "../../../components";
import { Checkout } from "../components";

export function CheckoutFormPage() {
    const cartQuery = useGetCart();

    return (
        <Section>
            <Stack gap={3}>
                {cartQuery.isPending ? <Spinner /> : null}

                {cartQuery.data ? <Checkout cart={cartQuery.data} /> : null}
            </Stack>
        </Section>
    );
}
