import { TextField, TextFieldProps } from "../FormText/TextField";

import Styles from "./NumberField.module.scss";

export interface NumberFieldProps extends Omit<TextFieldProps, "type"> {
    hideControls?: boolean;
}

function NumberField({ hideControls = false, ...rest }: NumberFieldProps) {
    return (
        <TextField
            inputClassName={hideControls ? Styles["NumberInput--hide-controls"] : ""}
            type="number"
            {...rest}
        />
    );
}

export default NumberField;
