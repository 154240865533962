import { storage } from "@resamare/functions";
import { createCart } from "./createCartKey";
import { checkCartExists } from "./checkCartExists";
import { ClientConfig } from "../../../models";
import { CART_KEY } from "../constants";

type GetCartKeyOptions = {
    isCartActivated: ClientConfig["activer_panier"];
    partenaireId: ClientConfig["partenaireId"];
    tokenReservation: ClientConfig["tokenReservation"];
};

export async function getCartKey({
    isCartActivated,
    partenaireId,
    tokenReservation,
}: GetCartKeyOptions): Promise<string> {
    if (!isCartActivated) {
        const key = await createCart({ partenaireId, tokenReservation });
        storage.setWithExpiry(CART_KEY, key);
        return key;
    }

    const localKey = storage.getWithExpiry<string>(CART_KEY);
    if (localKey && (await checkCartExists(localKey))) {
        return localKey;
    }

    const key = await createCart({ partenaireId, tokenReservation });
    storage.setWithExpiry(CART_KEY, key);
    return key;
}
