import { useTranslation } from "@resamare/translation";
import { FormCheckbox, FormHidden, Stack, Typography } from "@resamare/ui";
import { getNumberOfSessions } from "../../../cart";
import { ParticipantsInfosFields } from "../../../additional-infos";
import { DataField } from "../../../additional-infos/types";
import { Cart } from "../../../../models";

type ParticipantsInfosSectionProps = {
    cart: Cart;
    fields: DataField[];
};

export function ParticipantsInfosSection({ cart, fields }: ParticipantsInfosSectionProps) {
    const { t } = useTranslation();

    const nbOfSessions = getNumberOfSessions(cart);

    return (
        <Stack gap={3}>
            <Typography variant="title-xs">{t("Informations sur les participants")}</Typography>

            <Stack gap={1}>
                {nbOfSessions > 1 ? (
                    <FormCheckbox
                        alignTop
                        label="Les participants sont identiques sur tous les circuits"
                        name="isSame"
                    />
                ) : (
                    <FormHidden name="isSame" />
                )}

                <ParticipantsInfosFields cart={cart} data={fields} />
            </Stack>
        </Stack>
    );
}
