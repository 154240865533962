import { uid } from "@resamare/functions";
import { Field, buildYupSchema } from "@resamare/ui";

export function getReservationInfosSchema(fields: Field[]) {
    const schema = buildYupSchema({
        sections: [{ id: uid(6), title: "", fields }],
    });

    return schema;
}
