import { ErrorBoundary } from "@resamare/ui";

import styles from "./ModalLayout.module.scss";

type ModalLayoutProps = {
    children: React.ReactNode;
};

export function ModalLayout({ children }: ModalLayoutProps) {
    return (
        <div className={styles["modal-layout"]}>
            <ErrorBoundary>{children}</ErrorBoundary>
        </div>
    );
}
