import { Alert, Spinner } from "@resamare/ui";
import { useParams } from "react-router-dom";
import {
    useDefaultConfigSearch,
    useGetParticipantTypes,
    ExcursionForm as ExcursionFormUI,
    getExcursionSchema,
    useSearchContext,
    useGetSessions,
    ExcursionSearch,
} from "@resamare/widgets-shared";
import { isEqual } from "@resamare/functions";

export function ExcursionForm() {
    const { search, setSearch } = useSearchContext<ExcursionSearch>();
    const sessionsQuery = useGetSessions({ params: search });
    const { productTypeId } = useParams() as { productTypeId: string };

    const participantTypesQuery = useGetParticipantTypes({ productTypeId });
    const defaultSearch = useDefaultConfigSearch();

    if (participantTypesQuery.isPending) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data) {
        return <Alert severity="error">Aucun type de participant n'a pû être récupéré</Alert>;
    }

    const schema = getExcursionSchema(participantTypesQuery.data);

    const onSubmit = (data: ExcursionSearch) => {
        if (isEqual(data, search)) {
            sessionsQuery.refetch();
        }
        setSearch(data);
    };

    return (
        <ExcursionFormUI
            key={productTypeId}
            defaultValues={{ ...defaultSearch, type_produit: parseInt(productTypeId, 10) }}
            isLoading={sessionsQuery.isFetching}
            onSubmit={onSubmit}
            participantTypes={participantTypesQuery.data}
            schema={schema}
        />
    );
}
