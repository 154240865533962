import { useTranslation } from "@resamare/translation";
import { FormCheckbox, Stack, Typography } from "@resamare/ui";

export function NewsletterSection() {
    const { t } = useTranslation();

    return (
        <Stack gap={3}>
            <Typography variant="title-xs">{t("Newsletter")}</Typography>
            <FormCheckbox
                alignTop
                label={t(
                    "J'aimerais profiter d'offres exclusives et des dernières actualités de votre part et de celle de vos partenaires.",
                )}
                name="informations.newsletter"
            />
        </Stack>
    );
}
