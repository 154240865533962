import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../../../config";
import { ProductType } from "../types";

async function getProductType(id: string): Promise<ProductType> {
    const { data } = await apiClient.get<ProductType[]>("/type-produit");

    const productType = data.find((type) => type.value === id);

    if (!productType) {
        return Promise.reject(new Error("Product type not found"));
    }

    return productType;
}

export function useGetProductType(id: string) {
    const query = useQuery({
        queryKey: ["product-types", id],
        queryFn: () => getProductType(id),
    });

    return query;
}
