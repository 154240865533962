import { Drawer, ErrorBoundary } from "@resamare/ui";
import { useEffect, useState } from "react";
import { Location, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "@resamare/translation";
import { logError } from "../../config";

import styles from "./CheckoutProcessLayout.module.scss";
import { OverlayHeader } from "../../components";
import { useClientConfig } from "../../features/client-details";

export function CheckoutProcessLayout() {
    const { config } = useClientConfig();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const previousLocation = location.state.previousLocation as Location;

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => setIsOpen(true), []);

    const drawerWidths: { [key: string]: number } = {
        "/options": 500,
        "/additionnal-options": 500,
        "/checkout-summary": 992,
        "/checkout-form": 992,
        "/checkout-confirmation": 992,
    };

    const headerLabel: { [key: string]: string } = {
        "/options": t("Options"),
        "/additionnal-options": t("Assurance annulation"),
        "/checkout-summary": t("Résumé de la commande"),
        "/checkout-form": t("Finaliser votre commande"),
    };

    const onBack =
        config.activer_panier && location.pathname === "/checkout-form"
            ? () => navigate(-1)
            : undefined;

    return (
        <Drawer
            isOpen={isOpen}
            maxWidth={drawerWidths[location.pathname]}
            onClose={() => setIsOpen(false)}
            onTransitionExited={() => navigate(previousLocation.pathname)}
        >
            <div className={styles["checkout"]}>
                <div className={styles["checkout-inner"]}>
                    <ErrorBoundary onError={logError}>
                        {headerLabel[location.pathname] ? (
                            <OverlayHeader
                                onBack={onBack}
                                onClose={() => setIsOpen(false)}
                                position="static"
                            >
                                {headerLabel[location.pathname]}
                            </OverlayHeader>
                        ) : null}
                        <Outlet />
                    </ErrorBoundary>
                </div>
            </div>
        </Drawer>
    );
}
