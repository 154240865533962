import { Drawer, Image, Spinner, Stack, Typography } from "@resamare/ui";
import { OverlayHeader, Slide, Slider } from "../../../../components";
import { Package } from "../../../../models";
import { ModalLayout } from "../../../../layouts";
import { useGetProduct } from "../../../products";

type PackageCardDetailsProps = {
    packageItem: Package;
    isOpen: boolean;
    onClose: () => void;
};

export function PackageCardDetails({ packageItem, isOpen, onClose }: PackageCardDetailsProps) {
    return (
        <Drawer isOpen={isOpen} maxWidth={600} onClose={onClose}>
            <OverlayHeader onClose={onClose}>{packageItem.nom}</OverlayHeader>
            <div>
                {packageItem.images.length > 1 ? (
                    <Slider autoHeight>
                        {packageItem.images.map((image) => (
                            <Slide key={image}>
                                <Image src={image} />
                            </Slide>
                        ))}
                    </Slider>
                ) : (
                    <Image src={packageItem.images[0]} />
                )}
            </div>
            <ModalLayout>
                <Stack gap={6}>
                    <Typography>{packageItem.description}</Typography>
                    {packageItem.produits.map((produit) => (
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        <ProductDescription key={produit.id} product={produit} />
                    ))}
                </Stack>
            </ModalLayout>
        </Drawer>
    );
}

type ProductDescriptionProps = {
    product: { id: number; nom: string; quantite: number };
};

function ProductDescription({ product }: ProductDescriptionProps) {
    const productQuery = useGetProduct(product.id.toString());

    if (productQuery.isPending) {
        return <Spinner />;
    }

    return (
        <Stack gap={0.5}>
            <Typography fontWeight={600}>{product.nom}</Typography>

            {productQuery.data ? (
                <Typography component="div">
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: productQuery.data.description,
                        }}
                    />
                </Typography>
            ) : null}
        </Stack>
    );
}
