import { Drawer, Spinner, Stack, Typography } from "@resamare/ui";

import { useState } from "react";
import { dt } from "@resamare/functions";
import { PackageChoice } from "./PackageChoice";
import { useNavigateWithPrevious } from "../../../../hooks";
import { Package } from "../../../../models";
import { ExcursionOptionsValues } from "../../../activity-types/excursion";
import { useCreateCartLine } from "../../../cart";
import { Path } from "../../../../routes";
import { OverlayHeader } from "../../../../components";
import { ModalLayout } from "../../../../layouts";

type PackageComposerProps = {
    onClose: () => void;
    packageItem: Package | null;
};

export function PackageComposer({ packageItem, onClose }: PackageComposerProps) {
    const navigate = useNavigateWithPrevious();
    const [selectedProducts, setSelectedProducts] = useState<ExcursionOptionsValues[]>([]);
    const [firstDate, setFirstDate] = useState<Date | undefined>();

    const products = packageItem?.produits.flatMap((produit) =>
        Array.from({ length: produit.quantite }, (_, index) => ({
            id: produit.id,
            nom: produit.nom,
            key: `${produit.id}-${index}`,
        })),
    );

    const handleOnEdit = (index: number) => {
        setSelectedProducts(Object.assign([], selectedProducts, { [index]: null }));
    };

    const handleOnClose = () => {
        setSelectedProducts([]);
        onClose();
    };

    const createLineMutation = useCreateCartLine();
    const handleOnSubmit = (data: ExcursionOptionsValues, index: number) => {
        const chosenProducts = [
            ...selectedProducts.slice(0, index),
            { ...data },
            ...selectedProducts.slice(index + 1),
        ];
        setSelectedProducts(chosenProducts);

        if (chosenProducts.filter((v) => v).length === products?.length) {
            createLineMutation.mutate(chosenProducts, {
                onSuccess: () => {
                    handleOnClose();
                    navigate(Path.CHECKOUT_FORM);
                },
            });
        }
    };

    const maxDate =
        firstDate && packageItem?.periode ? dt.add(firstDate, packageItem.periode) : undefined;

    return (
        <Drawer isOpen={!!packageItem} maxWidth={560} onClose={handleOnClose}>
            <OverlayHeader onClose={onClose}>
                {products?.length === 1 ? products[0].nom : "Composez votre package"}
            </OverlayHeader>
            <ModalLayout>
                <Stack gap={5}>
                    <Stack gap={3}>
                        {products?.map((product, index) => (
                            <PackageChoice
                                key={product.key}
                                isCompleted={!!selectedProducts[index]}
                                isDisabled={index !== 0 && !selectedProducts[index - 1]}
                                maxDate={index !== 0 ? maxDate : undefined}
                                minDate={index !== 0 ? firstDate : undefined}
                                onDateChange={index === 0 ? setFirstDate : undefined}
                                onEdit={() => handleOnEdit(index)}
                                onSubmit={(data) => handleOnSubmit(data, index)}
                                product={product}
                                step={products.length === 1 ? null : index + 1}
                            />
                        ))}
                    </Stack>
                    {createLineMutation.isPending ? (
                        <Stack gap={2}>
                            <Spinner size={40} />
                            <Typography align="center">Création du package en cours...</Typography>
                        </Stack>
                    ) : null}
                </Stack>
            </ModalLayout>
        </Drawer>
    );
}
