import { FormBuilder, Spinner, Stack, Step } from "@resamare/ui";

import { useTranslation } from "@resamare/translation";
import { forwardRef } from "react";
import { SubmitHandler } from "react-hook-form";
import { Liaison } from "../../../../models";
import { NavibusSearch, useSearchContext } from "../../../search";
import { useGetSessionOptionsForm } from "../../../sessions";
import { toRefObject } from "../utils/toRefObject";

type NavibusOptionsProps = {
    liaison: Liaison;
    onSubmit: SubmitHandler<Record<string, number>>;
    step: number | null;
};

export const NavibusOptions = forwardRef<HTMLFormElement, NavibusOptionsProps>(
    ({ liaison, onSubmit, step }, ref) => {
        const { t } = useTranslation();

        const { search } = useSearchContext<NavibusSearch>() as { search: NavibusSearch };
        const optionsQuery = useGetSessionOptionsForm(liaison.id, {
            participants: search.participants,
        });

        const stepTitle = `${liaison.lieux_depart[0].label} -> ${liaison.lieux_destination[0].label}`;

        if (optionsQuery.isPending) return <Spinner />;

        return (
            <Stack gap={3}>
                {step ? (
                    <Step
                        direction="row"
                        gap={1}
                        step={step}
                        subtitle={`${t("Étape")} ${step}`}
                        title={stepTitle}
                    />
                ) : null}
                {optionsQuery.data ? (
                    <FormBuilder
                        data={optionsQuery.data}
                        formRef={toRefObject(ref)}
                        onSubmit={onSubmit}
                    />
                ) : null}
            </Stack>
        );
    },
);

NavibusOptions.displayName = "NavibusOptions";
