import React from "react";
import { Typography } from "../../../../General";

type WeekDayProps = {
    children: string;
};

function WeekDayComponent({ children }: WeekDayProps) {
    return <Typography variant="text-sm">{children}</Typography>;
}

export const WeekDay = React.memo(WeekDayComponent);
