import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from "@mui/material";
import { useRef } from "react";

interface PopoverProps extends Pick<MuiPopoverProps, "anchorOrigin" | "transformOrigin"> {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}

export function Popover({
    anchorOrigin,
    children,
    isOpen,
    onClose,
    transformOrigin,
}: PopoverProps) {
    const divRef = useRef<HTMLDivElement | null>(null);

    return (
        <div ref={divRef}>
            <MuiPopover
                anchorEl={divRef.current}
                anchorOrigin={anchorOrigin}
                onClose={onClose}
                open={isOpen}
                transformOrigin={transformOrigin}
            >
                {children}
            </MuiPopover>
        </div>
    );
}
