import { useController } from "react-hook-form";
import { PhoneField, PhoneFieldProps, defaultValueNames } from "./PhoneField";
import { Stack } from "../../Layout";
import { FormError } from "../_partials/FormError/FormError";

type FormPhoneProps = Pick<
    PhoneFieldProps,
    "defaultCode" | "helperText" | "preferedCountries" | "label" | "name" | "valueNames"
>;

export function FormPhone({
    defaultCode,
    helperText,
    label,
    name,
    preferedCountries,
    valueNames,
}: FormPhoneProps) {
    const names = { ...defaultValueNames, ...valueNames };

    const { field, fieldState } = useController({
        name,
        defaultValue: { ...{ [names.dialCode]: "+33" }, ...{ [names.phoneNumber]: "" } },
    });

    return (
        <Stack>
            <PhoneField
                defaultCode={defaultCode}
                helperText={helperText}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                name={name}
                onChange={field.onChange}
                preferedCountries={preferedCountries}
                value={field.value}
                valueNames={valueNames}
            />
            <FormError name={`${name}.${names.dialCode}`} />
            <FormError name={`${name}.${names.phoneNumber}`} />
        </Stack>
    );
}
