import { ArrowLeftIcon, ButtonLink, IconButton, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";

import styles from "./Overlay.module.scss";

type OverlayHeaderProps = {
    children: React.ReactNode;
    onBack?: () => void;
    onClose?: () => void;
    position?: "static" | "sticky";
};

export function OverlayHeader({
    children,
    onBack,
    onClose,
    position = "sticky",
}: OverlayHeaderProps) {
    const { t } = useTranslation();

    return (
        <div className={`${styles["overlay"]} ${styles["overlay-header"]}`} style={{ position }}>
            <Stack alignItems="center" direction="row" gap={2}>
                {onBack ? (
                    <IconButton aria-label="Retour à la page précédente" onClick={onBack}>
                        <ArrowLeftIcon size="small" />
                    </IconButton>
                ) : null}
                {typeof children === "string" ? (
                    <div className={styles["overlay-header__title"]}>
                        <Typography variant="title-xs">{children}</Typography>
                    </div>
                ) : (
                    children
                )}
            </Stack>
            {onClose ? <ButtonLink onClick={onClose}>{t("Fermer")}</ButtonLink> : null}
        </div>
    );
}
