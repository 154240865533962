import { isEmpty } from "@resamare/functions";
import { useScrollToRef } from "@resamare/react-hooks";
import { useTranslation } from "@resamare/translation";
import { Spinner, Stack, Typography } from "@resamare/ui";

import { useEffect } from "react";
import { PackagesList } from "../components/PackagesList";
import { useClientConfig } from "../../client-details";
import { PackageSearch, useSearchContext } from "../../search";
import { useGetPackages } from "../api/getPackages";
import { Section } from "../../../components";

export function PackagesPage() {
    const { t } = useTranslation();

    const { config } = useClientConfig();
    const { search } = useSearchContext<PackageSearch>();
    const packagesQuery = useGetPackages({ params: search });

    const scrollOffset = parseInt(config.offsetTop || "0", 10) + 32;
    const { ref, scrollToRef } = useScrollToRef({ offsetTop: -scrollOffset });

    useEffect(() => {
        if (!isEmpty(packagesQuery.data) && ref.current) {
            scrollToRef();
        }
    }, [ref, scrollToRef, packagesQuery.data]);

    if (!packagesQuery.data) {
        return null;
    }

    return (
        <Section>
            <Stack gap={3}>
                {packagesQuery.isFetching ? <Spinner /> : null}

                <div ref={ref}>
                    {isEmpty(packagesQuery.data) ? (
                        <Typography>{t("Aucun résultat")}</Typography>
                    ) : (
                        <PackagesList packages={packagesQuery.data} />
                    )}
                </div>
            </Stack>
        </Section>
    );
}
