import "@fontsource/inter/latin-400.css";
import "@fontsource/inter/latin-600.css";

import "@/styles/index.scss";

import { ClientConfig } from "@resamare/widgets-shared";
import { AppProvider } from "./providers/AppProvider";
import { AppRoutes } from "./routes/AppRoutes";

type AppProps = {
    config: Partial<ClientConfig>;
};

export function App({ config }: AppProps) {
    return (
        <AppProvider config={config}>
            <AppRoutes />
        </AppProvider>
    );
}
