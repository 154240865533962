import { useEffect, useRef } from "react";
import { isInViewport } from "@resamare/functions";
import { Button, Stack } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { LiaisonResumeItem } from "./LiaisonResumeItem";
import { SelectedLiaison } from "../types";
import { LiaisonSearch, TripType, useSearchContext } from "../../../search";
import { useGetLiaison } from "../../../liaisons";
import { useCreateCartLine } from "../../../cart";
import { Step } from "./Step/Step";
import { useClientConfig } from "../../../client-details";
import { Path } from "../../../../routes";
import { useNavigateWithPrevious } from "../../../../hooks";

type LiaisonResumeProps = {
    liaisons: SelectedLiaison[];
};

export function LiaisonResume({ liaisons }: LiaisonResumeProps) {
    const { t } = useTranslation();
    const { search } = useSearchContext<LiaisonSearch>() as { search: LiaisonSearch };
    const navigate = useNavigateWithPrevious();
    const { config } = useClientConfig();

    const isDisabled =
        search["trip-type"] === TripType.ROUNDTRIP ? liaisons.length < 2 : liaisons.length < 1;

    const outboundQuery = useGetLiaison(liaisons[0]?.session_id);
    const returnQuery = useGetLiaison(liaisons[1]?.session_id);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!ref.current || isDisabled) return;
        if (isInViewport(ref.current, 400)) return;
        ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }, [ref, isDisabled]);

    const createLineMutation = useCreateCartLine();
    const handleOnClick = async () => {
        const nextStep = config.activer_panier ? Path.CHECKOUT_SUMMARY : Path.CHECKOUT_FORM;
        createLineMutation.mutate(liaisons, {
            onSuccess: () => navigate({ pathname: nextStep }),
        });
    };

    return (
        <div ref={ref}>
            <Stack gap={3}>
                <Step
                    isCompleted={false}
                    isDisabled={isDisabled}
                    label={t("Récapitulatif de votre trajet")}
                    onClickEdit={() => null}
                    step={search["trip-type"] === TripType.ROUNDTRIP ? 3 : 2}
                />

                {!isDisabled ? (
                    <Stack gap={4}>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: 24 }}>
                            <LiaisonResumeItem
                                liaison={outboundQuery.data}
                                selectedDeparture={liaisons[0]?.lieu_id}
                                type="outbound"
                            />
                            <LiaisonResumeItem
                                liaison={returnQuery.data}
                                selectedDeparture={liaisons[1]?.lieu_id}
                                type="return"
                            />
                        </div>

                        <Stack alignItems="center" direction="row" justifyContent="flex-end">
                            <Button
                                isLoading={createLineMutation.isPending}
                                onClick={handleOnClick}
                            >
                                {t("Confirmer")}
                            </Button>
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
        </div>
    );
}
