import { useController } from "react-hook-form";
import { DateFieldRange, DateFieldRangeProps } from "./DateFieldRange";
import { FormError } from "../_partials/FormError/FormError";
import { Stack } from "../../Layout";

type FormDateRangeProps = Pick<
    DateFieldRangeProps,
    | "availableDates"
    | "blockedDates"
    | "helperText"
    | "inline"
    | "label"
    | "locale"
    | "maxDate"
    | "minDate"
    | "name"
    | "portalId"
>;

export function FormDateRange({
    availableDates,
    blockedDates,
    helperText,
    inline,
    label,
    locale,
    maxDate,
    minDate,
    name,
    portalId,
}: FormDateRangeProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue: minDate ? [minDate, minDate] : [new Date(), new Date()],
    });

    return (
        <Stack>
            <DateFieldRange
                availableDates={availableDates}
                blockedDates={blockedDates}
                helperText={helperText}
                inline={inline}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                locale={locale}
                maxDate={maxDate}
                minDate={minDate}
                name={name}
                onChange={field.onChange}
                portalId={portalId}
                value={field.value}
            />
            <FormError name={name} />
            <FormError name={`${name}[0]`} />
            <FormError name={`${name}[1]`} />
        </Stack>
    );
}
