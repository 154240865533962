import { formatCurrency } from "@resamare/functions";
import { DeleteIcon, IconButton, Stack, Typography } from "@resamare/ui";

import { useRemoveCard } from "../../../gift-card";
import { useRemoveCode } from "../../api/removeCode";
import { Cart } from "../../../../models";
import { CodeDetails } from "./CodeDetails";

type DiscountCodesProps = {
    cart: Cart;
};

export function DiscountCodes({ cart }: DiscountCodesProps) {
    const { panier_cle: cartKey, carte_cadeau: giftCards, code_promo: promoCode } = cart;

    const removeCardMutation = useRemoveCard();
    const handleDeleteCard = (cardId: string) => {
        removeCardMutation.mutate({ cartKey, cardId });
    };

    const removeCodeMutation = useRemoveCode();
    const handleDeleteCode = (codeId: string) => {
        removeCodeMutation.mutate({ cartKey, codeId });
    };

    return (
        <div>
            {giftCards.map((card) => (
                <Stack
                    key={card.id}
                    alignItems="center"
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                >
                    <Stack direction="row" gap={1}>
                        <Typography>{card.code}</Typography>
                        {card.description ? (
                            <CodeDetails
                                code={card.code}
                                description={card.description}
                                titre={card.titre}
                            />
                        ) : null}
                    </Stack>
                    <Stack alignItems="center" direction="row" gap={1}>
                        <Typography>{formatCurrency(-card.montant)}</Typography>
                        <IconButton color="error" onClick={() => handleDeleteCard(card.id)}>
                            <DeleteIcon size="small" />
                        </IconButton>
                    </Stack>
                </Stack>
            ))}

            {promoCode ? (
                <Stack
                    key={promoCode.id}
                    alignItems="center"
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                >
                    <Stack direction="row" gap={1}>
                        <Typography>{promoCode.code}</Typography>
                        {promoCode.description ? (
                            <CodeDetails
                                code={promoCode.code}
                                description={promoCode.description}
                                titre={promoCode.titre}
                            />
                        ) : null}
                    </Stack>
                    <Stack alignItems="center" direction="row" gap={1}>
                        <Typography>{formatCurrency(-promoCode.montant)}</Typography>
                        <IconButton color="error" onClick={() => handleDeleteCode(promoCode.id)}>
                            <DeleteIcon size="small" />
                        </IconButton>
                    </Stack>
                </Stack>
            ) : null}
        </div>
    );
}
