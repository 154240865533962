import { Field, FieldType } from "@resamare/ui/src/Forms/FormBuilder/types";
import { removeHtml } from "@resamare/functions";
import { DataField, DataFieldType } from "../types";

const createDynamicField = (
    data: DataField,
    options?: {
        prefix?: string;
    },
): Field => {
    const prefix = options?.prefix;

    let field: Field | undefined;

    if (data.type === DataFieldType.CHECKBOX) {
        field = {
            type: FieldType.CHECKBOX,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
        };
    }

    if (data.type === DataFieldType.DATE) {
        field = {
            type: FieldType.DATE,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
            description: data.description ? removeHtml(data.description) : null,
        };
    }

    if (data.type === DataFieldType.FILE) {
        field = {
            type: FieldType.FILE,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
            description: data.description ? removeHtml(data.description) : null,
            fileMimes: data.rules.mimes,
            fileMaxSize: data.rules.max,
            validation: [
                {
                    mimes: data.rules.mimes,
                    message: "Format de fichier incorrect",
                },
                {
                    max: data.rules.max,
                    message: "Fichier trop lourd",
                },
            ],
        };
    }

    if (data.type === DataFieldType.NUMBER) {
        field = {
            type: FieldType.NUMBER,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
            validation: [
                ...(data.max ? [{ max: data.max, message: `Maximum ${data.max}` }] : []),
                ...(data.min ? [{ min: data.min, message: `Minimum ${data.min}` }] : []),
            ],
        };
    }

    if (data.type === DataFieldType.SELECT) {
        field = {
            type: FieldType.SELECT,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
            options: data.options,
        };
    }

    if (data.type === DataFieldType.SELECT_MULTIPLE) {
        field = {
            type: FieldType.SELECT_MULTIPLE,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
            options: data.options,
        };
    }

    if (data.type === DataFieldType.TEXT) {
        field = {
            type: FieldType.TEXT,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
            description: data.description ? removeHtml(data.description) : null,
        };
    }

    if (data.type === DataFieldType.TEXTAREA) {
        field = {
            type: FieldType.TEXTAREA,
            name: prefix ? `${prefix}.${data.id}` : data.id,
            label: data.label,
        };
    }

    if (!field) {
        throw new Error(`Type d'input non pris en charge: ${data.type}`);
    }

    if (data.rules?.required === true) {
        field = {
            ...field,
            validation: [
                {
                    required: true,
                    message: "Le champ est requis",
                },
                ...(field.validation || []),
            ],
        };
    }

    return field;
};

export function fromDataToDynamicFields(
    data: DataField[],
    options?: {
        prefix?: string;
    },
): Field[] {
    return data.map((field) => createDynamicField(field, options));
}
