import { useTranslation } from "@resamare/translation";
import { ButtonLink, Stack } from "@resamare/ui";
import { useNavigate } from "react-router-dom";
import { OrderConfirmationMessage } from "../components";
import { Section } from "../../../components";

export function CheckoutConfirmationPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Section>
            <Stack alignItems="center" gap={3}>
                <OrderConfirmationMessage />
                <ButtonLink onClick={() => navigate("/")}>{t("Fermer")}</ButtonLink>
            </Stack>
        </Section>
    );
}
