import { useController } from "react-hook-form";

import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import NumberField from "./NumberField";
import { FormTextProps } from "../FormText/FormText";

export interface FormNumberProps extends Omit<FormTextProps, "type"> {
    hideControls?: boolean;
}

export function FormNumber({
    defaultValue = "",
    endElement,
    helperText,
    hideControls = false,
    label,
    name,
    placeholder,
}: FormNumberProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue,
    });

    return (
        <FieldWrapper name={field.name}>
            <NumberField
                endElement={endElement}
                helperText={helperText}
                hideControls={hideControls}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                name={field.name}
                onChange={field.onChange}
                placeholder={placeholder}
                value={field.value}
            />
        </FieldWrapper>
    );
}
