import { AxiosError } from "axios";
import { getCart } from "./getCart";

export async function checkCartExists(cartKey: string): Promise<boolean> {
    try {
        await getCart(cartKey);
        return true;
    } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
            return false;
        }

        throw error;
    }
}
