import { ButtonLink, Dialog, Stack, Typography } from "@resamare/ui";
import { useState } from "react";
import { useTranslation } from "@resamare/translation";
import { OverlayHeader } from "../../../../components";
import { ModalLayout } from "../../../../layouts";

type PromotionDetailsProps = {
    nom: string;
    description?: string;
};

export function PromotionDetails({ nom, description }: PromotionDetailsProps) {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleOnClose = () => setIsOpen(false);
    const handleOnOpen = () => setIsOpen(true);

    return (
        <>
            <ButtonLink onClick={handleOnOpen} variant="text-sm">
                {t("Détails de la promotion")}
            </ButtonLink>
            <Dialog isOpen={isOpen} onClose={handleOnClose}>
                <OverlayHeader onClose={handleOnClose}>{nom}</OverlayHeader>
                <ModalLayout>
                    <Stack gap={3}>
                        {description ? (
                            <Typography component="div">
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: description }}
                                />
                            </Typography>
                        ) : null}
                    </Stack>
                </ModalLayout>
            </Dialog>
        </>
    );
}
