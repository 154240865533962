import { dt } from "@resamare/functions";
import { ObjectSchema, object, date, number, string, array } from "yup";
import { getDepartureSelectOptions, getDestinationSelectOptions } from "../utils";
import { DepartureLocation } from "../../../../models";
import { ParticipantType } from "../../../participant-types";
import { LiaisonSearch, TripType } from "../../../search";

export function getLiaisonSchema(
    departureLocations: DepartureLocation[],
    participantTypes: ParticipantType[],
) {
    const participantsShape = participantTypes.reduce(
        (acc, type) => ({
            ...acc,
            [type.id]: number()
                .min(
                    type.quantite_min,
                    `${type.label} doit être supérieur ou égal à ${type.quantite_min}`,
                )
                .max(
                    type.quantite_max,
                    `${type.label} doit être inférieur ou égal à ${type.quantite_max}`,
                ),
        }),
        {},
    );

    const departures = getDepartureSelectOptions(departureLocations).map((d) => d.value);

    const schema: ObjectSchema<LiaisonSearch> = object<LiaisonSearch>().shape({
        "trip-type": string()
            .oneOf(Object.values(TripType), "Type de trajet invalide")
            .required("Veuillez choisir un type de trajet"),

        from: string()
            .oneOf(departures, "Point de départ invalide")
            .required("Veuillez choisir un point de départ"),

        to: string()
            .required("Veuillez choisir un point d'arrivée")
            .test({
                message: "Point d'arrivée invalide",
                test: (value, { parent }) => {
                    if (!value) return false;
                    const selectedDeparture = parent["from"] as LiaisonSearch["from"];
                    const destinations = getDestinationSelectOptions(
                        selectedDeparture,
                        departureLocations,
                    )?.map((d) => d.value);

                    return destinations?.includes(value);
                },
            }),

        dates: array()
            .of(
                date()
                    .min(dt.sub(dt.now(), { days: 1 }), "La date est passée")
                    .required("Veuillez choisir une date"),
            )
            .test({
                message: "Dates invalides",
                test: (value) => value?.length === 1 || value?.length === 2,
            })
            .test({
                message: "Veuillez choisir une date de retour",
                test: (value, { parent }) => {
                    if (parent["trip-type"] === TripType.ONEWAY) return true;
                    return value?.[1] !== undefined;
                },
            })
            .test({
                message: "La date de retour doit être ultérieure à la date de départ",
                test: (value, { parent }) => {
                    if (parent["trip-type"] === TripType.ONEWAY) return true;
                    if (!value?.[1] || !value[0]) return false;
                    if (dt.isSameOrAfter(value[1], value[0])) return true;
                    return false;
                },
            })
            .required("Veuillez choisir vos dates"),

        participants: object()
            .transform((value) => ({ ...value }))
            .shape(participantsShape)
            .test({
                test: (value: LiaisonSearch["participants"]) =>
                    Object.values(value).some((v) => v > 0),
                message: "Veuillez sélectionner des participants",
            }),

        type_produit: number().positive().integer().required(),
    });

    return schema;
}
