import { CheckIcon, Stack } from "@resamare/ui";

import styles from "./Step.module.scss";

type StepIndicatorProps = {
    isCompleted: boolean;
    step: number;
};

export function StepIndicator({ isCompleted, step }: StepIndicatorProps) {
    return (
        <Stack
            className={`${styles["step-indicator"]} ${
                isCompleted ? styles["step-indicator--is-completed"] : ""
            }`}
        >
            {isCompleted ? <CheckIcon /> : step}
        </Stack>
    );
}
