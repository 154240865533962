import { CSSProperties } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Typography } from "../../General";

import styles from "./PhoneField.module.scss";

export function isoToEmoji(code: string) {
    return code
        .split("")
        .map((letter) => (letter.charCodeAt(0) % 32) + 0x1f1e5)
        .map((emojiCode) => String.fromCodePoint(emojiCode))
        .join("");
}

export type Country = {
    name: string;
    dial_code: string;
    code: string;
};

type CountryItemProps = {
    country: Country;
    onClick: (country: Country) => void;
    style?: CSSProperties;
};

export function CountryItem({ country, onClick, style }: CountryItemProps) {
    return (
        <ListItemButton onClick={() => onClick(country)} style={style}>
            <ListItemIcon>
                <span className={styles["input-phone__flag"]}>{isoToEmoji(country.code)}</span>
            </ListItemIcon>
            <ListItemText>
                <Typography variant="text-sm">{country.name}</Typography>
                <Typography variant="text-xs">{country.dial_code}</Typography>
            </ListItemText>
        </ListItemButton>
    );
}
