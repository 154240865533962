import { boolean } from "yup";
import { FieldValidationRule } from "../../types";

export function buildCheckboxSchema(validation: FieldValidationRule[]) {
    let schema = boolean();

    validation.forEach((rule) => {
        if (rule.required) {
            schema = schema.required(rule.message);
        }
    });

    return schema;
}
