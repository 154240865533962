import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config/api-client";
import { DataField } from "../types";

export enum AdditionalInfoType {
    PARTICIPANT = "participant",
    RESERVATION = "reservation",
}

async function getAdditionalInfosParticipant(cartKey: string): Promise<DataField[]> {
    const response = await apiClient.get("infosSuppParticipant", {
        params: { cle_panier: cartKey },
    });
    return response.data;
}

async function getAdditionalInfosReservation(cartKey: string): Promise<DataField[]> {
    const response = await apiClient.get("infosSuppResa", {
        params: { cle_panier: cartKey },
    });

    return response.data;
}

export const useGetAdditionalInfos = (cartKey: string, type: AdditionalInfoType) => {
    const query = useQuery({
        queryKey: ["additional-infos", type, cartKey],
        queryFn: () => {
            if (type === AdditionalInfoType.PARTICIPANT) {
                return getAdditionalInfosParticipant(cartKey);
            }

            if (type === AdditionalInfoType.RESERVATION) {
                return getAdditionalInfosReservation(cartKey);
            }

            throw new Error("Unsupported additional information type");
        },
    });
    return query;
};
