import { ButtonBaseProps, ButtonBase as MuiButtonBase } from "@mui/material";

import Styles from "./ButtonCustom.module.scss";

export interface ButtonCustomProps
    extends Pick<ButtonBaseProps, "className" | "disabled" | "onClick"> {
    children: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function ButtonCustom({ children, className, disabled, onClick }: ButtonCustomProps) {
    return (
        <MuiButtonBase
            className={`${Styles.ButtonCustom} ${className || ""}`}
            component="button"
            disabled={disabled}
            focusRipple
            onClick={onClick}
            role="button"
        >
            {children}
        </MuiButtonBase>
    );
}
