import { FormSelectSwap } from "@resamare/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "@resamare/translation";
import { DepartureLocation } from "../../../../../models";
import { getDepartureSelectOptions, getDestinationSelectOptions } from "../../utils";

type LiaisonFormTravelProps = {
    departureLocations: DepartureLocation[];
};

export function LiaisonFormTravel({ departureLocations }: LiaisonFormTravelProps) {
    const { t } = useTranslation();

    const { getValues, setValue } = useFormContext();

    const departures = getDepartureSelectOptions(departureLocations);

    const selectedDeparture = useWatch({ name: "from", defaultValue: departures[0].value });
    const destinations = getDestinationSelectOptions(selectedDeparture, departureLocations);

    useEffect(() => {
        const currentDestination = getValues("to");
        const destinationIsPresent = destinations?.some(
            (destination) => destination.value === currentDestination,
        );
        if (destinationIsPresent) return;
        setValue("to", "");
    }, [selectedDeparture, getValues, destinations, setValue]);

    return (
        <FormSelectSwap
            first={{
                defaultValue: departures[0].value,
                label: t("Départ"),
                name: "from",
                options: departures,
            }}
            label={t("Trajet")}
            second={{
                label: t("Arrivée"),
                name: "to",
                isDisabled: destinations === null,
                options: destinations || [{ value: "", label: "" }],
            }}
        />
    );
}
