import { captureException, withScope } from "@sentry/react";
import {
    DefaultOptions,
    Mutation,
    MutationCache,
    Query,
    QueryCache,
    QueryClient,
    QueryKey,
} from "@tanstack/react-query";
import { isAxiosError } from "axios";

export const defaultQueryOptions: DefaultOptions = {
    queries: {
        throwOnError: true,
        staleTime: 5 * 60 * 1000,
    },
};

export const shouldCaptureError = (
    err: unknown,
    context:
        | Mutation<unknown, unknown, unknown, unknown>
        | Query<unknown, unknown, unknown, QueryKey>,
): boolean => {
    if (!isAxiosError(err) || !err.response) return true;

    const { status } = err.response;
    const skipLogHttpErrors = context?.meta?.skipLogHttpErrors;

    if (Array.isArray(skipLogHttpErrors) && skipLogHttpErrors.includes(status)) {
        return false;
    }

    return true;
};

const queryCache = new QueryCache({
    onError: (err, query) => {
        if (!shouldCaptureError(err, query)) return;

        withScope((scope) => {
            scope.setContext("query", { queryHash: query.queryHash });
            scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")]);
            captureException(err);
        });
    },
});

const mutationCache = new MutationCache({
    onError: (err, _variables, _context, mutation) => {
        if (!shouldCaptureError(err, mutation)) return;

        withScope((scope) => {
            scope.setContext("mutation", {
                mutationId: mutation.mutationId,
                variables: mutation.state.variables,
            });
            if (mutation.options.mutationKey) {
                scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
            }
            captureException(err);
        });
    },
});

export const queryClient = new QueryClient({
    defaultOptions: defaultQueryOptions,
    queryCache,
    mutationCache,
});
