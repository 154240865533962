import { FormType } from "@resamare/widgets-shared";

export enum Path {
    EXCURSIONS = `/${FormType.EXCURSION}`,
    LIAISONS = `/${FormType.LIAISON}`,
    PACKAGES = `/${FormType.PACKAGE}`,
    NAVIBUS = `/NAVIBUS`,

    OPTIONS = "/options",
    ADDITIONNAL_OPTIONS = "/additionnal-options",

    CHECKOUT_SUMMARY = "/checkout-summary",
    CHECKOUT_FORM = "/checkout-form",
    CHECKOUT_CONFIRMATION = "/checkout-confirmation",
}
