import { Spinner } from "@resamare/ui";
import {
    LiaisonSearch,
    getOutboundParams,
    useGetDepartureLocations,
    useGetLiaisons,
    useGetParticipantTypes,
    useGetProductType,
    useSearchContext,
    LiaisonForm as LiaisonFormUI,
    useDefaultConfigSearch,
} from "@resamare/widgets-shared";
import { useParams } from "react-router-dom";
import { isEqual } from "@resamare/functions";

export function LiaisonForm() {
    const { productTypeId } = useParams() as { productTypeId: string };
    const { search, setSearch } = useSearchContext<LiaisonSearch>();
    const outboundQuery = useGetLiaisons({ params: getOutboundParams(search) });
    const participantTypesQuery = useGetParticipantTypes({ productTypeId });
    const locationsQuery = useGetDepartureLocations({ productTypeId });
    const productTypesQuery = useGetProductType(productTypeId);

    const defaultSearch = useDefaultConfigSearch();

    if (
        participantTypesQuery.isPending ||
        locationsQuery.isPending ||
        productTypesQuery.isPending
    ) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data || !locationsQuery.data || !productTypesQuery.data) {
        return null;
    }

    const onSubmit = (data: LiaisonSearch) => {
        if (isEqual(data, search)) outboundQuery.refetch();
        setSearch(data);
    };

    return (
        <LiaisonFormUI
            key={productTypeId}
            defaultValues={{ ...defaultSearch, type_produit: parseInt(productTypeId, 10) }}
            departureLocations={locationsQuery.data}
            isLoading={outboundQuery.isFetching}
            onSubmit={onSubmit}
            participantTypes={participantTypesQuery.data}
            roundTripOnly={productTypesQuery.data.ar_seulement}
        />
    );
}
