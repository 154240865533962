import { useEffect } from "react";

export const useInjectStyleSheet = (href: string) => {
    const isDevMode = import.meta.env.MODE === "development";
    const isTestMode = import.meta.env.MODE === "test";

    useEffect(() => {
        if (isDevMode || isTestMode) return undefined;

        const isStyleSheetPresent = Array.from(
            document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]'),
        ).some((link) => link.href.includes(href));

        if (isStyleSheetPresent) return undefined;

        const link = document.createElement("link");
        link.href = href;
        link.rel = "stylesheet";

        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [href]);
};
