import { useController } from "react-hook-form";

import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { NumberStepperField, NumberStepperFieldProps } from "./NumberStepperField";

interface FormNumberStepperProps
    extends Pick<NumberStepperFieldProps, "helperText" | "label" | "max" | "step" | "name"> {
    defaultValue?: number;
    editable?: boolean;
    min?: number;
}

export function FormNumberStepper({
    defaultValue = 0,
    editable,
    helperText,
    label,
    max,
    min = 0,
    step,
    name,
}: FormNumberStepperProps) {
    const { field } = useController({
        name,
        defaultValue,
    });

    return (
        <FieldWrapper name={field.name}>
            <NumberStepperField
                editable={editable}
                helperText={helperText}
                inputRef={field.ref}
                label={label}
                max={max}
                min={min}
                name={field.name}
                onChange={field.onChange}
                step={step}
                value={field.value}
            />
        </FieldWrapper>
    );
}
