import { ClockIcon, InfoIcon, ReadMore, Stack, Tooltip, Typography } from "@resamare/ui";
import { flat, min, removeHtml, unique } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Product } from "../../../../../models";

import styles from "./ExcursionCard.module.scss";

type ExcursionCardContentProps = {
    excursion: Product;
    openDetails: () => void;
};

export function ExcursionCardContent({ excursion, openDetails }: ExcursionCardContentProps) {
    const { t } = useTranslation();
    const departureLocations = flat(excursion.sessions.map((session) => session.lieux_depart));
    const departureNames = unique(departureLocations.map((departure) => departure.label));

    const departureTimes = unique(excursion.sessions.map((session) => session.heure_depart.label));

    const sessionsWithStock = excursion.sessions.filter((s) => s.stock);
    const leastPeopleSession = min(sessionsWithStock, (session) => session.stock as number);

    return (
        <Stack className={styles["excursion-card__content-inner"]} gap={2}>
            <div>
                <Typography variant="title-xs">{excursion.nom}</Typography>
                {excursion.duree ? (
                    <Stack alignItems="center" direction="row" gap={1}>
                        <ClockIcon size="small" />
                        <Typography>
                            {t("Durée")} : {excursion.duree}
                        </Typography>
                    </Stack>
                ) : null}
                {leastPeopleSession ? (
                    <Typography color="error" variant="text-sm">
                        {t("Plus que x places disponibles", { count: leastPeopleSession.stock })}
                    </Typography>
                ) : null}
            </div>
            <Stack className={styles["excursion-card__departure"]} gap={2}>
                <div>
                    <Typography fontWeight={600}>
                        {t("Lieu de départ", { count: departureNames.length })}
                    </Typography>
                    <Typography>{departureNames.join(", ")}</Typography>
                </div>
                <div>
                    <Stack direction="row" gap={1}>
                        <Typography fontWeight={600}>
                            {t("Heure de départ", { count: departureTimes.length })}
                        </Typography>
                        {departureNames.length ? (
                            <Tooltip
                                placement="top"
                                title={t(
                                    "En fonction du lieu de départ, l'heure de départ peut varier.",
                                )}
                            >
                                <InfoIcon size="small" />
                            </Tooltip>
                        ) : null}
                    </Stack>
                    <Typography>{departureTimes.join(", ")}</Typography>
                </div>
            </Stack>
            {excursion.description_courte || excursion.description ? (
                <div className={styles["excursion-card__description"]}>
                    <Typography fontWeight={600}>{t("Programme")}</Typography>
                    <ReadMore
                        buttonTexts={{ readMore: `... ${t("Lire la suite")}` }}
                        nbOfLines={2}
                        onClick={openDetails}
                    >
                        <Typography>
                            {removeHtml(`${excursion.description_courte} ${excursion.description}`)}
                        </Typography>
                    </ReadMore>
                </div>
            ) : null}
        </Stack>
    );
}
