import { createContext, useContext, ReactNode, useState, useMemo, useEffect } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next, { TFunction } from "i18next";
import { dt } from "@resamare/functions";
import { common, en, fr } from "./locales";
import { formatAgeRange } from "./formatters";

export type SupportedLanguages = "fr" | "en";

interface TranslationProviderProps {
    children: ReactNode;
    defaultLanguage?: SupportedLanguages;
}

interface TranslationContextType {
    changeLanguage: (lng: SupportedLanguages) => void;
    currentLanguage: SupportedLanguages;
    t: TFunction;
}

const TranslationContext = createContext<TranslationContextType | null>(null);

export function TranslationProvider({
    children,
    defaultLanguage = "fr",
}: TranslationProviderProps) {
    const [currentLanguage, setCurrentLanguage] = useState<SupportedLanguages>(defaultLanguage);

    const i18nInstance = useMemo(() => {
        const instance = i18next.createInstance();
        instance
            .use(initReactI18next)
            .init({
                lng: defaultLanguage,
                fallbackLng: "fr",
                interpolation: {
                    escapeValue: false,
                },
                resources: {
                    en: { translation: { ...common, ...en } },
                    fr: { translation: { ...common, ...fr } },
                },
            })
            .then(() => {
                instance.services.formatter?.add("ageRange", (value) =>
                    formatAgeRange({ value, instance }),
                );
            });

        return instance;
    }, [defaultLanguage, formatAgeRange]);

    useEffect(() => {
        dt.setLocale(defaultLanguage);
    }, [defaultLanguage]);

    const changeLanguage = (lng: SupportedLanguages) => {
        i18nInstance.changeLanguage(lng);
        dt.setLocale(lng);
        setCurrentLanguage(lng);
    };

    const contextValue = useMemo(
        () => ({
            changeLanguage,
            currentLanguage,
            t: i18nInstance.t,
        }),

        [currentLanguage],
    );

    return (
        <TranslationContext.Provider value={contextValue}>
            <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
        </TranslationContext.Provider>
    );
}

export const useTranslation = (): TranslationContextType => {
    const context = useContext(TranslationContext);
    if (!context) {
        throw new Error("useTranslationContext must be used within a TranslationProvider");
    }
    return context;
};
