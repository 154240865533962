import React, { PropsWithChildren } from "react";
import { MemoryRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ErrorBoundary, FullPageSpinner, ToastRenderer } from "@resamare/ui";
import {
    ClientConfig,
    ClientConfigProvider,
    ThemeProvider,
    initApiClient,
    logError,
    queryClient,
} from "@resamare/widgets-shared";
import { TranslationProvider } from "@resamare/translation";
import { useInjectStyleSheet } from "@resamare/react-hooks";

import { WIDGET_ASSETS_URL } from "@/config/constants";

type AppProviderProps = PropsWithChildren<{
    config: Partial<ClientConfig>;
}>;

export function AppProvider({ children, config }: AppProviderProps) {
    useInjectStyleSheet(`${WIDGET_ASSETS_URL}/recherche/index.css`);

    initApiClient(config);

    return (
        <React.Suspense fallback={<FullPageSpinner />}>
            <ThemeProvider config={config}>
                <ErrorBoundary onError={logError}>
                    <TranslationProvider defaultLanguage={config.langue}>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools />
                            <ClientConfigProvider defaultConfig={config}>
                                <Router>{children}</Router>
                            </ClientConfigProvider>
                        </QueryClientProvider>
                    </TranslationProvider>
                </ErrorBoundary>
                <ToastRenderer />
            </ThemeProvider>
        </React.Suspense>
    );
}
