import { Stack, Typography } from "@resamare/ui";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@resamare/translation";
import { ConfirmationIllustration } from "../../../../assets";

import styles from "./OrderConfirmationMessage.module.scss";

export function OrderConfirmationMessage() {
    const { t } = useTranslation();

    const { state } = useLocation();

    return (
        <Stack alignItems="center" gap={5}>
            <div className={styles["illustration"]}>
                <ConfirmationIllustration />
            </div>
            <Stack alignItems="center" gap={2}>
                <Stack alignItems="center" gap={2}>
                    <Typography align="center" variant="title">
                        {t(`Réservation confirmée`)}
                    </Typography>

                    <Stack alignItems="center" gap={0.5}>
                        {state?.message ? <Typography>{state.message}</Typography> : null}

                        <Typography align="center">
                            {t(
                                "Nous vous avons également envoyé un email de confirmation, merci d'en prendre connaissance.",
                            )}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
