import { Button, FormText } from "@resamare/ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "@resamare/translation";
import { AxiosError } from "axios";
import { useAddCode } from "../../api/addCode";

import styles from "./DiscountField.module.scss";

type DiscountFieldProps = {
    cartKey: string;
};

export function DiscountField({ cartKey }: DiscountFieldProps) {
    const { t } = useTranslation();
    const { getValues, trigger, setError } = useFormContext();

    const getErrorMessage = (error: AxiosError<{ message: string } | null>) => {
        if (error.response?.data?.message) {
            return error.response.data.message;
        }

        const errorCode = error.response?.status;

        switch (errorCode) {
            case 404:
                return t("Ce code n'existe pas.");
            case 409:
                return t("Ce code est déjà appliqué.");
            default:
                return t("Une erreur s'est produite");
        }
    };

    const addCodeMutation = useAddCode();
    const handleAddCode = async () => {
        const isCodeValid = await trigger(["code"], { shouldFocus: true });
        if (!isCodeValid) return;

        const code = getValues("code");

        if (code === "") {
            setError(
                "code",
                { message: t("Veuillez ajouter un code promo ou une carte cadeau") },
                { shouldFocus: true },
            );
            return;
        }

        addCodeMutation.mutate(
            { cartKey, code },
            { onError: (error) => setError("code", { message: getErrorMessage(error) }) },
        );
    };

    return (
        <FormText
            endElement={
                <Button
                    className={styles["discount-button"]}
                    isLoading={addCodeMutation.isPending}
                    onClick={handleAddCode}
                    type="button"
                >
                    {t("Ajouter")}
                </Button>
            }
            label={t("Votre code")}
            name="code"
        />
    );
}
