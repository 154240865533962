import { isEmpty } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Button, Divider, FormBuilder, FormHidden, Spinner, Stack, useForm } from "@resamare/ui";

import { useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { ObjectSchema } from "yup";
import { Session } from "../../../../models";
import {
    DepartureLocationOption,
    DepartureTimeOption,
    ExcursionOptionsValues,
    getExcursionOptionsSchema,
} from "../../../activity-types/excursion";
import { ExcursionSearch, useSearchContext } from "../../../search";
import { useGetSessionOptionsForm } from "../../../sessions";

type PackageChoiceOptionsProps = {
    defaultSession: Session;
    defaultValues: {
        hour: string;
        lieu_id: string;
        participants: Record<string, number>;
        session_id: string;
    };
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    sessions: Session[];
};

export function PackageChoiceOptions({
    defaultSession,
    defaultValues,
    onSubmit,
    sessions,
}: PackageChoiceOptionsProps) {
    const { t } = useTranslation();

    const [schema, setSchema] = useState(getExcursionOptionsSchema());

    const form = useForm({ defaultValues, options: { shouldUnregister: true }, schema });
    const selectedHour = form.watch("hour", defaultSession.heure_depart.value);
    const selectedSession = sessions.find(
        ({ heure_depart: { value } }) => value === selectedHour,
    ) as Session;

    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };
    const optionsQuery = useGetSessionOptionsForm(selectedSession.id, {
        participants: search.participants,
    });

    return (
        <div>
            {optionsQuery.isPending ? <Spinner /> : null}
            {optionsQuery.data ? (
                <FormProvider {...form}>
                    <Stack gap={4}>
                        <FormBuilder
                            data={optionsQuery.data}
                            onMount={(optionsSchema) =>
                                setSchema(
                                    schema.concat(
                                        optionsSchema,
                                    ) as ObjectSchema<ExcursionOptionsValues>,
                                )
                            }
                            onSubmit={onSubmit}
                        >
                            <Stack gap={2}>
                                <DepartureTimeOption sessions={sessions} />
                                <DepartureLocationOption session={selectedSession} />
                                <FormHidden name="session_id" />
                                <FormHidden name="participants" />
                            </Stack>

                            {!isEmpty(optionsQuery.data.sections) ? (
                                <Divider>{t("Personnalisez votre réservation")}</Divider>
                            ) : null}
                        </FormBuilder>

                        <Button onClick={form.handleSubmit(onSubmit)} size="small">
                            Valider
                        </Button>
                    </Stack>
                </FormProvider>
            ) : null}
        </div>
    );
}
