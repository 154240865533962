import {
    ColorSystemOptions,
    CssVarsThemeOptions,
    SupportedColorScheme,
    ZIndex,
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { BreakpointsOptions, ShapeOptions, SpacingOptions } from "@mui/system";

import { BREAKPOINTS, COLOR, CSS_VARIABLE_PREFIX, FONT_FAMILY, SHAPE } from "./theme.constants";

const breakpoints: BreakpointsOptions = {
    values: {
        xs: BREAKPOINTS.XS,
        sm: BREAKPOINTS.SM,
        md: BREAKPOINTS.MD,
        lg: BREAKPOINTS.LG,
        xl: BREAKPOINTS.XL,
    },
};

const colorSchemes: Partial<Record<SupportedColorScheme, ColorSystemOptions>> = {
    light: {
        palette: {
            primary: {
                main: COLOR.PRIMARY,
            },
            text: {
                secondary: COLOR.TEXT,
                disabled: COLOR.GREY_500,
            },
            action: {
                disabled: COLOR.GREY_500,
            },
            divider: COLOR.GREY_500,
        },
    },
};

const shape: ShapeOptions = {
    borderRadius: SHAPE.BORDER_RADIUS,
};

const spacing: SpacingOptions = (factor: number) => `${0.5 * factor}em`;

const typography: TypographyOptions = {
    fontFamily: FONT_FAMILY.MAIN,
};

const zIndex: Partial<ZIndex> = {
    drawer: 999999,
    modal: 999999,
};

export const defaultCustomTheme: CssVarsThemeOptions = {
    breakpoints,
    colorSchemes,
    cssVarPrefix: CSS_VARIABLE_PREFIX,
    shape,
    typography,
    spacing,
    zIndex,
};
