import React from "react";
import ReactDOM from "react-dom/client";
import { ClientConfig, initSentry } from "@resamare/widgets-shared";
import { App } from "./App";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
initSentry({ dsn: SENTRY_DSN, widgetName: "recherche" });

const widgets = document.querySelectorAll<HTMLDivElement>(".resamare-widget-recherche");

widgets.forEach((widget) => {
    const { dataset } = widget;

    if (!dataset.api) {
        throw new Error("Missing 'data-api' attribute");
    }

    ReactDOM.createRoot(widget).render(
        <React.StrictMode>
            <App config={dataset as Partial<ClientConfig>} />
        </React.StrictMode>,
    );
});
