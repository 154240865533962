import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useClientConfig } from "../../client-details";

import { getCartKey } from "./getCartKey";
import { apiClient } from "../../../config/api-client";
import { ClientConfig } from "../../../models";

type CreateCartLineOptions = {
    isCartActivated: ClientConfig["activer_panier"];
    partenaireId: ClientConfig["partenaireId"];
    tokenReservation: ClientConfig["tokenReservation"];
    request: CreateCartLineDTO | CreateCartLineDTO[];
};

export type CreateCartLineDTO = {
    lieu_id: string;
    lieu_destination_id?: string;
    options?: Record<string, number>;
    participants: Record<string, number>;
    session_id: string;
};

async function createCartLine({
    isCartActivated,
    partenaireId,
    tokenReservation,
    request,
}: CreateCartLineOptions): Promise<number | AxiosResponse[]> {
    const cartKey = await getCartKey({ isCartActivated, tokenReservation, partenaireId });

    if (!Array.isArray(request)) {
        const { data } = await apiClient.post(`panier/${cartKey}/lignePanier`, request);
        return data;
    }

    const promises = request.map((line) => apiClient.post(`panier/${cartKey}/lignePanier`, line));

    return Promise.all(promises);
}

export function useCreateCartLineMutation() {
    const { config } = useClientConfig();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (data: CreateCartLineDTO | CreateCartLineDTO[]) =>
            createCartLine({
                isCartActivated: config.activer_panier,
                tokenReservation: config.tokenReservation,
                partenaireId: config.partenaireId,
                request: data,
            }),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
