import { ProductSection } from "@resamare/models";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";

async function getProductSections(productId?: number): Promise<ProductSection[]> {
    if (typeof productId === "undefined") {
        return Promise.reject(new Error("Invalid product id"));
    }

    const response = await apiClient.get(`/produits/${productId}/troncons`);
    return response.data;
}

export function useGetProductSections(productId?: number) {
    const query = useQuery({
        queryKey: ["products", productId, "sections"],
        queryFn: () => getProductSections(productId),
        enabled: !!productId,
    });

    return query;
}
