import { Button, Card, Divider, Image, Stack } from "@resamare/ui";
import { useState } from "react";
import { useTranslation } from "@resamare/translation";
import { ExcursionCardDetails } from "./ExcursionCardDetails";
import { ExcursionCardContent } from "./ExcursionCardContent";
import { ExcursionCardFooter } from "./ExcursionCardFooter";
import { Product } from "../../../../../models";

import styles from "./ExcursionCard.module.scss";

type ExcursionCardProps = {
    excursion: Product;
    isLoading: boolean;
    onClick: () => void;
};

export function ExcursionCard({ excursion, isLoading, onClick }: ExcursionCardProps) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const openDetails = () => setIsOpen(true);
    const closeDetails = () => setIsOpen(false);

    return (
        <Card className={styles["excursion-card"]}>
            <div className={styles["excursion-card__image"]}>
                <Image src={excursion.images[0]} />

                <div className={styles["excursion-card__button"]}>
                    <Button onClick={openDetails} size="small" variant="outlined">
                        {t("Plus d'infos")}
                    </Button>
                </div>
            </div>
            <Stack className={styles["excursion-card__content"]} gap={2}>
                <ExcursionCardContent excursion={excursion} openDetails={openDetails} />
                <Divider />
                <ExcursionCardFooter
                    excursion={excursion}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </Stack>

            <ExcursionCardDetails excursion={excursion} isOpen={isOpen} onClose={closeDetails} />
        </Card>
    );
}
