import { Spinner } from "@resamare/ui";
import { Navigate } from "react-router-dom";
import { isEmpty, storage } from "@resamare/functions";
import { FieldValues } from "react-hook-form";
import { useSearchParamsAdditionnalOptions } from "../hooks";
import { useGetSessionOptionsForm } from "../../sessions";
import { useAddOptions } from "../api";
import { ModalLayout } from "../../../layouts";
import { Path } from "../../../routes";
import { AdditionnalOptionsContainer } from "../containers";
import { useNavigateWithPrevious, usePreviousLocation } from "../../../hooks";
import { useClientConfig } from "../../client-details";

export function AdditionnalOptionsPage() {
    const { config } = useClientConfig();
    const navigate = useNavigateWithPrevious();
    const previousLocation = usePreviousLocation();

    const { lineId, participants, sessionId } = useSearchParamsAdditionnalOptions();
    const optionsFormQuery = useGetSessionOptionsForm(sessionId, { lineId, participants });
    const addOptions = useAddOptions();

    if (optionsFormQuery.isPending) {
        return (
            <ModalLayout>
                <Spinner />
            </ModalLayout>
        );
    }

    const nextStep = config.activer_panier ? Path.CHECKOUT_SUMMARY : Path.CHECKOUT_FORM;

    if (optionsFormQuery.isError) {
        return <Navigate state={{ previousLocation }} to={nextStep} />;
    }

    if (!optionsFormQuery.data) return null;

    if (isEmpty(optionsFormQuery.data.sections)) {
        return <Navigate state={{ previousLocation }} to={nextStep} />;
    }

    const handleOnSubmit = (data: FieldValues) => {
        const cartKey = storage.getWithExpiry<string>("cart");
        if (!cartKey) throw new Error("Cart not found");
        addOptions.mutate(
            { cartKey, lineId, options: data },
            { onSuccess: () => navigate(nextStep) },
        );
    };

    return (
        <AdditionnalOptionsContainer
            formData={optionsFormQuery.data}
            isLoading={addOptions.isPending}
            onSubmit={handleOnSubmit}
        />
    );
}
