import { FormHidden, FormRadio, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { Session } from "../../../../../models";

export function DepartureLocationOption({ session }: { session: Session }) {
    const { t } = useTranslation();

    const departureLocations = session.lieux_depart;
    const departureOptions = departureLocations.map((location) => {
        const delai = parseInt(location.delai, 10) > 0 ? `(+${location.delai}min)` : "";
        return { label: `${location.label} ${delai}`, value: location.value.toString() };
    });

    return departureLocations.length > 1 ? (
        <FormRadio label={t("Lieu de départ")} name="lieu_id" options={departureOptions} />
    ) : (
        <div>
            <Typography variant="label">{t("Lieu de départ")}</Typography>
            <Typography>{departureLocations[0].label}</Typography>
            <FormHidden name="lieu_id" />
        </div>
    );
}
