import { useController } from "react-hook-form";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import CheckboxGroup, { CheckboxOption } from "./CheckboxGroup";

type FormCheckboxProps = {
    alignTop?: boolean;
    attributes?: CheckboxOption["attributes"];
    defaultValue?: boolean;
    helperText?: string;
    label: string | React.ReactNode;
    name: string;
};

export function FormCheckbox({
    alignTop,
    attributes,
    defaultValue = false,
    helperText,
    label,
    name,
}: FormCheckboxProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue,
    });
    return (
        <FieldWrapper name={field.name}>
            <CheckboxGroup
                alignTop={alignTop}
                helperText={helperText}
                inputRef={field.ref}
                isError={!!fieldState.error}
                onChange={(data: boolean) => field.onChange(data)}
                options={[{ label, name, attributes }]}
                value={field.value}
            />
        </FieldWrapper>
    );
}
