export type ProductType = {
    value: string;
    label: string;
    description?: string;
    type_form: FormType;
    ar_seulement?: boolean;
};

export enum FormType {
    EXCURSION = "EXCURSION",
    LIAISON = "LIAISON",
    NAVIBUS = "NAVIBUS",
    PACKAGE = "PACKAGE",
}
