import { SubmitHandler } from "react-hook-form";
import { PackageChoiceOptions } from "./PackageChoiceOptions";
import { ExcursionOptionsValues } from "../../../activity-types/excursion";
import { Session } from "../../../../models";
import { ExcursionSearch, useSearchContext } from "../../../search";
import { usePrefetchSessionOptionsForm } from "../../../sessions";

type PackageChoiceSessionProps = {
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    sessions: Session[];
};

export function PackageChoiceSession({ onSubmit, sessions }: PackageChoiceSessionProps) {
    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };
    const sessionIds = sessions.map((session) => session.id);

    usePrefetchSessionOptionsForm(sessionIds, { participants: search.participants });

    const firstBookableSession = sessions.find((session) => session.dispo_vente) || sessions[0];

    return (
        <PackageChoiceOptions
            defaultSession={firstBookableSession}
            defaultValues={{
                session_id: firstBookableSession.id,
                hour: firstBookableSession.heure_depart.value,
                lieu_id: firstBookableSession.lieux_depart[0].value.toString(),
                participants: search?.participants,
            }}
            onSubmit={onSubmit}
            sessions={sessions}
        />
    );
}
