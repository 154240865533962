import styles from "./Image.module.scss";

type ImageProps = {
    alt?: string;
    loading?: "eager" | "lazy";
    src: string;
};

export function Image({ alt = "", loading = "lazy", src }: ImageProps) {
    return <img alt={alt} className={styles["image"]} loading={loading} src={src} />;
}
