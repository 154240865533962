import { Spinner, Stack } from "@resamare/ui";
import { isEmpty } from "@resamare/functions";
import { Liaison as ILiaison } from "../../../../models";
import { Liaison } from "./Liaison";
import { Step } from "./Step/Step";
import { SelectedLiaison } from "../types";

type LiaisonsListProps = {
    isCompleted: boolean;
    isDisabled?: boolean;
    isLoading: boolean;
    label: string;
    liaisons?: ILiaison[];
    onEdit: () => void;
    onSelect: (data: SelectedLiaison) => void;
    renderDaysPicker: () => React.ReactNode;
    renderNoResult: () => React.ReactNode;
    step: number;
};

export function LiaisonsList({
    isCompleted,
    isDisabled = false,
    isLoading,
    label,
    liaisons,
    onEdit,
    onSelect,
    renderDaysPicker,
    renderNoResult,
    step,
}: LiaisonsListProps) {
    return (
        <Stack gap={2}>
            <Step
                isCompleted={isCompleted}
                isDisabled={isDisabled}
                label={label}
                onClickEdit={onEdit}
                step={step}
            />

            {isLoading ? <Spinner /> : null}

            {isCompleted ? null : (
                <Stack gap={3}>
                    {renderDaysPicker()}

                    {!isDisabled && liaisons ? (
                        <Stack gap={3}>
                            {isEmpty(liaisons)
                                ? renderNoResult()
                                : liaisons.map((liaison) => (
                                      <Liaison
                                          key={liaison.id}
                                          liaison={liaison}
                                          onSelect={onSelect}
                                      />
                                  ))}
                        </Stack>
                    ) : null}
                </Stack>
            )}
        </Stack>
    );
}
