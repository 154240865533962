import { get } from "@resamare/functions";
import { useFormState } from "react-hook-form";
import { FormErrorMessage } from "./FormErrorMessage";

type FormErrorProps = {
    name: string;
};

export function FormError({ name }: FormErrorProps) {
    const { errors } = useFormState({ name });

    const message = get(errors, `${name}.message`);

    if (!errors || !message || typeof message !== "string") return null;

    return <FormErrorMessage>{message}</FormErrorMessage>;
}
