import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Liaison } from "../../../models";

export function useGetLiaison(id: string) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["liaisons", id],
        queryFn: async () => {
            // Récupère uniquement les données en cache
            const cachedData = queryClient.getQueryData<Liaison>(["liaisons", id]);
            if (!cachedData) {
                // Empêche l'appel réseau en lançant une erreur si aucune donnée en cache
                throw new Error(`No cached data for liaison ${id}`);
            }
            return cachedData;
        },
        enabled: !!id,
        gcTime: 0,
        initialData: () => queryClient.getQueryData<Liaison>(["liaisons", id]),
    });

    return query;
}
