import { useController } from "react-hook-form";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { SelectField, SelectFieldProps } from "./SelectField";

export interface FormSelectProps
    extends Pick<
        SelectFieldProps,
        "helperText" | "isDisabled" | "label" | "name" | "options" | "placeholder"
    > {
    defaultValue?: string;
}

export function FormSelect({
    defaultValue = "",
    helperText,
    isDisabled,
    label,
    name,
    options,
    placeholder,
}: FormSelectProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue,
    });
    return (
        <FieldWrapper name={field.name}>
            <SelectField
                helperText={helperText}
                inputRef={field.ref}
                isDisabled={isDisabled}
                isError={!!fieldState.error}
                label={label}
                name={field.name}
                onChange={field.onChange}
                options={options}
                placeholder={placeholder}
                value={field.value}
            />
        </FieldWrapper>
    );
}
