import "./Typography.d";

import { ElementType } from "react";

import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@mui/material";

import Styles from "./Typography.module.scss";

// type TypographyProps = Pick<MuiTypographyProps, "className" | "variant">;

export interface TypographyProps
    extends Pick<MuiTypographyProps, "className" | "children" | "variant" | "align"> {
    color?:
        | "primary"
        | "primaryLink"
        | "secondary"
        | "error"
        | "warning"
        | "info"
        | "success"
        | "inherit";
    component?: ElementType;
    fontWeight?: 400 | 600;
    /**
     * Applies the theme typography styles.
     * @default 'text'
     */
    variant?:
        | "text"
        | "text-sm"
        | "text-xs"
        | "title"
        | "title-sm"
        | "title-xs"
        | "label"
        | "helper-text"
        | "menu-label";
}

export function Typography({
    children,
    className,
    color,
    component = "p",
    fontWeight,
    variant = "text",
    ...rest
}: TypographyProps) {
    return (
        <MuiTypography
            className={`${Styles[variant]} ${
                fontWeight ? Styles[`font-weight-${fontWeight}`] : ""
            } ${className || ""}`}
            color={color ? `${color}.main` : undefined}
            component={component}
            {...rest}
        >
            {children}
        </MuiTypography>
    );
}
