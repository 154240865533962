import { AxiosError } from "axios";
import { phone } from "phone";
import { UseFormReturn } from "react-hook-form";
import { ObjectSchema, boolean, object, string } from "yup";
import { handleServerError } from "../../../utils/api-utils";

export type InfosValues = {
    informations: {
        prenom: string;
        nom: string;
        email: string;
        phone: {
            indicatif: string;
            telephone: string;
        };
        langue: "français" | "anglais";
        newsletter: boolean;
    };
    methode_paiement?: string;
};

export const infosSchema: ObjectSchema<InfosValues> = object({
    informations: object({
        prenom: string().required("Votre prénom est requis"),
        nom: string().required("Votre nom est requis"),
        email: string().email("Email invalide").required("Votre email est requis"),
        phone: object({
            indicatif: string().required("Le dialcode est requis"),
            telephone: string()
                .required("Votre numéro de téléphone est requis")
                .test({
                    test: (value, { parent }) => {
                        const { isValid } = phone(`${parent.indicatif}${value}`);
                        return isValid;
                    },
                    message: "Votre numéro de téléphone n'est pas valide",
                }),
        }).required(),
        langue: string()
            .oneOf(["français", "anglais"], "Cette valeur est incorrecte")
            .required("Veuillez choisir une langue"),
        newsletter: boolean().required("Newsletter est requis"),
    }),
    methode_paiement: string(),
});

export async function handleInfosServerError(
    error: AxiosError,
    setError: UseFormReturn<InfosValues>["setError"],
    formRef: React.MutableRefObject<HTMLFormElement | null>,
) {
    const errorFieldMap = {
        "informations.telephone": "informations.phone.telephone",
        "informations.indicatif": "informations.phone.indicatif",
    };

    handleServerError<InfosValues>(error, setError, formRef, errorFieldMap);
}
