import { SwiperSlide } from "swiper/react";

type SlideProps = {
    children: React.ReactNode;
};

export function Slide({ children }: SlideProps) {
    return <SwiperSlide>{children}</SwiperSlide>;
}

//  Swiper a besoin que d'un élément SwiperSlide comme enfant direct
//  https://github.com/nolimits4web/swiper/issues/4413
Slide.displayName = "SwiperSlider";
