import { MIMES, MimeExtensionsMap } from "./mimes";

export function getExtensionsFromMimes<T extends keyof MimeExtensionsMap>(mimes: T[]): string[] {
    const extensions: string[] = [];

    mimes.forEach((mime) => {
        const extensionInfo = MIMES[mime];
        if (!extensionInfo) {
            throw new Error("Mime non pris en charge");
        }

        extensions.push(...extensionInfo.extensions);
    });

    return extensions;
}

export function formatExtensions(extensions: string[]) {
    if (extensions.length === 0) {
        return "Aucune extension spécifiée";
    }

    const formattedExtensions = extensions.map((ext) => ext.toUpperCase());

    if (formattedExtensions.length === 1) {
        return formattedExtensions[0];
    }

    const lastExtension = formattedExtensions.pop();
    const joinedExtensions = formattedExtensions.join(", ");

    return `${joinedExtensions} ou ${lastExtension}`;
}

export function formatSize(size: number): string {
    const fileSize = Math.abs(parseInt(size.toString(), 10));
    const def: [number, string][] = [
        [1, "octets"],
        [1024, "ko"],
        [1024 * 1024, "Mo"],
        [1024 * 1024 * 1024, "Go"],
        [1024 * 1024 * 1024 * 1024, "To"],
    ];

    for (let i = 0; i < def.length; i += 1) {
        if (fileSize < def[i][0]) {
            return `${(fileSize / def[i - 1][0]).toFixed(2)} ${def[i - 1][1]}`;
        }
    }

    // Si la taille est supérieure au dernier seuil défini
    return `${(fileSize / def[def.length - 1][0]).toFixed(2)} ${def[def.length - 1][1]}`;
}
