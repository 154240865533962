import { string } from "yup";
import { FieldValidationRule } from "../../types";

export function buildTextSchema(validation: FieldValidationRule[]) {
    let schema = string();

    validation.forEach((rule) => {
        if (rule.required) {
            schema = schema.required(rule.message);
        }
    });

    return schema;
}
