import { useQuery } from "@tanstack/react-query";
import { storage } from "@resamare/functions";
import { apiClient } from "../../../config/api-client";
import { CartNotFoundError } from "../errors/CartNotFoundError";
import { Cart } from "../../../models";

export async function getCart(cartKey: string | null): Promise<Cart> {
    if (!cartKey) throw new CartNotFoundError();

    const response = await apiClient.get<Cart>(`panier/${cartKey}`);

    const { data } = response;

    const participants: Cart["participants"] = {};
    data.ligne_package.forEach((pack) => {
        pack.liste_session.forEach((line) => {
            participants[line.id] = pack.participants.filter(
                (type) => parseInt(type.quantite, 10) > 0,
            );
        });
    });
    data.ligne_panier.forEach((line) => {
        participants[line.id] = line.participants?.filter(
            (type) => parseInt(type.quantite, 10) > 0,
        );
    });

    return { ...response.data, participants };
}

export const useGetCart = () => {
    const cartKey = storage.getWithExpiry<string>("cart");

    const query = useQuery({
        queryKey: ["cart", cartKey],
        queryFn: () => getCart(cartKey),
        staleTime: 15000,
        retry: 1,
        throwOnError: (error) => !(error instanceof CartNotFoundError),
        meta: { skipLogHttpErrors: [404] },
    });
    return query;
};
