import { StepIndicator } from "./StepIndicator";
import { Stack } from "../../Layout";
import { Typography } from "../../General";

import styles from "./Stepper.module.scss";

type StepProps = {
    direction?: React.ComponentProps<typeof Stack>["direction"];
    gap?: React.ComponentProps<typeof Stack>["gap"];
    isCentered?: boolean;
    isCompleted?: boolean;
    isDisabled?: boolean;
    step: number;
    subtitle?: string;
    title: string;
};

export function Step({
    direction = "column",
    gap = 0.5,
    isCentered,
    isCompleted,
    isDisabled,
    step,
    subtitle,
    title,
}: StepProps) {
    return (
        <Stack alignItems={isCentered ? "center" : "flex-start"} direction={direction} gap={gap}>
            <StepIndicator isCompleted={isCompleted} isDisabled={isDisabled} step={step} />
            <Stack>
                {subtitle ? (
                    <Typography align={isCentered ? "center" : "left"} variant="text-xs">
                        {subtitle}
                    </Typography>
                ) : null}
                <Typography
                    align={isCentered ? "center" : "left"}
                    className={styles["stepper-label"]}
                    fontWeight={600}
                    variant="text-sm"
                >
                    {title}
                </Typography>
            </Stack>
        </Stack>
    );
}
