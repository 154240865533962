import { useTranslation } from "@resamare/translation";
import { Button, Spinner, Stack, Typography } from "@resamare/ui";
import { DateFormat, dt, isEmpty } from "@resamare/functions";
import { GetNextDatesAvailableDTO, useGetNextDatesAvailable } from "../../features/sessions";
import { LoupIllustration } from "../../assets";

import styles from "./NoResultFound.module.scss";

type NoResultFoundProps = {
    onDateClick: (date: Date) => void;
    search: GetNextDatesAvailableDTO;
    showIllustration?: boolean;
};

export function NoResultFound({
    onDateClick,
    search,
    showIllustration = false,
}: NoResultFoundProps) {
    const { t } = useTranslation();

    const nextDatesQuery = useGetNextDatesAvailable({ params: search });

    return (
        <Stack className={styles["no-result"]} gap={3}>
            <Stack alignItems="center" gap={2}>
                {showIllustration ? <LoupIllustration /> : null}

                <div>
                    <Typography align="center" variant="title-xs">
                        {t("Oups")}
                    </Typography>
                    <Typography align="center">
                        {t("Aucun résultat ne semble correspondre à vos critères.")}
                    </Typography>
                </div>
            </Stack>

            {nextDatesQuery.isPending ? (
                <Stack gap={1}>
                    <Spinner size={40} />
                    <Typography align="center">
                        {t("Recherche d'une date disponible pour vous")}
                    </Typography>
                </Stack>
            ) : null}

            {nextDatesQuery.data && !isEmpty(nextDatesQuery.data) ? (
                <Stack gap={2}>
                    <div>
                        <Typography align="center" variant="title-xs">
                            {t("Vous êtes flexibles ?")}
                        </Typography>
                        <Typography align="center">
                            {t("Ces dates sont disponibles pour vous.")}
                        </Typography>
                    </div>

                    <div className={styles["dates"]}>
                        {nextDatesQuery.data.map((date) => (
                            <Button
                                key={date}
                                onClick={() => onDateClick(dt.fromISO(date))}
                                size="small"
                            >
                                {dt.formatLocale(dt.fromISO(date), DateFormat.DATE_FULL)}
                            </Button>
                        ))}
                    </div>
                </Stack>
            ) : null}
        </Stack>
    );
}
