export type ExcursionSearch = {
    date_deb: Date;
    participants: Record<string, number>;
    type_produit?: number;
    produit_id?: number | string;
};

export type LiaisonSearch = {
    "trip-type": TripType;
    from: string;
    to: string;
    dates: Date[];
    participants: Record<string, number>;
    type_produit: number;
};

export type NavibusSearch = {
    date_deb: Date;
    participants: Record<string, number>;
    produit_id: number;
    itinerary: string[];
};

export enum TripType {
    ONEWAY = "oneway",
    ROUNDTRIP = "roundtrip",
}

export type PackageSearch = {
    participants: Record<string, number>;
    type_produit?: number;
};
