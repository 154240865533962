import { useState } from "react";
import { InputLabel as MuiInputLabel, Select as MuiSelect } from "@mui/material";
import { FormControl, FormHelperText } from "../_partials";
import { SelectFieldProps } from "../FormSelect/SelectField";
import { SelectItem } from "../FormSelect/SelectItem";
import { ChevronBottomIcon, IconButton, SwapIcon, Typography } from "../../General";

import styles from "./SelectSwapField.module.scss";

export interface SelectSwapFieldProps {
    helperText?: string;
    label?: string;
    first: Omit<SelectFieldProps, "helperText" | "placeholder">;
    second: Omit<SelectFieldProps, "helperText" | "placeholder">;
}

export function SelectSwapField({ helperText, label, first, second }: SelectSwapFieldProps) {
    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(!isClicked);
        first.onChange(second.value || "");
        second.onChange(first.value || "");
    };

    return (
        <div>
            {label ? <Typography variant="label">{label}</Typography> : null}
            <div className={styles["selects"]}>
                <FormControl isError={first.isError}>
                    <MuiInputLabel
                        className={`${styles["label"]} ${
                            first.value ? styles["label--has-value"] : ""
                        }`}
                        htmlFor={first.name}
                        id="first-select-label"
                    >
                        <Typography component="span" variant={first.value ? "text-sm" : "text"}>
                            {first.label}
                        </Typography>
                    </MuiInputLabel>
                    <MuiSelect
                        IconComponent={ChevronBottomIcon}
                        aria-labelledby="first-select-label"
                        className={`${styles["select__first"]} ${
                            first.isDisabled ? styles["select--is-disabled"] : ""
                        }`}
                        disabled={first.isDisabled}
                        displayEmpty
                        id={first.name}
                        inputProps={{ className: styles["select"] }}
                        inputRef={first.inputRef}
                        onChange={(e) => {
                            first.onChange(e.target.value);
                        }}
                        renderValue={(v) => (
                            <Typography>
                                {first.options.find((option) => option.value === v)?.label}
                            </Typography>
                        )}
                        value={first.value}
                        variant="outlined"
                    >
                        {first.options.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </MuiSelect>
                </FormControl>
                <FormControl isError={second.isError}>
                    <MuiInputLabel
                        className={`${styles["label"]} ${
                            second.value ? styles["label--has-value"] : ""
                        }`}
                        htmlFor={second.name}
                        id="second-select-label"
                    >
                        <Typography component="span" variant={second.value ? "text-sm" : "text"}>
                            {second.label}
                        </Typography>
                    </MuiInputLabel>
                    <MuiSelect
                        IconComponent={ChevronBottomIcon}
                        aria-labelledby="second-select-label"
                        className={`${styles["select__second"]} ${
                            second.isDisabled ? styles["select--is-disabled"] : ""
                        }`}
                        disabled={second.isDisabled}
                        displayEmpty
                        id={second.name}
                        inputProps={{ className: styles["select"] }}
                        inputRef={second.inputRef}
                        onChange={(e) => {
                            second.onChange(e.target.value);
                        }}
                        renderValue={(v) => (
                            <Typography>
                                {second.options.find((option) => option.value === v)?.label}
                            </Typography>
                        )}
                        value={second.value}
                        variant="outlined"
                    >
                        {second.options.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </MuiSelect>
                </FormControl>
                <div className={styles["swap-button"]}>
                    <IconButton
                        aria-label="Swap selected options between the two dropdowns"
                        className={isClicked ? styles["swap-button--is-clicked"] : ""}
                        color="primaryLink"
                        edge="start"
                        onClick={handleOnClick}
                    >
                        <SwapIcon />
                    </IconButton>
                </div>
            </div>

            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </div>
    );
}
