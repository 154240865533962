import { Spinner, Stack } from "@resamare/ui";
import { isEmpty, isInViewport } from "@resamare/functions";
import { useEffect, useRef } from "react";
import { useGetNavibusSessions } from "../api";
import { useNavibusLogic } from "../hooks/useNavibusLogic";
import { NavibusItem } from "../logic/NavibusItem";
import { NoResultFound } from "../components/NoResultFound";
import { NavibusSearch, useSearchContext } from "../../../search";
import { Section } from "../../../../components";

export function NavibusPage() {
    const { search } = useSearchContext<NavibusSearch>();

    const sessionsQueries = useGetNavibusSessions({ params: search });
    const navibus = useNavibusLogic(sessionsQueries);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!ref.current) return;
        if (isInViewport(ref.current, 300)) return;
        ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }, [ref, navibus?.liaisons]);

    if (!navibus) return null;

    const { isLoading, isMultipleSections, liaisons } = navibus;

    if (isLoading) {
        return (
            <Section>
                <Spinner />
            </Section>
        );
    }

    if (!liaisons || isEmpty(liaisons)) {
        return <NoResultFound />;
    }

    return (
        <div ref={ref}>
            <Section>
                <Stack gap={3}>
                    {isMultipleSections ? (
                        <NavibusItem liaisons={liaisons} />
                    ) : (
                        liaisons.map((liaison) => (
                            <NavibusItem key={liaison.id} liaisons={[liaison]} />
                        ))
                    )}
                </Stack>
            </Section>
        </div>
    );
}
