import { CheckIcon, Stack, Typography } from "@resamare/ui";

import styles from "./Step.module.scss";

type StepIndicatorProps = {
    isCompleted: boolean;
    step: number;
};

export function StepIndicator({ isCompleted, step }: StepIndicatorProps) {
    return (
        <Stack
            className={`${styles["step-indicator"]} ${
                isCompleted ? styles["step-indicator--is-completed"] : ""
            }`}
        >
            {isCompleted ? <CheckIcon /> : <Typography variant="text-sm">{step}</Typography>}
        </Stack>
    );
}
