import { useSearchParams } from "react-router-dom";
import { array, object, string } from "yup";

export function useSearchParamsOptions() {
    const [searchParams] = useSearchParams();

    const sessionIds = searchParams.getAll("sessions");
    const participants = searchParams.get("participants");

    const schema = object().shape({
        sessionIds: array()
            .of(string().required("Session ID is required"))
            .min(1, "At least one session ID is required")
            .required("Session IDs are required"),
        participants: string()
            .required("Participants are required")
            .test({
                message: "Participants must be a valid object",
                test: (value) => {
                    try {
                        const parsedValue = JSON.parse(value);

                        if (typeof parsedValue !== "object" || parsedValue === null) return false;

                        // Vérifie que chaque clé est un nombre et chaque valeur est un nombre
                        return Object.keys(parsedValue).every(
                            (key) =>
                                !Number.isNaN(Number(key)) && typeof parsedValue[key] === "number",
                        );
                    } catch {
                        return false;
                    }
                },
            })
            .test({
                message: "At least one participant must be selected with a quantity of 1 or more.",
                test: (value) => {
                    const parsedValue = JSON.parse(value) as Record<string, number>;
                    return Object.values(parsedValue).some((val) => val >= 1);
                },
            }),
    });

    schema.validateSync({ sessionIds, participants }, { abortEarly: false });

    return {
        sessionIds,
        participants: JSON.parse(participants as string) as Record<string, number>,
    };
}
