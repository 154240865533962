import {
    FormType,
    NavibusFormLogic,
    PackageForm,
    SearchModule as SharedSearchModule,
} from "@resamare/widgets-shared";
import { Intro } from "./Intro";
import { LiaisonForm } from "./LiaisonForm";
import { ExcursionForm } from "./ExcursionForm";

const FORMS = {
    [FormType.EXCURSION]: <ExcursionForm />,
    [FormType.LIAISON]: <LiaisonForm />,
    [FormType.NAVIBUS]: <NavibusFormLogic />,
    [FormType.PACKAGE]: <PackageForm />,
};

export function SearchModule() {
    return <SharedSearchModule forms={FORMS} maxWidth={500} renderBefore={Intro} />;
}
