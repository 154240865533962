import { useQuery } from "@tanstack/react-query";
import { Product } from "@resamare/models";
import { apiClient } from "../../../config";

type GetProductsParams = {
    productTypeId?: string;
};

async function getProducts(params?: GetProductsParams): Promise<Product[]> {
    const response = await apiClient.get(`/produits`, {
        params: {
            produit_type_id: params?.productTypeId,
        },
    });

    return response.data;
}

export function useGetProducts(params?: GetProductsParams) {
    const query = useQuery({
        queryKey: ["products", params],
        queryFn: () => getProducts(params),
    });

    return query;
}
