import { Backdrop as MuiBackdrop } from "@mui/material";

import { Spinner } from "../Spinner/Spinner";

import styles from "./FullPageSpinner.module.scss";

export function FullPageSpinner() {
    return (
        <MuiBackdrop className={styles["backdrop"]} open>
            <Spinner color="inherit" size={100} thickness={1.5} />
        </MuiBackdrop>
    );
}
