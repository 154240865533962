import { ReactElement } from "react";
import { Divider } from "./Divider";

export function renderElementsWithDividers(elements: ReactElement[]): ReactElement[] {
    return elements.reduce<ReactElement[]>((acc, element, index) => {
        acc.push(element);

        // Ajoute un Divider après chaque élément, sauf après le dernier
        if (index < elements.length - 1) {
            acc.push(<Divider key={`${element.key}-divider`} />);
        }

        return acc;
    }, []);
}
