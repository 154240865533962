import { flat, uid } from "@resamare/functions";
import { Field, buildYupSchema } from "@resamare/ui";
import { boolean, object } from "yup";
import { Cart } from "../../../models";

export function getParticipantsInfosSchema(fields: Field[], cart: Cart) {
    const { participants } = cart;
    const sessionIds = Object.keys(participants);

    const prefixs: string[] = [];

    sessionIds.forEach((sessionId) =>
        participants[sessionId].forEach(({ id, quantite }) =>
            Array.from(Array(parseInt(quantite, 10))).forEach((_, index) => {
                prefixs.push(`informations-participants.${sessionId}.${id}.${index}`);
            }),
        ),
    );

    const allFields = flat(
        prefixs.map((prefix) =>
            fields.map((field) => ({ ...field, name: `${prefix}.${field.name}` })),
        ),
    );

    const participantsSchema = buildYupSchema({
        sections: [{ id: uid(6), title: "", fields: allFields }],
    });

    return object({
        isSame: boolean().required(),
    })
        .transform((value) => {
            if (value.isSame === true) {
                const sessionId = Object.keys(value["informations-participants"])[0];
                const dataParticipants = value["informations-participants"][sessionId];

                const infosParticipants: { [key: string]: string } = {};
                Object.keys(cart.participants).forEach((id) => {
                    infosParticipants[id] = dataParticipants;
                });

                return { ...value, "informations-participants": infosParticipants };
            }

            return value;
        })
        .concat(participantsSchema);
}
