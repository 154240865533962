import {
    Checkbox as MuiCheckbox,
    FormControlLabel as MuiFormControlLabel,
    CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import { Typography } from "../../General";

import styles from "./Checkbox.module.scss";

interface CheckboxItemProps
    extends Pick<MuiCheckboxProps, "checked" | "disabled" | "name" | "onChange"> {
    alignTop?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    label: string | React.ReactNode;
}

export function CheckboxItem({
    alignTop = false,
    checked,
    disabled,
    inputRef,
    label,
    name,
    onChange,
}: CheckboxItemProps) {
    return (
        <MuiFormControlLabel
            className={`${styles["label"]} ${alignTop ? styles["label--align-top"] : ""}`}
            control={
                <MuiCheckbox
                    checked={checked}
                    className={alignTop ? styles["checkbox--align-top"] : ""}
                    disabled={disabled}
                    inputRef={inputRef}
                    name={name}
                    onChange={onChange}
                />
            }
            label={typeof label === "string" ? <Typography>{label}</Typography> : label}
        />
    );
}
