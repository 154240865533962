/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import { ObjectSchema, Schema, object } from "yup";
import { FieldValues } from "react-hook-form";
import { slug } from "@resamare/functions";
import { Field, FieldType, FieldValidationRule, FormData } from "../types";
import {
    buildCheckboxSchema,
    buildFileSchema,
    buildNumberSchema,
    buildRadioSchema,
    buildSectionSchema,
    buildSelectSchema,
    buildTextSchema,
} from "./builders";
import { SECTION_PREFIX } from "../config";

function addNestedFieldToSchema(schema: { [key: string]: any }, path: string, fieldSchema: Schema) {
    // Cas de base: si le chemin ne contient pas de ".", ajouter directement le champ au schéma
    if (!path.includes(".")) {
        if (schema[path]) {
            // Si un schéma existe déjà pour ce chemin, le concaténer avec le nouveau
            schema[path] = schema[path].concat(fieldSchema);
        } else {
            // Sinon, simplement assigner le nouveau schéma
            schema[path] = fieldSchema;
        }
        return;
    }

    // Séparer le premier élément du chemin du reste
    const [firstPart, ...rest] = path.split(".");
    const restPath = rest.join(".");

    // S'assurer que le premier élément du chemin a un schéma d'objet
    if (!schema[firstPart] || !(schema[firstPart] instanceof ObjectSchema)) {
        schema[firstPart] = object()
            .transform((value) => ({ ...value }))
            .shape({});
    }

    // Si restPath ne contient pas de ".", nous sommes au niveau juste avant le champ final
    if (!restPath.includes(".")) {
        // Préparer le schéma à ajouter
        const newFieldSchema = object().shape({ [restPath]: fieldSchema });

        // Concaténer le schéma existant avec le nouveau schéma du champ
        schema[firstPart] = schema[firstPart].concat(newFieldSchema);
    } else {
        // Sinon, appel récursif pour traiter le niveau suivant d'imbrication
        if (!schema[firstPart].fields) {
            schema[firstPart].fields = {};
        }
        const newNode = restPath.split(".")[0];
        if (!schema[firstPart]._nodes.includes(newNode)) {
            schema[firstPart]._nodes.push(newNode);
        }
        addNestedFieldToSchema(schema[firstPart].fields, restPath, fieldSchema);
    }
}

function processField(
    schema: { [key: string]: any },
    field: Field,
    buildSchemaFunction: (validation: FieldValidationRule[]) => Schema,
) {
    if (!field.validation) return;

    const fieldSchema = buildSchemaFunction(field.validation);
    addNestedFieldToSchema(schema, field.name, fieldSchema);
}

export function buildYupSchema<FormValues extends FieldValues>(
    data: FormData,
): ObjectSchema<FormValues> {
    const schema: { [key: string]: Schema } = {};

    // Validation individuelle des champs
    data.sections.forEach((section) => {
        section.fields.forEach((field) => {
            switch (field.type) {
                case FieldType.CHECKBOX:
                    processField(schema, field, buildCheckboxSchema);
                    break;

                case FieldType.DATE:
                    // TODO: Date validation
                    break;

                case FieldType.FILE:
                    processField(schema, field, buildFileSchema);
                    break;

                case FieldType.NUMBER:
                    processField(schema, field, buildNumberSchema);
                    break;

                case FieldType.NUMBER_STEPPER:
                    processField(schema, field, buildNumberSchema);
                    break;

                case FieldType.RADIO:
                    processField(schema, field, buildRadioSchema);
                    break;

                case FieldType.SELECT:
                    processField(schema, field, buildSelectSchema);
                    break;

                case FieldType.SELECT_MULTIPLE:
                    // TODO: Select multiple validation
                    break;

                case FieldType.TEXT:
                    processField(schema, field, buildTextSchema);
                    break;

                case FieldType.TEXTAREA:
                    processField(schema, field, buildTextSchema);
                    break;

                default:
                    throw new Error("Type de champ non pris en charge");
            }
        });
    });

    // Validations inter-champs
    data.sections.forEach((section) => {
        if (!section.validation) return;

        const dependantsField = section.validation.find((rule) => rule.fields?.length >= 2);
        if (!dependantsField) return;

        const key = slug(`${SECTION_PREFIX}-${section.id}`);
        schema[key] = buildSectionSchema(section.validation);
    });

    return object().shape(schema) as ObjectSchema<FormValues>;
}
