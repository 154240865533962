import { number } from "yup";
import { FieldValidationRule } from "../../types";

export function buildNumberSchema(validation: FieldValidationRule[]) {
    let schema = number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable();

    validation.forEach((rule) => {
        if (rule.min !== undefined) {
            schema = schema.min(rule.min, rule.message);
        }
        if (rule.max !== undefined) {
            schema = schema.max(rule.max, rule.message);
        }
        if (rule.required) {
            schema = schema.required(rule.message);
        }
    });

    return schema;
}
