import { useMemo } from "react";
import { InputLabel as MuiInputLabel, Select as MuiSelect } from "@mui/material";
import { FormControl, FormHelperText } from "../_partials";
import { SelectItem } from "../FormSelect/SelectItem";
import {
    ButtonLink,
    ChevronBottomIcon,
    CloseIcon,
    IconButton,
    LocationIcon,
    SquareIcon,
    TripOriginIcon,
    Typography,
} from "../../General";
import { Stack } from "../../Layout";
import { getAvailableStopovers } from "./utils";
import { ItineraryOption } from "./types";

import styles from "./ItineraryPlannerField.module.scss";

export interface ItineraryPlannerFieldProps {
    helperText?: string;
    label?: string;
    max?: number;
    name: string;
    options: ItineraryOption[];
    onChange: (value: [string, ...string[], string]) => void;
    value: [string, ...string[], string];
}

export function ItineraryPlannerField({
    helperText,
    label,
    max,
    name,
    options,
    onChange,
    value,
}: ItineraryPlannerFieldProps) {
    const steps = [...value];

    const departure = steps.shift() as string;
    const arrival = steps.pop() as string;
    const intermediateSteps = steps;

    const handleAddStep = () => {
        const newSteps = [...intermediateSteps, ""];
        onChange([departure, ...newSteps, arrival]);
    };
    const handleDeleteStep = (index: number) => {
        if (intermediateSteps.length === 1) {
            onChange([departure, arrival]);
            return;
        }

        const copySteps = [...intermediateSteps];
        copySteps.splice(index, 1);
        onChange([departure, ...copySteps, arrival]);
    };
    const handleChangeStep = (newValue: string, index: number) => {
        const newSteps = [...intermediateSteps];
        newSteps[index] = newValue;
        onChange([departure, ...newSteps, arrival]);
    };

    const availableStopovers = useMemo(
        () => getAvailableStopovers(options, value),
        [options, value],
    );

    return (
        <div>
            {label ? <Typography variant="label">{label}</Typography> : null}
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            <div className={styles["selects"]}>
                <Stack alignItems="center" direction="row" gap={1}>
                    <TripOriginIcon />
                    <div className={styles["select-wrapper"]}>
                        <FormControl isError={false}>
                            <MuiInputLabel
                                className={`${styles["label"]} ${
                                    departure ? styles["label--has-value"] : ""
                                }`}
                                htmlFor={`${name}[0]`}
                            >
                                <Typography
                                    component="span"
                                    variant={departure ? "text-sm" : "text"}
                                >
                                    Départ
                                </Typography>
                            </MuiInputLabel>
                            <MuiSelect
                                IconComponent={ChevronBottomIcon}
                                className={`${styles["select__first"]}`}
                                data-testid={`${name}[0]`}
                                displayEmpty
                                id={`${name}[0]`}
                                inputProps={{ className: styles["select"] }}
                                onChange={(e) => {
                                    onChange([e.target.value, ...intermediateSteps, arrival]);
                                }}
                                renderValue={(v) => (
                                    <Typography>
                                        {options.find((option) => option.value === v)?.label}
                                    </Typography>
                                )}
                                value={departure}
                                variant="outlined"
                            >
                                {options.map((option) => (
                                    <SelectItem key={option.value} value={option.value}>
                                        {option.label}
                                    </SelectItem>
                                ))}
                            </MuiSelect>
                        </FormControl>
                    </div>
                </Stack>

                {intermediateSteps.map((step, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Stack key={`${step}-${index}`} alignItems="center" direction="row" gap={1}>
                        <div className={styles["step-icon"]}>
                            <SquareIcon />
                            <Typography
                                className={styles["step-icon__number"]}
                                component="span"
                                fontWeight={600}
                                variant="text-xs"
                            >
                                {index + 1}
                            </Typography>
                        </div>
                        <div className={styles["select-wrapper"]}>
                            <FormControl isError={false}>
                                <MuiInputLabel
                                    className={`${styles["label"]} ${
                                        step ? styles["label--has-value"] : ""
                                    }`}
                                    htmlFor={`${name}[${index + 1}]`}
                                >
                                    <Typography
                                        component="span"
                                        variant={step ? "text-sm" : "text"}
                                    >
                                        Escale n°{index + 1}
                                    </Typography>
                                </MuiInputLabel>
                                <MuiSelect
                                    IconComponent={ChevronBottomIcon}
                                    className={`${styles["select__step"]}`}
                                    data-testid={`${name}[${index + 1}]`}
                                    displayEmpty
                                    id={`${name}[${index + 1}]`}
                                    inputProps={{ className: styles["select"] }}
                                    onChange={(e) => {
                                        handleChangeStep(e.target.value, index);
                                    }}
                                    renderValue={(v) => (
                                        <Typography>
                                            {options.find((option) => option.value === v)?.label}
                                        </Typography>
                                    )}
                                    value={availableStopovers.includes(step) ? step : ""}
                                    variant="outlined"
                                >
                                    {options.map((option) => (
                                        <SelectItem
                                            key={option.value}
                                            disabled={
                                                !availableStopovers.includes(option.value) ||
                                                value
                                                    .filter((v) => v !== step)
                                                    .includes(option.value)
                                            }
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </MuiSelect>
                            </FormControl>
                        </div>
                        <IconButton
                            aria-label={`Remove stopover ${index + 1}`}
                            className={styles["select__delete-button"]}
                            color="error"
                            edge="start"
                            onClick={() => handleDeleteStep(index)}
                        >
                            <CloseIcon size="small" />
                        </IconButton>
                    </Stack>
                ))}

                <Stack alignItems="center" direction="row" gap={1}>
                    <LocationIcon />
                    <FormControl isError={false}>
                        <MuiInputLabel
                            className={`${styles["label"]} ${
                                arrival ? styles["label--has-value"] : ""
                            }`}
                            htmlFor={`${name}[${value.length - 1}]`}
                        >
                            <Typography component="span" variant={arrival ? "text-sm" : "text"}>
                                Arrivée
                            </Typography>
                        </MuiInputLabel>
                        <MuiSelect
                            IconComponent={ChevronBottomIcon}
                            className={`${styles["select__last"]}`}
                            data-testid={`${name}[${value.length - 1}]`}
                            displayEmpty
                            id={`${name}[${value.length - 1}]`}
                            inputProps={{ className: styles["select"] }}
                            onChange={(e) => {
                                onChange([departure, ...intermediateSteps, e.target.value]);
                            }}
                            renderValue={(v) => (
                                <Typography>
                                    {options.find((option) => option.value === v)?.label}
                                </Typography>
                            )}
                            value={arrival}
                            variant="outlined"
                        >
                            {options.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </MuiSelect>
                    </FormControl>
                </Stack>

                {max && value.length >= max ? null : (
                    <Stack alignItems="flex-end">
                        <ButtonLink isDisabled={!value.every((v) => v)} onClick={handleAddStep}>
                            + Ajouter une escale
                        </ButtonLink>
                    </Stack>
                )}
            </div>
        </div>
    );
}
