import { MenuItemProps as MuiMenuItemProps, MenuItem as MuiMenuItem } from "@mui/material";
import { Typography } from "../../General";

export interface SelectItemProps
    extends Pick<MuiMenuItemProps, "disabled" | "onClick" | "selected"> {
    value: string;
    children: string;
}

export function SelectItem({ disabled, children, onClick, selected, value }: SelectItemProps) {
    return (
        <MuiMenuItem disabled={disabled} onClick={onClick} selected={selected} value={value}>
            <Typography>{children}</Typography>
        </MuiMenuItem>
    );
}
