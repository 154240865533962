import { ObjectSchema, object, string } from "yup";

export type ExcursionOptionsValues = {
    hour: string;
    lieu_id: string;
    session_id: string;
    options?: Record<string, number>;
    participants: Record<string, number>;
};

export function getExcursionOptionsSchema(): ObjectSchema<ExcursionOptionsValues> {
    return object().shape({
        hour: string().required(),
        lieu_id: string().required().min(0),
        options: object().transform((value) => ({ ...value })),
        session_id: string().required().min(0),
        participants: object().required(),
    });
}
