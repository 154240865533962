import React from "react";
import { Typography } from "@resamare/ui";

import styles from "./LineTitle.module.scss";

type LineTitleProps = {
    children: React.ReactNode;
};

export function LineTitle({ children }: LineTitleProps) {
    return (
        <Typography className={styles["line-title"]} fontWeight={600}>
            {children}
        </Typography>
    );
}
