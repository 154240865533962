import { DateFormat, dt, formatCurrency, min } from "@resamare/functions";
import { ButtonCustom, Typography } from "@resamare/ui";
import { useGetLiaisons } from "../../../../liaisons";
import { getOutboundParams, getReturnParams } from "../../utils";

import styles from "./DaysPicker.module.scss";

type DayButtonProps = {
    date: Date;
    isDisabled?: boolean;
    isSelected?: boolean;
    minDate?: Date;
    onClick: (date: Date) => void;
    search: ReturnType<typeof getOutboundParams | typeof getReturnParams>;
};

export function DayButton({
    date,
    isDisabled = false,
    isSelected = false,
    minDate,
    onClick,
    search,
}: DayButtonProps) {
    const liaisonsQuery = useGetLiaisons({
        params: search && !isDisabled ? { ...search, date_deb: date } : null,
    });

    const filteredLiaisons = liaisonsQuery.data?.filter(({ date_depart }) =>
        minDate ? dt.isAfter(dt.fromISO(dt.removeIsoOffset(date_depart)), minDate) : true,
    );

    const cheppestLiaison = filteredLiaisons
        ? min(filteredLiaisons, (liaison) => liaison.prix_total)
        : null;

    return (
        <ButtonCustom
            className={`${styles["day-button"]} ${
                isSelected ? styles["day-button--is-selected"] : ""
            } ${isDisabled ? styles["day-button--is-disabled"] : ""}`}
            disabled={isDisabled}
            onClick={() => {
                onClick(date);
            }}
        >
            <Typography component="span" variant="text-sm">
                {dt.formatLocale(date, DateFormat.DAY_SHORT)}
            </Typography>
            <Typography component="span" fontWeight={600} variant="text-sm">
                {cheppestLiaison
                    ? formatCurrency(cheppestLiaison.prix_apartirde || cheppestLiaison.prix_total)
                    : "-"}
            </Typography>
        </ButtonCustom>
    );
}
