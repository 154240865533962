export { isEmpty, assign as deepMerge, get } from "radash";

type Searchable<T> = {
    [K in keyof T]: string | number;
};

export const filterItemsByProperties = <T>(
    items: T[],
    value: string,
    propertiesToCheck: (keyof Searchable<T>)[],
): T[] => {
    if (!items) return [];
    if (!value) return items;

    const lowerSearchValue = value.toLowerCase();
    return items.filter((item) =>
        propertiesToCheck.some((prop) =>
            String(item[prop]).toLowerCase().includes(lowerSearchValue),
        ),
    );
};

export const removeEmptyValues = (obj: object) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};
