import { Typography } from "@resamare/ui";

import styles from "./Overlay.module.scss";

type OverlayFooterProps = {
    children: React.ReactNode;
};

export function OverlayFooter({ children }: OverlayFooterProps) {
    return (
        <div className={`${styles["overlay"]} ${styles["overlay-footer"]}`}>
            {typeof children === "string" ? (
                <div>
                    <Typography variant="title-xs">{children}</Typography>
                </div>
            ) : (
                children
            )}
        </div>
    );
}
