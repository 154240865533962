import { useTranslation } from "@resamare/translation";
import { formatCurrency } from "@resamare/functions";
import { Stack, Typography } from "@resamare/ui";
import { Promotion } from "../../../../../models";
import { PromotionDetails } from "../../../../promo-code";

type LinePromotionProps = {
    promotion: Promotion;
};

export function LinePromotion({ promotion }: LinePromotionProps) {
    const { t } = useTranslation();

    return (
        <Stack>
            <Stack direction="row" gap={1}>
                <Typography fontWeight={600}>{t("Réduction")}</Typography>
            </Stack>
            <Stack direction="row" gap={1} justifyContent="space-between">
                <Typography>{promotion.label}</Typography>
                <Typography color="success">{formatCurrency(promotion.total)}</Typography>
            </Stack>
            {promotion.description ? (
                <PromotionDetails description={promotion.description} nom={promotion.label} />
            ) : null}
        </Stack>
    );
}
