import { createContext, ReactNode, useMemo } from "react";

export interface PermissionContextProps<User = object | null, Role = string> {
    user: User;
    roles: Role[];
}

export interface PermissionsProviderProps<User, Role> {
    user: User;
    roles: Role[];
    children: ReactNode;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const PermissionsContext = createContext<PermissionContextProps<any, any> | undefined>(
    undefined,
);

export function PermissionsProvider<User, Role>({
    user,
    roles,
    children,
}: PermissionsProviderProps<User, Role>) {
    const value = useMemo(() => ({ roles, user }), [roles, user]);

    return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}
