import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";
import { forwardRef } from "react";

import Styles from "./Tooltip.module.scss";

interface TooltipProps extends Pick<MuiTooltipProps, "arrow" | "placement" | "title"> {
    children: React.ReactNode;
    delay?: number;
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
    ({ arrow, children, delay = 0, placement, title }, ref) => (
        <MuiTooltip arrow={arrow} enterTouchDelay={delay} placement={placement} title={title}>
            <div ref={ref} className={Styles.TooltipComponent}>
                {children}
            </div>
        </MuiTooltip>
    ),
);

Tooltip.displayName = "Tooltip";
