import { useTranslation } from "@resamare/translation";
import { buildYupSchema, Button, Spinner, Stack, useForm } from "@resamare/ui";
import { useEffect, useMemo } from "react";
import { ObjectSchema } from "yup";
import { useFormSubmitter } from "@resamare/react-hooks";
import { FormProvider } from "react-hook-form";
import { OptionsForm, OptionsTotal } from "../components";
import { ExcursionOptionsValues, getExcursionOptionsSchema } from "../../activity-types/excursion";
import { Session } from "../../../models";
import { useChosenSession } from "../hooks";
import { useGetSessionOptionsForm } from "../../sessions";
import { ModalLayout } from "../../../layouts";
import { OverlayFooter } from "../../../components";

type OptionsContainerProps = {
    defaultValues: {
        hour: string;
        lieu_id: string;
        participants: Record<string, number>;
        session_id: string;
    };
    isLoading?: boolean;
    onSubmit: (data: ExcursionOptionsValues) => void;
    participants: Record<string, number>;
    sessions: Session[];
};

export function OptionsContainer({
    defaultValues,
    isLoading,
    onSubmit,
    participants,
    sessions,
}: OptionsContainerProps) {
    const { t } = useTranslation();

    const form = useForm({ defaultValues, options: { shouldUnregister: true } });

    const chosenSession = useChosenSession(sessions, form.watch("hour", defaultValues["hour"]));

    const optionsFormQuery = useGetSessionOptionsForm(chosenSession.id, { participants });

    const excursionSchema = useMemo(() => getExcursionOptionsSchema(), []);
    useEffect(() => {
        if (optionsFormQuery.data) {
            const optionsSchema = buildYupSchema(optionsFormQuery.data);

            form.updateSchema(
                excursionSchema.concat(optionsSchema) as ObjectSchema<ExcursionOptionsValues>,
            );
        }
    }, [optionsFormQuery.data, excursionSchema]);

    const { formRef, submitForm } = useFormSubmitter();

    if (!optionsFormQuery.data && optionsFormQuery.isPending) {
        return (
            <ModalLayout>
                <Spinner />
            </ModalLayout>
        );
    }

    if (!optionsFormQuery.data) return null;

    return (
        <FormProvider {...form}>
            <ModalLayout>
                {optionsFormQuery.isPending ? <Spinner /> : null}
                {optionsFormQuery.data ? (
                    <OptionsForm
                        formRef={formRef}
                        onSubmit={onSubmit}
                        options={optionsFormQuery.data}
                        selectedSession={chosenSession}
                        sessions={sessions}
                    />
                ) : null}
            </ModalLayout>
            <OverlayFooter>
                <Stack direction="row" justifyContent="space-between">
                    <OptionsTotal participants={participants} session={chosenSession} />
                    <Button isLoading={isLoading} onClick={submitForm}>
                        {t("Suivant")}
                    </Button>
                </Stack>
            </OverlayFooter>
        </FormProvider>
    );
}
